<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">样品投入产出分析</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="calendarMonthInt" label="历年" label-width="120px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.calendarMonthInt"
                  value-format="yyyy/MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="industryList" label="行业" label-width="120px">
              <el-select filterable style="width: 99%" :disabled="searchForm.flag === 1 && searchForm.dictType !== 1 && searchForm.dictType !== 11 && searchForm.dictType !== 12" collapse-tags multiple
                         v-model="searchForm.industryList">
                <el-option
                    v-for="(value,key) in sampleOrderIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="deptIdList" label="部门" label-width="120px">
              <el-select filterable multiple @change="deptChange" :disabled="searchForm.classZu !== '1' && searchForm.dictType !== 13" collapse-tags
                         style="width: 99%"
                         v-model="searchForm.deptIdList">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployeeList" label="销售雇员" label-width="120px">
              <el-select filterable style="width: 99%" :disabled="searchForm.classZu !== '1' && searchForm.dictType !== 16" collapse-tags multiple
                         v-model="searchForm.mySalesEmployeeList">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="classZu" label="是否组合查询" label-width="120px">
              <el-select filterable collapse-tags style="width: 99%"
                         @change="classZuChange"
                         :disabled="(searchForm.flag === 1 && searchForm.dictType !== 1 && searchForm.dictType !== 2 && searchForm.dictType !== 11 && searchForm.dictType !== 12) || user_identity"
                         v-model="searchForm.classZu">
                <el-option
                    label="是"
                    value="1">
                </el-option>
                <el-option
                    label="否"
                    value="0">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="flag" label="统计类型" label-width="120px">
              <el-select clearable filterable style="width: 99%"
                         @change="flagChange"
                         v-model="searchForm.flag">
                <el-option
                    v-for="(value,key) in salesIndexStatisticsModeList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="productCategoryList" label="产品大类" label-width="120px">
              <el-select filterable style="width: 99%" :disabled="searchForm.flag === 1 && searchForm.dictType !== 2 && searchForm.dictType !== 11 && searchForm.dictType !== 12" collapse-tags multiple
                         v-model="searchForm.productCategoryList">
                <el-option
                    v-for="(value,key) in sampleOrderProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdList" label="分区" label-width="120px">
              <el-select :disabled=" searchForm.classZu !== '1' && searchForm.dictType !== 14" @change="partitionChange" filterable multiple
                         collapse-tags style="width: 99%"
                         v-model="searchForm.partitionIdList">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerRatingList" label="评级" label-width="120px">
              <el-select filterable :disabled="searchForm.flag === 1 && searchForm.dictType !== 7" style="width: 99%"
                         v-model="searchForm.customerRatingList">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item :prop="searchForm.flag === 2 || searchForm.flag === 3 ? '' : 'dictType'" label="统计方式" label-width="120px">
              <el-select @change="selectChange"
                         :disabled="searchForm.flag === 2 || searchForm.flag === 3"
                         filterable style="width: 99%"
                         v-model="searchForm.dictType">
                <el-option
                    v-for="(value,key) in inputOutputAnalysisTypeList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerAbbreviationList" label="客户" label-width="120px">
              <el-select filterable multiple :disabled="searchForm.flag === 1 && searchForm.dictType !== 6" collapse-tags style="width: 99%"
                         v-model="searchForm.customerAbbreviationList">
                <el-option
                    v-for="(value,key) in ssCrDropDownBoxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="120px">
              <el-select filterable multiple collapse-tags style="width: 99%"
                         @change="groupChange"
                         :disabled="searchForm.classZu !== '1' && searchForm.dictType !== 15"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="materialList" label="物料" label-width="120px">
              <el-select filterable style="width: 99%" :disabled="searchForm.flag === 1 && searchForm.dictType !== 8" collapse-tags multiple
                         v-model="searchForm.materialList">
                <el-option
                    v-for="(value,key) in ssMaterialDropDownBoxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="addSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
              <div @click="quXianClick()" style="margin-top: 10px" class="select_sou">曲线图</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                prop="table_name"
                :label="tableName()"
            >
            </el-table-column>
            <el-table-column
                v-for="(item,key) in sumSampleTotal"
                :key="key"
                :prop="item"
                :label="'样品金额'+ item"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.sumSampleTotal[item] }}</span>
              </template>
            </el-table-column>
            <el-table-column
                v-for="(item,key) in sumSalesQuota"
                :key="key+sumSampleTotal.length"
                :prop="item"
                :label="'销售额'+ item"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.sumSalesQuota[item] }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="sampleTotal"
                label="样品总金额"
            >
            </el-table-column>
            <el-table-column
                prop="sumQuota"
                label="总计销售额"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="曲线图" :visible.sync="dialogFormVisible">
      <div id="myChart" style="width: 100%;height: 500px"></div>
    </el-dialog>
    <el-dialog title="搜索" :visible.sync="searchVisible">
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">


        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('searchForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderListXiao",
  mixins: [mixins],
  components: {},
  data() {
    return {
      searchVisible: false,
      dialogFormVisible: false,
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      tableDataTime: [],
      partitionDropDownBoxList: [],
      searchForm: {
        groupIdList: [],
        calendarMonthInt: [],
        calendarMonthIntStart: "",
        classZu: "0",
        calendarMonthIntEnd: "",
        flag: "",
        industryList: [],
        partitionIdList: [],
        customerAbbreviationList: [],
        materialList: [],
        productCategoryList: [],
        mySalesEmployeeList: [],
        deptIdList: [],
        customerRatingS: [],
        customerRatingList: [],
      },
      searchs: {
        calendarMonthInt: [
          {required: true, message: '请选择历年', trigger: 'blur'}
        ],
        dictType: [
          {required: true, message: '请选择统计方式', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      groupDropDownList: [],
      salesEmployeeDropDownBoxList: [],
      selectList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
      echartsList: [],
      echartsName: [],
      sumSalesQuota: [],
      sumSampleTotal: [],
      salesIndexStatisticsModeList: [],
      partitionListDropDownBoxList: [],
      inputOutputAnalysisTypeList: [],
      ssCrDropDownBoxList: [],
      deptDropDownBoxList: [],
      ratingFunctionList: [],
      sampleOrderIndustryDropboxList: [],
      sampleOrderProductCategoryDropboxList: [],
      ssMaterialDropDownBoxList: [],
      echats_date: '',
      tableCao: true,
    }
  },
  mounted() {
// “id”: id,
// “deliveryYears”: 交货年月,
// “industry”: 行业,
// “productCategory”: 产品大类,
// “deliveryTime”: 交货日期,
// “actualDeliveryTime”: 实际发货日,
// “orderFirstDeliveryTime”: 订单首个交货日,
// “leaveManifestPromptness”: 开出货单及时率,
// “storehouseDeliveryPromptness”: 仓库发货及时率,
// “createBy”: 创建者,
// “createTime”: 创建时间,
// “updateBy”: 更新者,
// “updateTime”: 更新时间
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    this.salesEmployeeDropDownBox();
    // this.IndustryFunction();
    // this.product_categoryFunction();
    this.salesIndexStatisticsMode();
    this.deptDropDownBox();
    this.inputOutputAnalysisType();
    this.ssCrDropDownBox();
    this.ratingFunction();
    this.sampleOrderIndustryDropbox();
    this.sampleOrderProductCategoryDropbox();
    this.ssMaterialDropDownBox();
    // this.partitionDropDownBox();
    // this.groupDropDown();
    // this.partitionListDropDownBox();
  },
  methods: {
    classZuChange(e) {
      console.log(111, e);
      if (e === '0') {
        this.$set(this.searchForm, 'deptIdList', []);
        this.$set(this.searchForm, 'partitionIdList', []);
        this.$set(this.searchForm, 'groupIdList', []);
        this.$set(this.searchForm, 'mySalesEmployeeList', []);
      }
    },
    flagChange(e){
      console.log(111,e);
      this.searchForm.classZu = "0";
      this.searchForm.groupIdList = "";
      this.searchForm.industryList = "";
      this.searchForm.productCategoryList = "";
      this.searchForm.mySalesEmployeeList = "";
      this.searchForm.deptIdList = "";
      this.searchForm.customerAbbreviationList = "";
      this.searchForm.customerRatingList = "";
      this.searchForm.materialList = "";
      this.searchForm.partitionIdList = "";
      if (e !== 1){
        this.searchForm.dictType = ''
      }
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
      }
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
      }
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.salesEmployeeDropDownBox();
      }
    },
    groupDropDown() {
      let date = `roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptIdList}`
      if (this.searchForm.dictType === 15) {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&groupId=${this.user_date.groupId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      let date = `roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptIdList.toString()}`
      if (this.searchForm.dictType === 14) {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleOrderProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleOrderProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleOrderIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleOrderIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    ssMaterialDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/ssMaterialDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/ssMaterialDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ssMaterialDropDownBoxList = response.data.materialList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/rateDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/rateDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = response.data.rateList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    tableName() {
      // this.searchForm.dictType
      let date = this.inputOutputAnalysisTypeList.find((value, key) => {
        return value.dictValue === this.searchForm.dictType
      })

      return date ? date.dictLabel : ''
    },
    echartsTitle() {
      let name = '';
      if (this.searchForm.flag) {
        let date = this.salesIndexStatisticsModeList.find((value) => {
          return value.dictValue === this.searchForm.flag
        })
        if (date) {
          name = date.dictLabel + name
        }
      }
      return name
    },
    selectChange(e) {
      console.log(e);
      this.searchForm.classZu = "0";
      this.searchForm.groupIdList = "";
      this.searchForm.industryList = "";
      this.searchForm.productCategoryList = "";
      this.searchForm.mySalesEmployeeList = "";
      this.searchForm.deptIdList = "";
      this.searchForm.customerAbbreviationList = "";
      this.searchForm.customerRatingList = "";
      this.searchForm.materialList = "";
      this.searchForm.partitionIdList = "";
      if (e === 16) {
        this.salesEmployeeDropDownBox();
      } else if (e === 14) {
        this.partitionDropDownBox();
      } else if (e === 15) {
        this.groupDropDown();
      }

    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionListDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionListDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/partitionListDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.partitionListDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesIndexStatisticsMode() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=inputOutputAnalysisMode`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=inputOutputAnalysisMode', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesIndexStatisticsModeList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    inputOutputAnalysisType() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=inputOutputAnalysisType`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=inputOutputAnalysisType', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.inputOutputAnalysisTypeList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    toPoint(num) {
      let str = num.replace("%", "");
      return str
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "样品投入产出分析导出.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    radioChange(e) {
      console.log(111, e);
      this.searchForm.customerAbbreviationS = "";
      this.searchForm.industryList = "";
      this.searchForm.productCategoryList = "";
      this.searchForm.mySalesEmployeeList = "";
      this.searchForm.customerRatingS = "";
      this.searchForm.customerAbbreviationS_s = "";
    },
    drawLine(list, name, time) {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'));
      let option = {}
      if (this.searchForm.flag === 2) {
        option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              type: 'pie',
              label: {
                position: 'inner',
                formatter: '{b}: {c} ({d}%)',
                fontSize: 14
              },
              radius: '50%',
              data: list
            }
          ]
        };
      } else {
        option = {
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: time
          },
          legend: {
            data: name
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: list,
        };
      }

      console.log(999, option)

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(name, level) {
      if (this.echats_date) {
        this.echats_date.clear();
      }
      this.dialogFormVisible = true;
      let list = [];
      let list_name = [];
      if (name) {
        let arrData = this.echartsList.find((value) => {
          return value.name === name + '-' + level
        });
        list = [arrData];
        list_name = [name + '-' + level];
      } else {
        if (this.selectList.length > 0) {
          this.selectList.map((values) => {
            let arrData = this.echartsList.find((value) => {
              if (this.searchForm.classList === '1') {
                return value.name === values.name
              } else {
                return value.name === values.name + '-' + values.level
              }
            });
            list.push(arrData);
            list_name.push(arrData.name);
          })
        } else {
          list = this.echartsList;
          list_name = this.echartsName;
        }
      }
      setTimeout(() => {
        this.drawLine(list, list_name);
      }, 300)
    },
    quXianClick() {
      let {classZu, deptIdList, partitionIdList, groupIdList, mySalesEmployeeList} = this.searchForm;
      if (classZu === '1' && !deptIdList.toString() && !partitionIdList.toString() && !groupIdList.toString() && !mySalesEmployeeList.toString()) {
        this.$message('部门、分区、小组、销售雇员至少选一个');
        return;
      }
      if (this.searchForm.dictType === 8) {
        this.$message('不做按物料号查询');
        return;
      }
      console.log(1233322,this.searchForm.flag);
      if (this.searchForm.flag === 1) {
        if (!this.searchForm.dictType){
          this.$message('请选择统计方式');
          return;
        }
        this.input0utPutCurve();
      } else if (this.searchForm.flag === 2) {
        this.proportionOfInputCategories();
      } else if (this.searchForm.flag === 3) {
        this.input0utPutHistogram();
      } else if (this.searchForm.flag === 5) {
        this.sampleTurnoverRate("1");
      } else if (this.searchForm.flag === 6) {
        this.sampleTurnoverRate("3");
      } else if (this.searchForm.flag === 7) {
        this.sampleTurnoverRate("4");
      } else if (this.searchForm.flag === 8) {
        this.sampleTurnoverRate("2");
      } else {
        this.$message('请选择统计类型');
      }
    },
    convertObjectToArray(obj, parentKey = "") {
      let result = [];

      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let currentKey = parentKey ? `${parentKey}-${key}` : key;

          if (typeof obj[key] === "object" && obj[key] !== null) {
            // 递归处理嵌套对象，但在到达最后一级时停止
            if (!("sumSampleTotal" in obj[key])) {
              result = result.concat(this.convertObjectToArray(obj[key], currentKey));
            } else {
              // 到达最后一级，将当前层级的信息添加到数组
              result.push({ table_name: currentKey, ...obj[key]  });
            }
          }
        }
      }

      return result;
    },
    digui(value) {
      let arr = [];
      let sumSalesQuota = [];//销货额
      let sumSampleTotal = [];//样品金额
      let echartList = [];
      let echartName = [];
      if (this.searchForm.classZu === '1'){
        if (typeof value === "object") {
          if (Object.keys(value).length > 0) {
            Object.keys(value).map((val) => {
              console.log('val',val);
              Object.keys(value[val]).map(vals=>{
                value[val][vals]['table_name'] = vals;
                arr.push(value[val][vals]);
                sumSalesQuota = Object.keys(value[val][vals].sumSalesQuota);
                sumSampleTotal = Object.keys(value[val][vals].sumSampleTotal);
              })

            })
          }
        }
      } else {
        if (typeof value === "object") {
          if (Object.keys(value).length > 0) {
            Object.keys(value).map((val) => {
              value[val]['table_name'] = val;
              arr.push(value[val]);
              sumSalesQuota = Object.keys(value[val].sumSalesQuota);
              sumSampleTotal = Object.keys(value[val].sumSampleTotal);
            })
          }
        }
      }

      console.log(111, arr, sumSalesQuota, sumSampleTotal);
      return {
        arr,
        sumSalesQuota,
        sumSampleTotal,
        echartList,
        echartName
      };
    },
    customers_group() {
      // if (this.searchForm.dictType === '11' || this.searchForm.dictType === '12') {
      //   this.$message('该统计方式无法查询');
      //   return;
      // }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/inputOutputAnalysis`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            industryList: this.searchForm.industryList.toString(),
            productCategoryList: this.searchForm.productCategoryList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            customerRatingList: this.searchForm.customerRatingList.toString(),
            materialList: this.searchForm.materialList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            flag: this.searchForm.dictType ? this.searchForm.dictType.toString() : '',
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/inputOutputAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {
              let arr = this.convertObjectToArray(response.data.mapResult);
              // const {
              //   sumSalesQuota,
              //   sumSampleTotal,
              //   echartList,
              //   echartName
              // } = this.digui(response.data.mapResult, 0);
              // sumQuota
              let a = arr.sort((a, b) => {
                return b.sumQuota - a.sumQuota
              });
              this.tableData = a;
              // this.tableDataTime = arrTime;
              this.sumSalesQuota = Object.keys(arr[0].sumSalesQuota);
              this.sumSampleTotal = Object.keys(arr[0].sumSampleTotal);
              // this.echartsList = echartList;
              // this.echartsName = echartName;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    processDynamicFunction(a){
      let b = [];
      let nameArray = [];
      let timeArray = [];

      function processDynamic(obj, dynamicKey = "") {
        for (let key in obj) {
          let currentKey = dynamicKey ? `${dynamicKey}-${key}` : key;

          if (typeof obj[key] === 'object') {
            processDynamic(obj[key], currentKey);
          } else {
            console.log('key',key)
            console.log('obj',obj)
            console.log('currentKey',currentKey)
            console.log('dynamicKey',dynamicKey)
            let name = dynamicKey; // 移除最后两层
            let time = Object.keys(obj);
            let data = Object.values(obj);  // 获取最后一层的值
            // 构建data
            let series = {
              name: name,
              type: currentKey.includes("产出") ? "line" : "bar",
              data: data
            };
            if (b.findIndex(e=>{return e.name === name}) === -1) {
              b.push(series);
              nameArray.push(name);
            }
            timeArray = time;
          }
        }
      }

      for (let dynamicKey in a) {
        processDynamic(a[dynamicKey], dynamicKey);
      }

      // 去除重复的日期
      timeArray = [...new Set(timeArray)];

      console.log("Name Array:", nameArray);
      console.log("Time Array:", timeArray);
      console.log("Result Array:", b);

      return {
        time:timeArray,
        name:nameArray,
        arr:b,
      }

    },
    input0utPutCurve() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/input0utPutCurve`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            industryList: this.searchForm.industryList.toString(),
            productCategoryList: this.searchForm.productCategoryList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            customerRatingList: this.searchForm.customerRatingList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            materialList: this.searchForm.materialList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            flag: this.searchForm.dictType.toString(),
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/input0utPutCurve', response.data);
            loading.close();
            if (response.data.code === 200) {
              let a = response.data.mapResult;
              // response.data.mapResult
              let name = [];
              let arr = [];
              let time = [];
              if (this.searchForm.classZu === '1' || this.searchForm.dictType == '11' || this.searchForm.dictType == '12'){

                let date = this.processDynamicFunction(a[Object.keys(a)[0]]);
                arr = date.arr;
                name = date.name;
                time = date.time;
              } else {
                arr = Object.keys(a).reduce((result, project) => {
                  let projectData = a[project];
                  let series = Object.keys(projectData).map(key => {
                    name.push(`${project}-${key}`);
                    time = Object.keys(projectData[key]);
                    return {
                      name: `${project}-${key}`,
                      type: key === "产出" ? "line" : "bar",
                      data: Object.values(projectData[key])
                    };
                  });

                  result = result.concat(series);
                  return result;
                }, []);
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(666,arr);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    proportionOfInputCategories() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/proportionOfInputCategories`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            industryList: this.searchForm.industryList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            customerRating: this.searchForm.customerRatingList.toString(),
            material: this.searchForm.materialList.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/proportionOfInputCategories', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult[Object.keys(response.data.mapResult)[0]];
              let name = [];
              let arr = [];
              let time = [];
              if (Object.keys(date).length > 0) {
                Object.keys(date).map((value, key) => {
                  console.log(111, value);
                  arr.push({
                    name: value,
                    value: date[value],
                  });
                })
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(arr, name, time);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    input0utPutHistogram() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/input0utPutHistogram`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            industryList: this.searchForm.industryList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            customerRating: this.searchForm.customerRatingList.toString(),
            material: this.searchForm.materialList.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/input0utPutHistogram', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              if (Object.keys(date).length > 0) {
                Object.keys(date).map((value, key) => {
                  console.log(111, value);
                  if (Object.keys(date[value]).length > 0) {
                    Object.keys(date[value]).map((val, ind) => {
                      console.log(222, val);
                      if (ind === 0) {
                        arr.push({
                          name: value + '-' + val,
                          type: 'line',// bar柱状图 pie饼图 line折线图
                          data: Object.values(date[value][val]),
                        });
                      } else {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: Object.values(date[value][val]),
                        });
                      }
                      time = Object.keys(date[value][val]);
                      name.push(value + '-' + val);
                    })
                  }

                })
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(arr, name, time);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    effectiveSampleDelivery(date) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/effectiveSampleDelivery`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.dictType.toString(),
            industry: this.searchForm.dictType === 1 ? date.industry : '',
            productCategory: this.searchForm.dictType === 2 ? date.productCategory : '',
            deptId: this.searchForm.dictType === 3 ? date.deptId.toString() : '',
            mySalesEmployee: this.searchForm.dictType === 4 ? date.mySalesEmployee : '',
          })
          .then((response) => {
            console.log('sampleDataAnalysis/effectiveSampleDelivery', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              if (Object.keys(date).length > 0) {
                Object.keys(date).map((value) => {
                  console.log(111, value);
                  if (Object.keys(date[value]).length > 0) {
                    Object.keys(date[value]).map((val) => {
                      console.log(222, val, date[value][val]);
                      if (Object.keys(date[value][val]).length > 0) {
                        Object.keys(date[value][val]).map((vals) => {
                          console.log(333, vals);
                          let find = arr.findIndex((vals_) => {
                            console.log(444, vals_)
                            return vals_.name === value + '-' + vals
                          })
                          console.log(555, find, value + '-' + vals)
                          if (find > -1) {
                            arr[find].data.push(date[value][val][vals])
                          } else {
                            arr.push({
                              name: value + '-' + vals,
                              type: 'bar',// bar柱状图 pie饼图 line折线图
                              data: [date[value][val][vals]],
                            });
                          }
                          name.push(value + '-' + vals);
                        })
                      }

                      time = Object.keys(date[value]);
                    })
                  }
                })
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(arr, name, time);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleTurnoverRate(type) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleTurnoverRate`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.dictType.toString(),
            industryList: this.searchForm.industryList.toString(),
            productCategoryList: this.searchForm.productCategoryList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            type: type,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/input0utPutHistogram', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              if (Object.keys(date).length > 0) {
                Object.keys(date).map((value) => {
                  console.log(111, value);
                  if (Object.keys(date[value]).length > 0) {
                    Object.keys(date[value]).map((val) => {
                      console.log(222, val);
                      if (type === "1") {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: [date[value][val].sampleTurnoverRate],
                        });
                      } else if (type === "3") {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: [date[value][val].repeatSampleFrequency],
                        });
                      } else if (type === "4") {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: [date[value][val].saleSpanMonth],
                        });
                      } else if (type === "2") {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: [date[value][val].sampleRateOfReturn],
                        });
                      }

                      time = [calendarMonthInt[0] + "~" + calendarMonthInt[1]];
                      name.push(value + '-' + val);
                    })
                  }

                })
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(arr, name, time);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = {
        deptId: this.searchForm.deptIdList.toString(),
        partitionId: this.searchForm.partitionIdList.toString(),
        groupId: this.searchForm.groupIdList.toString(),
        roleName: this.roleMap.roleName,
        id: JSON.parse(sessionStorage.getItem('user')).id,
      }

      if (this.searchForm.dictType === 16) {
        date = {
          deptId: this.user_date.deptId,
          partitionId: this.user_date.partitionId,
          groupId: this.user_date.groupId,
          roleName: this.user_date.roleName,
          id: JSON.parse(sessionStorage.getItem('user')).id,
        }
      }
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, date)
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    ssCrDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ssCrDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let {classZu, deptIdList, partitionIdList, groupIdList, mySalesEmployeeList} = this.searchForm;
          if (classZu === '1' && !deptIdList.toString() && !partitionIdList.toString() && !groupIdList.toString() && !mySalesEmployeeList.toString()) {
            this.$message('部门、分区、小组、销售雇员至少选一个');
            return;
          }
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
