<template>
  <div id="app">
    <LeftNav v-if="this.$route.path!=='/'"/>
    <div class="Right">
      <RightHeader v-if="this.$route.path!=='/'"/>
      <div class="AppContent">
        <router-view/>
      </div>
      <RightBottom v-if="this.$route.path!=='/'"/>
    </div>
  </div>
</template>

<script>
import LeftNav from './components/LeftNav'
import RightHeader from './components/RightHeader'
import RightBottom from './components/RightBottom'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "app",
  components: {
    LeftNav,
    RightHeader,
    RightBottom,
  },
  mounted() {
    console.log(33,this.isMobile())
    if (this.isMobile()) {
      alert('本应用为PC应用，请在电脑浏览器打开！')
    }
  },
  methods: {
    isMobile() {
      const userAgent = navigator.userAgent;
      const mobileKeywords = ['Mobile', 'Android', 'iPhone', 'iPad', 'Windows Phone'];

      return mobileKeywords.some(keyword => userAgent.includes(keyword));
    }
  }
}
</script>

<style>

.select_xin {
  cursor: pointer;
  color: #FFFFFF;
  line-height: 40px;
  width: 120px;
  height: 40px;
  background: #00674f;
  text-align: center;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.select_sou {
  cursor: pointer;
  color: #FFFFFF;
  line-height: 40px;
  width: 120px;
  height: 40px;
  background: #00674f;
  text-align: center;
  margin-left: 10px;
  border-radius: 20px;
  font-size: 14px;
}

.select_chong {
  cursor: pointer;
  color: #353535;
  line-height: 40px;
  min-width: 120px;
  height: 40px;
  text-align: center;
  margin-left: 10px;
  border-radius: 20px;
  border: 1px solid #B7B7B7;
  box-sizing: border-box;
  font-size: 14px;
}

.select_xia {
  cursor: pointer;
  width: 120px;
  height: 40px;
  background-color: #00674f;
  text-align: center;
  margin-left: 10px;
  border-radius: 5px;
  color: #FFFFFF;
  line-height: 40px;
  font-size: 14px;
}

.AppContent {
  flex: 1;
  overflow: hidden;
}

.Right {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba(242, 242, 242, 1);
}

* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: rgba(242, 242, 242, 1);
}

body, html {
  height: 100%;
}
</style>
