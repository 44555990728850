<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">登录日志</span>
        </el-col>
<!--        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">-->
<!--          <div v-if="$buttonStatus('部门-添加')" @click="addOfEdit(true)" class="select_xin">新增</div>-->
<!--        </el-col>-->
      </el-row>
      <el-form ref="searchForm" :rules="searchForm" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="18">
            <el-form-item prop="name" label="登录人名称" label-width="120px">
              <el-input style="width: 40%" v-model="searchForm.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="customers_group()" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table

              :data="tableData"
              style="width: 100%">
            <el-table-column
                prop="name"
                label="登录人"
            >
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="登录时间"
            >
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="tanStatus?'添加部门':'修改部门'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="deptName" label="部门名称" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.deptName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "DepartmentList",
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      searchForm: {
        name: "",
      },
      ruleForm: {
        id: 0,
        deptName: "",
        createBy: "",
      },
      rules: {
        deptName: [
          {required: true, message: '请输入部门名称', trigger: 'blur'}
        ],
        createBy: [
          {required: true, message: '请输入创建者', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 10,
      tableData: [],
    }
  },
  mounted() {
    this.customers_group();
  },
  methods: {
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,
          deptName: "",
          createBy: "",
        }
      } else {
        this.ruleForm.id = data.id;
        this.ruleForm.deptName = data.deptName;
        this.ruleForm.createBy = data.createBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', this.pageSize);
      data.append('name', this.searchForm.name);
      this.axios
          .post(this.$urlHttp + `/homepage/list`, data)
          .then((response) => {
            console.log('homepage/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('deptName', this.ruleForm.deptName);
      data.append('createBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysDept/add`, data)
          .then((response) => {
            loading.close();
            console.log('sysDept/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('deptName', this.ruleForm.deptName);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysDept/edit`, data)
          .then((response) => {
            loading.close();
            console.log('sysDept/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sysDept/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sysDept/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}



.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
