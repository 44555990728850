<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">分区管理</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div v-if="$buttonStatus('分区-添加')" @click="addOfEdit(true)" class="select_xin">新增</div>
        </el-col>
      </el-row>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              default-expand-all
              row-key="row-id"
              :tree-props="{children: 'groupList'}"
              :data="tableData"
              style="width: 100%">
            <el-table-column
                prop="id"
                type="index"
                label="编号"
            >
            </el-table-column>
            <el-table-column
                prop="deptName"
                label="部门名称"
            >
            </el-table-column>
            <el-table-column
                prop="partitionName"
                label="分区名称"
            >
            </el-table-column>
            <el-table-column
                label="操作"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.deptId">
                  <el-button
                      v-if="$buttonStatus('分区-添加')"
                      size="mini"
                      type="primary"
                      @click="addOfEdit_xiao(true, scope.row)">新增小组
                  </el-button>
                  <el-button
                      v-if="$buttonStatus('分区-编辑')"
                      size="mini"
                      type="warning"
                      @click="addOfEdit(false, scope.row)">编辑分区
                  </el-button>
                  <el-popover
                      v-if="$buttonStatus('分区-删除')"
                      style="margin-left: 10px"
                      placement="top"
                      width="160"
                      v-model="scope.row.tanshan">
                    <p>确定删除该分区吗？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                      <el-button type="primary" size="mini" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                        确定
                      </el-button>
                    </div>
                    <el-button
                        slot="reference"
                        size="mini"
                        type="danger">删除分区
                    </el-button>
                  </el-popover>
                </template>
                <template v-else>
                  <el-button
                      v-if="$buttonStatus('分区-编辑')"
                      size="mini"
                      type="warning"
                      @click="addOfEdit_xiao(false, scope.row)">编辑小组
                  </el-button>
                  <el-popover
                      v-if="$buttonStatus('分区-删除')"
                      style="margin-left: 10px"
                      placement="top"
                      width="160"
                      v-model="scope.row.tanshan_xiao">
                    <p>确定删除该小组吗？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="text" @click="scope.row.tanshan_xiao = false">取消</el-button>
                      <el-button type="primary" size="mini" @click="customers_groupDel_xiao(scope.row.id,scope.row.tanshan_xiao)">
                        确定
                      </el-button>
                    </div>
                    <el-button
                        slot="reference"
                        size="mini"
                        type="danger">删除小组
                    </el-button>
                  </el-popover>
                </template>



              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="tanStatus?'添加分区':'修改分区'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="partitionName" label="分区名称" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.partitionName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="deptId" label="查看菜单" label-width="100px">
          <el-select style="width: 50%" filterable v-model="ruleForm.deptId">
            <el-option
                v-for="(value,key) in deptDropDownBoxList"
                :key="key"
                :label="value.deptName"
                :value="value.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :title="tanStatus_xiao?'添加小组':'修改小组'" :visible.sync="dialogFormVisible_xiao">
      <el-form ref="ruleForm_xiao" :rules="rules_xiao" :model="ruleForm_xiao">
        <el-form-item prop="groupName" label="小组名称" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm_xiao.groupName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit_xiao('ruleForm_xiao')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm_xiao')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "RoleList",
  components: {},
  data() {
    return {
      dialogFormVisible_xiao: false,
      dialogFormVisible: false,
      tanStatus_xiao: true,
      tanStatus: true,
      searchForm: {
        partitionName: "",
        deptId: "",
      },
      ruleForm: {
        id: "",
        deptId: "",
        partitionName: "",
        updateBy: "",
      },
      rules: {
        deptId: [
          {required: true, message: '请选择部门', trigger: 'blur'}
        ],
        partitionName: [
          {required: true, message: '请输入分区名称', trigger: 'blur'}
        ],
      },
      ruleForm_xiao: {
        id: "",
        partitionId: "",// 分区id
        groupName: "",// 小组名称
        createBy: "",// 创建者
      },
      rules_xiao: {
        groupName: [
          {required: true, message: '请输入小组名称', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      tableData: [],
      getModelTreeList: [],
      deptDropDownBoxList: [],
      fuList: [],
    }
  },
  mounted() {
// “deptName”: 部门名称,
// “partitionName”: 分区名称,
// “createBy”: 创建者,
// “createTime”: 创建时间,
// “updateBy”: 更新者,
// “updateTime”: 更新时间,
    this.customers_group();
    this.deptDropDownBox();
    // this.getModelTree();
  },
  methods: {
    deptDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDownBox`, {})
          .then((response) => {
            console.log('dropDownBox/deptDropDownBox', response.data);
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleNodeClick(value, data) {
      console.log(value, data);
      let arr = [];
      arr = arr.concat(data.checkedNodes, data.halfCheckedNodes);
      this.ruleForm.moduleIds = arr;
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    resArr(arr1, arr2) {
      return arr1.filter((v) => arr2.every((val) => val !== v));
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: "",
          deptId: "",
          partitionName: "",
          updateBy: "",
        }
        console.log(111, this.ruleForm);
      } else {
        this.ruleForm.id = data.id;
        this.ruleForm.deptId = data.deptId;
        this.ruleForm.partitionName = data.partitionName;
        this.ruleForm.updateBy = data.updateBy;
      }
      this.dialogFormVisible = true;
    },
    addOfEdit_xiao(type, data) {
      this.tanStatus_xiao = type;
      if (type) {
        this.ruleForm_xiao = {
          id: "",
          partitionId:data.id,
          groupName:"",
          createBy:"",
        }
        console.log(111, this.ruleForm);
      } else {
        this.ruleForm_xiao.id = data.id;
        this.ruleForm_xiao.partitionId = data.partitionId;
        this.ruleForm_xiao.groupName = data.groupName;
        this.ruleForm_xiao.updateBy = data.updateBy;
      }
      this.dialogFormVisible_xiao = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', 20);
      data.append('partitionName', this.searchForm.partitionName);
      data.append('deptId', this.searchForm.deptId);
      this.axios
          .post(this.$urlHttp + `/sysPartition/list`, data)
          .then((response) => {
            console.log('sysPartition/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              if (response.data.data.length > 0) {
                response.data.data.map((value, key) => {
                  value['row-id'] = value.id;
                  if (value.groupList.length > 0) {
                    value.groupList.map((val, keys) => {
                      val['deptName'] = val.groupName;
                      val['row-id'] = value.id + val.id;
                    })
                  }
                })
              }
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    getModelTree() {
      this.axios
          .get(this.$urlHttp + `/sysModel/getModelTree`, {})
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDownBox', response.data);
            if (response.data.code === 200) {
              let date = response.data.data;
              let fuList = [];
              if (date.length > 0) {
                date.map((value) => {
                  value['children'] = value.chiLds;
                  if (value.chiLds && value.chiLds.length > 0) {
                    fuList.push(value.id);
                    value.chiLds.map((val) => {
                      val['children'] = val.buttonList;
                      if (val.buttonList && val.buttonList.length > 0) {
                        fuList.push(val.id)
                        val.buttonList.map((values, keys) => {
                          values['modelName'] = values.buttonName;
                        })
                      }
                    });
                  }
                })
              }
              this.fuList = fuList;
              this.getModelTreeList = date;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('deptId', this.ruleForm.deptId);
      data.append('partitionName', this.ruleForm.partitionName);
      data.append('createBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysPartition/add`, data)
          .then((response) => {
            loading.close();
            console.log('sysPartition/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('deptId', this.ruleForm.deptId);
      data.append('partitionName', this.ruleForm.partitionName);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysPartition/edit`, data)
          .then((response) => {
            loading.close();
            console.log('sysPartition/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sysPartition/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sysPartition/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd_xiao() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('partitionId', this.ruleForm_xiao.partitionId);
      data.append('groupName', this.ruleForm_xiao.groupName);
      data.append('createBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysGroup/add`, data)
          .then((response) => {
            loading.close();
            console.log('sysGroup/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible_xiao = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit_xiao() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm_xiao.id);
      data.append('partitionId', this.ruleForm_xiao.partitionId);
      data.append('groupName', this.ruleForm_xiao.groupName);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysGroup/edit`, data)
          .then((response) => {
            loading.close();
            console.log('sysGroup/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible_xiao = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel_xiao(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sysGroup/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sysGroup/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addSubmit_xiao(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus_xiao) {
            this.customers_groupAdd_xiao();
          } else {
            this.customers_groupEdit_xiao();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
