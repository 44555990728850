<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">销售订单分布</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="calendarMonthInt" label="历年" label-width="100px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.calendarMonthInt"
                  value-format="yyyy/MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="industryS" label="行业" label-width="100px">
              <el-select filterable style="width: 99%" multiple collapse-tags
                         :disabled="searchForm.classList === 1"
                         v-model="searchForm.industryS">
                <el-option
                    v-for="(value,key) in orderDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="deptId" label="部门" label-width="100px">
              <el-select @change="deptChange" filterable multiple collapse-tags :disabled="searchForm.classZu !== '1' && searchForm.classList !== 7"
                         style="width: 99%" v-model="searchForm.deptId">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployee" label="销售雇员" label-width="100px">
              <el-select filterable multiple collapse-tags :disabled="searchForm.classZu !== '1' && searchForm.classList !== 10"
                         style="width: 99%" v-model="searchForm.mySalesEmployee">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="classList" label="统计方式" label-width="100px">
              <el-select @change="selectChange" filterable style="width: 99%"
                         v-model="searchForm.classList">
                <el-option
                    v-for="(value,key) in orderAmountStatisticsModeList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerAbbreviation" label="客户" label-width="100px">
              <el-select filterable multiple collapse-tags :disabled="searchForm.classList === 1"
                         style="width: 99%" v-model="searchForm.customerAbbreviation">
                <el-option
                    v-for="(value,key) in orderDataCustomerDropboxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdList" label="分区" label-width="100px">
              <el-select @change="partitionChange" filterable multiple collapse-tags style="width: 99%"
                         :disabled="searchForm.classZu !== '1' && searchForm.classList !== 8" v-model="searchForm.partitionIdList">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="classZu" label="是否组合查询" label-width="100px">
              <el-select filterable collapse-tags style="width: 99%"
                         @change="classZuChange"
                         :disabled="(searchForm.classList !== 2 && searchForm.classList !== 9 && searchForm.classList !== 10) || user_identity"
                         v-model="searchForm.classZu">
                <el-option
                    label="是"
                    value="1">
                </el-option>
                <el-option
                    label="否"
                    value="0">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="level" label="订单额分类" label-width="100px">
              <el-select clearable filterable style="width: 99%"
                         v-model="searchForm.level">
                <el-option
                    label="样品&寄售&退货单"
                    value="1">
                </el-option>
                <el-option
                    label="小额订单"
                    value="2">
                </el-option>
                <el-option
                    label="一般订单"
                    value="3">
                </el-option>
                <el-option
                    label="大额订单"
                    value="4">
                </el-option>
                <el-option
                    label="超大订单"
                    value="5">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerRating" label="评级" label-width="100px">
              <el-select filterable clearable :disabled="searchForm.classList === 1" style="width: 99%"
                         v-model="searchForm.customerRating">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="100px">
              <el-select @change="groupChange" filterable multiple :disabled="searchForm.classZu !== '1' && searchForm.classList !== 9" collapse-tags
                         style="width: 99%"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="addSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
              <div @click="addOfEdit('')" style="margin-top: 10px" class="select_sou">曲线图</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="name"
                label="分类"
            >
            </el-table-column>
            <el-table-column
                prop="names"
                label="二级分类"
            >
            </el-table-column>
            <el-table-column
                prop="level"
                label="数额范围"
            >
            </el-table-column>
            <el-table-column
                v-for="(item,key) in tableDataTime"
                :key="key"
                :prop="item"
                :label="item"
            >
              <template slot-scope="scope">
                <span>{{
                    scope.row[item][scope.row.level]
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
                v-if="tableCao"
            >
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="warning"
                    @click="addOfEdit(scope.row)">曲线图
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="曲线图" :visible.sync="dialogFormVisible">
      <div id="myChart" style="width: 100%;height: 500px"></div>
    </el-dialog>
    <el-dialog title="搜索" :visible.sync="searchVisible">
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('searchForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderListOut",
  mixins: [mixins],
  components: {},
  data() {
    return {
      tableCao: true,
      searchVisible: false,
      dialogFormVisible: false,
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      tableDataTime: [],
      searchForm: {
        calendarMonthInt: [],
        calendarMonthIntStart: "",
        calendarMonthIntEnd: "",
        classZu: "0",
        classList: "",
        orderTotal: "",
        dictType: "",
        industryS: "",
        productCategoryS: "",
        groupIdList: "",
        level: "",
        deptId: "",
        mySalesEmployee: "",
        customerRating: "",
        partitionIdList: "",
        customerAbbreviation: ""
      },
      searchs: {
        calendarMonthInt: [
          {required: true, message: '请选择历年', trigger: 'blur'}
        ],
        classList: [
          {required: true, message: '请选择统计方式', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      selectList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
      echartsList: [],
      echartsName: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      ratingFunctionList: [],
      groupDropDownList: [],
      customerRatingDropDownBoxList: [],
      orderAmountStatisticsModeList: [],
      partitionDropDownBoxList: [],
      orderDataIndustryDropboxList: [],
      orderDataCustomerDropboxList: [],
      echats_date: '',
    }
  },
  mounted() {
    console.log(123333,this.$route)
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    // this.IndustryFunction();
    // this.product_categoryFunction();
    this.deptDropDownBox();
    this.salesEmployeeDropDownBox();
    this.ratingFunction();
    this.customerRatingDropDownBox();
    this.orderAmountStatisticsMode();
    this.orderDataIndustryDropbox();
    this.orderDataCustomerDropbox();
    // this.partitionDropDownBox();
    // this.groupDropDown();
  },
  methods: {
    classZuChange(e) {
      console.log(111, e);
      if (e === '0') {
        this.$set(this.searchForm, 'deptId', []);
        this.$set(this.searchForm, 'partitionIdList', []);
        this.$set(this.searchForm, 'groupIdList', []);
        this.$set(this.searchForm, 'mySalesEmployee', []);
      }
    },
    orderDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    echartsTitle() {
      let name = '曲线图';
      if (this.searchForm.classList) {
        let date = this.orderAmountStatisticsModeList.find((value) => {
          return value.dictValue === this.searchForm.classList
        })
        if (date) {
          name = date.dictLabel + name
        }
      }
      return name
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
      }
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
      }
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.salesEmployeeDropDownBox();
      }
    },
    groupDropDown() {
      let date = `roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptId}`
      if (this.searchForm.classList === 9) {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&groupId=${this.user_date.groupId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    selectChange(e) {
      console.log(e);
      this.searchForm.classZu = "0";
      this.searchForm.groupIdList = "";

      this.searchForm.level = "";
      this.searchForm.industryS = "";
      this.searchForm.customerAbbreviation = "";
      this.searchForm.deptId = "";
      this.searchForm.customerRating = "";
      this.searchForm.mySalesEmployee = "";
      this.searchForm.partitionIdList = "";

      if (e === 10) {
        this.salesEmployeeDropDownBox();
      } else if (e === 8) {
        this.partitionDropDownBox();
      } else if (e === 9) {
        this.groupDropDown();
      }
    },
    partitionDropDownBox() {
      let date = `roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptId.toString()}`
      if (this.searchForm.classList === 8) {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderAmountStatisticsMode() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=orderAmountStatisticsMode`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=orderAmountStatisticsMode', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderAmountStatisticsModeList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "销售订单分布.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    radioChange(e) {
      console.log(111, e);
      this.tableData = [];
      this.searchForm.mySalesEmployee = "";
      this.searchForm.industryS = "";
      this.searchForm.productCategoryS = "";
      this.searchForm.customerRating = "";
      this.searchForm.level = "";
      this.searchForm.deptId = "";
      this.searchForm.customerAbbreviation = "";
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/rateDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/rateDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = response.data.rateList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = {
        deptId: this.searchForm.deptId.toString(),
        partitionId: this.searchForm.partitionIdList.toString(),
        groupId: this.searchForm.groupIdList.toString(),
        roleName: this.roleMap.roleName,
        id: JSON.parse(sessionStorage.getItem('user')).id,
      }

      if (this.searchForm.classList === 10) {
        date = {
          deptId: this.user_date.deptId,
          partitionId: this.user_date.partitionId,
          groupId: this.user_date.groupId,
          roleName: this.user_date.roleName,
          id: JSON.parse(sessionStorage.getItem('user')).id,
        }
      }
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, date)
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    drawLine(list, name) {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'))
      let option = {
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: this.tableDataTime
        },
        legend: {
          data: name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'value'
        },
        series: list,
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    handleSelectionChange(val) {
      console.log(111, val);
      this.selectList = val;
    },
    addOfEdit(valueData) {
      if (this.echats_date) {
        this.echats_date.clear();
      }
      this.dialogFormVisible = true;
      let list = [];
      let list_name = [];
      if (valueData) {
        let dataList = [];
        this.tableDataTime.map((valuess) => {
          dataList.push(valueData[valuess][valueData.level]);
        })
        list = [{
          name: valueData['name'] + '_' + valueData['names'] + '_' + valueData['level'],
          type: 'line',
          data: dataList,
        }];
        list_name = [valueData['name'] + '_' + valueData['names'] + '_' + valueData['level']];

      } else {
        if (this.selectList.length > 0) {
          this.selectList.map((values) => {
            let dataList = [];
            console.log(111, values);
            this.tableDataTime.map((valuess) => {
              dataList.push(values[valuess][values.level]);
            })
            console.log(222, dataList);
            list.push({
              name: values['name'] + '_' + values['names'] + '_' + values['level'],
              type: 'line',
              data: dataList,
            })
            list_name.push(values['name'] + '_' + values['names'] + '_' + values['level']);

          })
        } else {
          if (this.tableData.length > 0) {
            this.tableData.map((values) => {
              let dataList = [];
              console.log(111, values);
              this.tableDataTime.map((valuess) => {
                dataList.push(values[valuess][values.level]);
              })
              console.log(222, dataList);
              list.push({
                name: values['name'] + '_' + values['names'] + '_' + values['level'],
                type: 'line',
                data: dataList,
              })
              list_name.push(values['name'] + '_' + values['names'] + '_' + values['level']);

            })
          }
        }
      }
      setTimeout(() => {
        this.drawLine(list, list_name);
      }, 300)
    },
    IfFunction() {
      let date = {
        2: 'industryS',
        3: 'customerAbbreviation',
      }
      if (date[this.searchForm.classList] && this.searchForm[date[this.searchForm.classList]].length <= 0) {
        this.$message('所选统计方式不能为空');
        return true;
      } else {
        return false;
      }
    },
    digui2(arrs, num) {

      let arr = [];
      let arrTime = [];
      let arrObj = {};
      let arrValue = [];
      let arrValues = [];
      let name = '';
      let nameClass = '';
      let names = '';
      let echartList = [];
      let echartName = [];

      if (this.searchForm.classZu === '1'){
        let list = (arrs, num, obj)=>{
          if (Object.keys(arrs).length > 0) {
            Object.keys(arrs).map((res,keys) => {
              console.log(666,res,num);
              if (num===0){
                name = res;
                console.log('name',name);
              } else if (num === 1){
                console.log('name11',name);
                nameClass = res;
              } else if (num === 2){
                names = res;
                arrValues.push({
                  name:name + '-' +nameClass,
                  names:res,
                })
                arrTime = [];
              } else if (num === 3){
                arrTime.push(res);
                arrObj[res] = arrs[res];

                if (!arrValues[arrValues.length - 1]['arrObj']){
                  arrValues[arrValues.length - 1]['arrObj'] = {}
                }
                arrValues[arrValues.length - 1]['arrObj'][res] = arrs[res];

                arrValue = Object.keys(arrs[res]);
              }
              return list(arrs[res],num+1, obj)
            })
          }
        }
        list(arrs,num, {});

        arrValues.map(resA=>{
          arrValue.map(res=>{
            echartName.push(names + '_' + res);
            arr.push({
              name:resA.name,
              names:resA.names,
              level:res,
              ...resA.arrObj
            })
          })
        })


      }else if (this.searchForm.classList == '6') {
        let arrValueAA = [];
        let list = (arrs, num, obj)=>{
          if (Object.keys(arrs).length > 0) {
            Object.keys(arrs).map((res,keys) => {
              console.log(666,res,num);
              if (num===0){
                name = res;
              } else if (num === 1){
                names = res;
                arrTime = [];
                arrValue.push({
                  name:name,
                  names:res,

                })
              } else if (num === 2){
                arrTime.push(res);
                arrObj[res] = arrs[res];

                if (!arrValue[arrValue.length - 1]['arrObj']){
                  arrValue[arrValue.length - 1]['arrObj'] = {}
                }
                arrValue[arrValue.length - 1]['arrObj'][res] = arrs[res];
                arrValueAA = Object.keys(arrs[res]);
              } else if (num === 3) {

                arrValue[arrValue.length - 1]['level'] = res;
              }
              return list(arrs[res],num+1, obj)
            })
          }
        }
        list(arrs,num, {});

        arrValue.map(res=>{
          arrValueAA.map(val=>{
            echartName.push(res.names + '_' + res.level);
            arr.push({
              name:res.name,
              names:res.names,
              level:val,
              ...res.arrObj
            })
          })

        })

      } else {
        let list = (arrs, num, obj)=>{
          if (Object.keys(arrs).length > 0) {
            Object.keys(arrs).map((res,keys) => {
              console.log(666,res,num);
              if (num===0){
                name = res;
              } else if (num === 1){
                names = res;
                arrTime = [];
              } else if (num === 2){
                arrTime.push(res);
                arrObj[res] = arrs[res];
                arrValue = Object.keys(arrs[res]);
              }
              return list(arrs[res],num+1, obj)
            })
          }
        }
        list(arrs,num, {});

        arrValue.map(res=>{
          echartName.push(names + '_' + res);
          arr.push({
            name,
            names,
            level:res,
            ...arrObj
          })
        })
      }

      console.log(555,arr,arrTime,arrObj,arrValue);
      return {
        arr,
        arrTime,
        echartList,
        echartName
      };
    },
    customers_group() {
      if (this.IfFunction()) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/order/summaryAnalysis`, {
            calendarMonthIntStart: calendarMonthInt[0],
            calendarMonthIntEnd: calendarMonthInt[1],
            dictType: this.searchForm.classList.toString(),
            industryList: this.searchForm.industryS.toString(),
            productCategoryList: this.searchForm.productCategoryS.toString(),
            level: this.searchForm.level ? this.searchForm.level : "0",
            deptIdList: this.searchForm.deptId.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployee.toString(),
            customerRating: this.searchForm.customerRating,
            customerAbbreviationList: this.searchForm.customerAbbreviation.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/summaryAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {

              const {
                arr,
                arrTime,
                echartList,
                echartName
              } = this.digui2(response.data.mapResult, 0);
              let a = arr;
              if (this.searchForm.level) {
                let name = Object.keys(arr[0][arrTime[arrTime.length - 1]])[0];
                console.log(111, name);
                a = arr.sort((a, b) => {
                  return b[arrTime[arrTime.length - 1]][name] - a[arrTime[arrTime.length - 1]][name]
                })
              }
              console.log(7777, a)
              console.log(8888, arrTime)
              this.tableData = a;
              this.tableDataTime = arrTime;
              this.echartsList = echartList;
              this.echartsName = echartName;


            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    digui(value, key) {
      let arr = [];
      let arrTime = [];
      let echartList = [];
      let echartName = [];

      let a = (value, key, obj = {}, objValue) => {
        let Obj_Value = []
        if (typeof value === 'object') {
          Obj_Value = Object.keys(value);
        }
        console.log({
          value, key, obj
        });
        if (Obj_Value.length > 0) {
          Obj_Value.map((value_v, value_k) => {
            if (key === 0) {
              obj['name'] = value_v;
            } else if (key === 1) {
              obj['names'] = value_v;
            } else if (key === 2) {
              arrTime = Obj_Value;
              obj[value_v] = value[value_v];
              // console.log(444, value_v, objValue, Obj_Value, value);
              // if (Obj_Value.length - 1 === value_k) {
              //   console.log(666,JSON.parse(JSON.stringify(obj)))
              //   arr.push(JSON.parse(JSON.stringify(obj)));
              //   echartName.push(obj['name'] + '_' + obj['names']);
              //   echartList.push({
              //     name: obj['name'] + '_' + obj['names'],
              //     type: 'line',
              //     data: Object.values(value),
              //   })
              // }
            } else if (key === 3) {
              obj['level'] = value_v;
              if (objValue === arrTime[arrTime.length - 1]) {
                // console.log(555, value_v, objValue, Obj_Value, value);
                arr.push(JSON.parse(JSON.stringify(obj)));
                echartName.push(obj['names'] + '_' + obj['level']);
                // echartList.push({
                //   name: obj['names'] + '_' + obj['level'],
                //   type: 'line',
                //   data: Object.values(value),
                // })
                // console.log(666, JSON.parse(JSON.stringify(obj)));
              }
            }

            a(value[value_v], key + 1, JSON.parse(JSON.stringify(obj)), value_v);
          })
        }
      }

      a(value, key);
      console.log(arr);
      return {
        arr,
        arrTime,
        echartList,
        echartName
      };
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let {classZu, deptId, partitionIdList, groupIdList, mySalesEmployee} = this.searchForm;
          if (classZu === '1' && !deptId.toString() && !partitionIdList.toString() && !groupIdList.toString() && !mySalesEmployee.toString()) {
            this.$message('部门、分区、小组、销售雇员至少选一个');
            return;
          }
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName);
      this.tableData = [];
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
