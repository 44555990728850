<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D;margin-right: 100px">未出货明细导入</span>
          <span style="color: #3D3D3D">当前月度:{{queryTheLatestMonthList}}月</span>
        </el-col>
        <el-col :span="12" style="display: flex;align-items:center;justify-content: flex-end">
          <!--          <div @click="addOfEdit(true)" class="select_xin">新增</div>-->
          <el-upload
              v-if="$buttonStatus('未出货明细-导入')"
              :action="`${$urlHttp}/unshippedDetails/uploadFile`"
              :before-remove="beforeRemove"
              :data="{createBy:user.userName}"
              :on-change="handleUpdate"
              :on-exceed="handleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :show-file-list="false"
              class="upload-demo"
              multiple>
            <div class="select_xin">选择文件</div>
          </el-upload>
          <a :href="`${$urlHttp}/statics/excel/未出货明细导入模板.xlsx`" style="text-decoration: none">
            <div class="select_xia">下载数据模板</div>
          </a>
          <div @click="exportExcelSampleList" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchForm" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="month" label="月" label-width="80px">
              <el-date-picker
                  style="width:99%"
                  v-model="searchForm.month"
                  format="MM"
                  value-format="MM"
                  type="month"
                  placeholder="选择月">
              </el-date-picker>
            </el-form-item>

            <el-form-item prop="deptIdList" label="部门" label-width="80px">
              <el-select @change="deptChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.deptIdList">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployeeList" label="销售雇员" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.mySalesEmployeeList">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="industryList" label="行业" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.industryList">
                <el-option
                    v-for="(value,key) in orderDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdList" label="分区" label-width="80px">
              <el-select @change="partitionChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.partitionIdList">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerAbbreviationList" label="客户" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.customerAbbreviationList">
                <el-option
                    v-for="(value,key) in orderDataCustomerDropboxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="productCategoryList" label="产品大类" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.productCategoryList">
                <el-option
                    v-for="(value,key) in orderDataProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="80px">
              <el-select @change="groupChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerRatingList" label="客户评级" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.customerRatingList">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="customers_group()" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
            </el-form-item>
            <div style="display: flex">
              <el-popconfirm
                  v-if="$buttonStatus('订单明细-删除')"
                  title="确认批量删除吗？"
                  @confirm="delBatch"
              >
                <div slot="reference" v-if="$buttonStatus('订单明细-删除')" class="select_chong">批量删除</div>
              </el-popconfirm>
              <el-popconfirm
                  v-if="$buttonStatus('订单明细-删除')"
                  title="确认删除搜索结果吗？"
                  @confirm="delBatchSearch"
              >
                <div slot="reference" v-if="$buttonStatus('订单明细-删除')" class="select_chong">删除搜索结果</div>
              </el-popconfirm>
<!--              <div v-if="$buttonStatus('订单明细-删除')" @click="delBatchSearch" class="select_chong">删除搜索结果</div>-->
            </div>

          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              :data="tableData"
              style="width: 100%"
              @selection-change="handleSelectionChange">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                label="客户代码"
                prop="customerCode"
            >
            </el-table-column>
            <el-table-column
                label="凭证日期"
                prop="voucherTime"
            >
            </el-table-column>
            <el-table-column
                label="SD凭证"
                prop="sdVoucher"
            >
            </el-table-column>
            <el-table-column
                label="客户物料"
                prop="customerMaterial"
            >
            </el-table-column>
            <el-table-column
                label="物料"
                prop="material"
            >
            </el-table-column>
            <el-table-column
                label="订单数量"
                prop="orderAmount"
            >
            </el-table-column>
            <el-table-column
                label="含税单价"
                prop="unitPrice"
            >
            </el-table-column>
            <el-table-column
                label="已过帐数量"
                prop="postedQuantity"
            >
            </el-table-column>
            <el-table-column
                label="创建者"
                prop="createBy"
            >
            </el-table-column>
            <el-table-column
                label="创建时间"
                prop="createTime"
            >
            </el-table-column>
            <el-table-column
                label="更新者"
                prop="updateBy"
            >
            </el-table-column>
            <el-table-column
                label="更新时间"
                prop="updateTime"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
            >
              <template slot-scope="scope">
                <el-button
                    v-if="$buttonStatus('未出货明细-编辑')"
                    size="mini"
                    type="warning"
                    @click="addOfEdit(false, scope.row)">编辑
                </el-button>
                <el-popover
                    v-if="$buttonStatus('未出货明细-删除')"
                    v-model="scope.row.tanshan"
                    placement="top"
                    style="margin-left: 10px"
                    width="160">
                  <p>确定删除该未出货明细吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                    <el-button size="mini" type="primary" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            :current-page="currentPage4"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 100,1000]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="tanStatus?'添加未出货明细':'修改未出货明细'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item label="客户代码" label-width="100px" prop="customerCode">
          <el-input v-model="ruleForm.customerCode" autocomplete="off" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="凭证日期" label-width="100px" prop="voucherDate">
          <el-date-picker
              value-format="yyyy/MM/dd"
              style="width: 50%"
              v-model="ruleForm.voucherDate"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="SD凭证" label-width="100px" prop="sdVoucher">
          <el-input v-model="ruleForm.sdVoucher" autocomplete="off" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="客户物料" label-width="100px" prop="customerMaterial">
          <el-input v-model="ruleForm.customerMaterial" autocomplete="off" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="物料" label-width="100px" prop="material">
          <el-input v-model="ruleForm.material" autocomplete="off" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="订单数量" label-width="100px" prop="orderAmount">
          <el-input v-model="ruleForm.orderAmount" autocomplete="off" style="width: 50%" type="number"></el-input>
        </el-form-item>
        <el-form-item label="含税单价" label-width="100px" prop="unitPrice">
          <el-input v-model="ruleForm.unitPrice" autocomplete="off" style="width: 50%" type="number"></el-input>
        </el-form-item>
        <el-form-item label="已过帐数量" label-width="100px" prop="postedQuantity">
          <el-input v-model="ruleForm.postedQuantity" autocomplete="off" style="width: 50%" type="number"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <!--          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import {mixins} from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderImport",
  components: {},
  mixins: [mixins],
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      ruleForm: {
        id: "",//id
        customerCode: "",//客户代码
        voucherDate: "",//凭证日期
        sdVoucher: "",//SD凭证
        customerMaterial: "",//客户物料
        material: "",//物料
        orderAmount: "",//订单数量
        unitPrice: "",//含税单价
        postedQuantity: "",//已过帐数量
        salesEmployee: "",//销售雇员
        updateBy: "",//更新者
      },
      rules: {
        deliveryYears: [
          {required: true, message: '请选择交货年月', trigger: 'blur'}
        ],
        deliveryTime: [
          {required: true, message: '请选择交货日期', trigger: 'blur'}
        ],
        actualDeliveryTime: [
          {required: true, message: '请选择实际发货日', trigger: 'blur'}
        ],
        orderFirstDeliveryTime: [
          {required: true, message: '请选择首个交货日', trigger: 'blur'}
        ],
        customerCode: [
          {required: true, message: '请输入客户代码', trigger: 'blur'}
        ],
        material: [
          {required: true, message: '请输入物料', trigger: 'blur'}
        ],
        customerMaterial: [
          {required: true, message: '请输入客户物料', trigger: 'blur'}
        ],
        amount: [
          {required: true, message: '请输入数量', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        month: "",
        customerAbbreviationList: "",
        industryList: "",
        productCategoryList: "",
        mySalesEmployeeList: "",
        customerRatingList: "",
        deptIdList: "",
        partitionIdList: [],
        groupIdList: [],
      },
      IndustryList: [],
      product_categoryList: [],
      selectList: [],
      customerRatingDropDownBoxList: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      partitionDropDownBoxList: [],
      deliveryDataIndustryDropboxList: [],
      deliveryDataProductCategoryDropboxList: [],
      deliveryDataCustomerDropboxList: [],
      orderDataProductCategoryDropboxList: [],
      orderDataIndustryDropboxList: [],
      orderDataCustomerDropboxList: [],
      ratingFunctionList: [],
      groupDropDownList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
      queryTheLatestMonthList: 0,
    }
  },
  mounted() {
    // "customerCode": 客户代码,
    // "voucherDate": 凭证日期,
    // "sdVoucher": SD凭证,
    // "customerMaterial": 客户物料,
    // "material": 物料,
    // "orderAmount": 订单数量,
    // "unitPrice": 含税单价,
    // "postedQuantity": 已过帐数量,
    // "salesEmployee": 销售雇员,
    // "createBy": 创建者,
    // "createTime": 创建时间,
    // "updateBy": 更新者,
    // "updateTime": 更新时间


    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();

    // this.IndustryFunction();
    // this.product_categoryFunction();
    // this.customerRatingDropDownBox();
    // this.salesEmployeeDropDownBox();
    this.deptDropDownBox();
    this.deliveryDataIndustryDropbox();
    this.deliveryDataProductCategoryDropbox();
    this.deliveryDataCustomerDropbox();
    this.orderDataIndustryDropbox();
    this.orderDataCustomerDropbox();
    this.orderDataProductCategoryDropbox();
    this.ratingFunction();
    this.queryTheLatestMonth();

    // this.partitionDropDownBox();
  },
  methods: {
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/rateDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/rateDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = response.data.rateList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    queryTheLatestMonth() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/unshippedDetails/queryTheLatestMonth`)
          .then((response) => {
            console.log('unshippedDetails/queryTheLatestMonth', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.queryTheLatestMonthList = response.data.month;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    exportExcelSampleList() {
      let queryString = Object.keys(this.searchForm).map(key => key + '=' + this.searchForm[key]).join('&');
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      window.location.href = this.$urlHttp + `/unshippedDetails/exportExcel?${queryString}`
      loading.close();
    },
    exportExcelSampleList1() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      console.log(this.searchForm);
      data.append('month', this.searchForm.month);
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.length > 0 ? this.searchForm.customerAbbreviationList.toString() : "");
      data.append('industryList', this.searchForm.industryList.length > 0 ? this.searchForm.industryList.toString() : "");
      data.append('productCategoryList', this.searchForm.productCategoryList.length > 0 ? this.searchForm.productCategoryList.toString() : "");
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.length > 0 ? this.searchForm.mySalesEmployeeList.toString() : "");
      data.append('customerRatingList', this.searchForm.customerRatingList.length > 0 ? this.searchForm.customerRatingList.toString() : "");
      data.append('deptIdList', this.searchForm.deptIdList.length > 0 ? this.searchForm.deptIdList.toString() : "");
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      data.append('groupIdList', this.searchForm.groupIdList.toString());
      this.axios
          .post(this.$urlHttp + `/unshippedDetails/exportExcel`, data)
          .then((response) => {
            console.log('unshippedDetails/list', response.data);
            loading.close();
            this.$message(response.data.message);
            if (response.data.code === 200) {

            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.salesEmployeeDropDownBox();
    },
    groupDropDown() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptIdList}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptIdList.toString()}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, {
            deptId: this.searchForm.deptIdList.toString(),
            partitionId: this.searchForm.partitionIdList.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: "",//id
          customerCode: "",//客户代码
          voucherDate: "",//凭证日期
          sdVoucher: "",//SD凭证
          customerMaterial: "",//客户物料
          material: "",//物料
          orderAmount: "",//订单数量
          unitPrice: "",//含税单价
          postedQuantity: "",//已过帐数量
          salesEmployee: "",//销售雇员
          updateBy: "",//更新者
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.customerCode = data.customerCode;
        this.ruleForm.voucherDate = data.voucherTime;
        this.ruleForm.sdVoucher = data.sdVoucher;
        this.ruleForm.customerMaterial = data.customerMaterial;
        this.ruleForm.material = data.material;
        this.ruleForm.orderAmount = data.orderAmount;
        this.ruleForm.unitPrice = data.unitPrice;
        this.ruleForm.postedQuantity = data.postedQuantity;
        this.ruleForm.salesEmployee = data.salesEmployee;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      console.log(this.searchForm);
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', this.pageSize);
      data.append('month', this.searchForm.month);
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.length > 0 ? this.searchForm.customerAbbreviationList.toString() : "");
      data.append('industryList', this.searchForm.industryList.length > 0 ? this.searchForm.industryList.toString() : "");
      data.append('productCategoryList', this.searchForm.productCategoryList.length > 0 ? this.searchForm.productCategoryList.toString() : "");
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.length > 0 ? this.searchForm.mySalesEmployeeList.toString() : "");
      data.append('customerRatingList', this.searchForm.customerRatingList.length > 0 ? this.searchForm.customerRatingList.toString() : "");
      data.append('deptIdList', this.searchForm.deptIdList.length > 0 ? this.searchForm.deptIdList.toString() : "");
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      data.append('groupIdList', this.searchForm.groupIdList.toString());
      this.axios
          .post(this.$urlHttp + `/unshippedDetails/list`, data)
          .then((response) => {
            console.log('unshippedDetails/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviationList', this.ruleForm.customerAbbreviationList);
      data.append('customerRatingList', this.ruleForm.customerRatingList);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('customerCode', this.ruleForm.customerCode);
      data.append('voucherDate', this.ruleForm.voucherDate);
      data.append('sdVoucher', this.ruleForm.sdVoucher);
      data.append('customerMaterial', this.ruleForm.customerMaterial);
      data.append('material', this.ruleForm.material);
      data.append('orderAmount', this.ruleForm.orderAmount);
      data.append('unitPrice', this.ruleForm.unitPrice);
      data.append('postedQuantity', this.ruleForm.postedQuantity);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/unshippedDetails/edit`, data)
          .then((response) => {
            loading.close();
            console.log('unshippedDetails/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/unshippedDetails/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('unshippedDetails/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择未出货明细');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/unshippedDetails/delBatch?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('unshippedDetails/delBatch', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatchSearch() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/unshippedDetails/deleteSearchResults`, {
            month:this.searchForm.month,
            industryList: this.searchForm.industryList && this.searchForm.industryList.length > 0 ? this.searchForm.industryList.toString() : "",
            productCategoryList: this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "",
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            customerRatingList: this.searchForm.customerRatingList.toString(),
          })
          .then((response) => {
            loading.close();
            console.log('unshippedDetails/deleteSearchResults', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
