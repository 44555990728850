<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">角色管理</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div v-if="$buttonStatus('角色-添加')" @click="addOfEdit(true)" class="select_xin">新增</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchForm" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="18">
            <el-form-item prop="roleName" label="角色名称" label-width="80px">
              <el-input style="width: 40%" v-model="searchForm.roleName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="customers_group()" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table

              :data="tableData"
              style="width: 100%">
            <el-table-column
                prop="id"
                type="index"
                label="编号"
            >
            </el-table-column>
            <el-table-column
                prop="roleName"
                label="角色名称"
            >
            </el-table-column>
            <el-table-column
                prop="createBy"
                label="创建者"
            >
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
            >
            </el-table-column>
            <el-table-column
                prop="updateBy"
                label="更新者"
            >
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="更新时间"
            >
            </el-table-column>
            <el-table-column
                label="操作"
            >
              <template slot-scope="scope">
                <el-button
                    v-if="$buttonStatus('角色-编辑')"
                    size="mini"
                    type="warning"
                    @click="addOfEdit(false, scope.row)">编辑
                </el-button>
                <el-popover
                    v-if="$buttonStatus('角色-删除')"
                    style="margin-left: 10px"
                    placement="top"
                    width="160"
                    v-model="scope.row.tanshan">
                  <p>确定删除该角色吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>

              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="tanStatus?'添加角色':'修改角色'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="roleName" label="角色名称" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.roleName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="moduleIds" label="查看菜单" label-width="100px">
          <el-tree
              :data="getModelTreeList"
              node-key="id"
              ref="tree"
              :props="{children: 'children', label: 'modelName'}"
              show-checkbox
              @check="handleNodeClick"
              @check-change="handleCheckChange">
          </el-tree>
          <!--          <el-select filterable style="width: 50%" :disabled="ruleForm.moduleIds === 0" multiple v-model="ruleForm.moduleIds">-->
          <!--            <el-option-->
          <!--                v-for="(value,key) in getModelTreeList"-->
          <!--                :key="key"-->
          <!--                :label="value.modelName"-->
          <!--                :value="value.id">-->
          <!--            </el-option>-->
          <!--          </el-select>-->
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "RoleList",
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      searchForm: {
        roleName: "",
      },
      ruleForm: {
        id: 0,
        roleName: "",
        moduleIds: [],
        buttonIds: "",
        createBy: "",
      },
      rules: {
        roleName: [
          {required: true, message: '请输入角色名称', trigger: 'blur'}
        ],
        createBy: [
          {required: true, message: '请输入创建者', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      tableData: [],
      getModelTreeList: [],
      fuList: [],
    }
  },
  mounted() {
    this.customers_group();
    this.getModelTree();
  },
  methods: {
    handleNodeClick(value, data) {
      console.log(value, data);
      let arr = [];
      arr = arr.concat(data.checkedNodes,data.halfCheckedNodes);
      this.ruleForm.moduleIds = arr;
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    resArr(arr1, arr2) {
      return arr1.filter((v) => arr2.every((val) => val!== v));
    },
    addOfEdit(type, data) {
      if (this.$refs.tree){
        this.$refs.tree.setCheckedKeys([]);
      }
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,
          roleName: "",
          moduleIds: [],
          createBy: "",
        }
        console.log(111,this.ruleForm);
      } else {
        this.ruleForm.id = data.id;
        this.ruleForm.roleName = data.roleName;
        let arr = [];
        if (data.moduleIdList.length > 0) {
          data.moduleIdList.map((value) => {
            arr.push(Number(value));
          })
        }
        if (data.buttonList.length > 0) {
          data.buttonList.map((value) => {
            arr.push(Number(value));
          })
        }
      // :default-checked-keys="ruleForm.moduleIds"
      // :default-expanded-keys="ruleForm.moduleIds"
        setTimeout(()=>{
          this.$refs.tree.setCheckedKeys(this.resArr(arr,this.fuList));
        },300)
        this.ruleForm.moduleIds = arr;
        this.ruleForm.createBy = data.createBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', 20);
      data.append('roleName', this.searchForm.roleName);
      this.axios
          .post(this.$urlHttp + `/sysRole/list`, data)
          .then((response) => {
            console.log('sysRole/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    getModelTree() {
      this.axios
          .get(this.$urlHttp + `/sysModel/getModelTree`, {})
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDownBox', response.data);
            if (response.data.code === 200) {
              let date = response.data.data;
              let fuList = [];
              if (date.length > 0) {
                date.map((value) => {
                  value['children'] = value.chiLds;
                  if (value.chiLds && value.chiLds.length > 0) {
                    fuList.push(value.id);
                    value.chiLds.map((val) => {
                      val['children'] = val.buttonList;
                      if (val.buttonList && val.buttonList.length > 0) {
                        fuList.push(val.id)
                        val.buttonList.map((values, keys) => {
                          values['modelName'] = values.buttonName;
                        })
                      }
                    });
                  }
                })
              }
              this.fuList = fuList;
              this.getModelTreeList = date;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let moduleIds = [];
      let buttonIds = [];
      if (this.ruleForm.moduleIds.length > 0) {
        this.ruleForm.moduleIds.map((value, key) => {
          if (value['buttonName']){
            buttonIds.push(value.id);
          } else {
            moduleIds.push(value.id);
          }
        })
      }
      console.log('moduleIds',moduleIds,moduleIds.length);
      console.log('buttonIds',buttonIds,buttonIds.length);
      let user = JSON.parse(sessionStorage.getItem('user'));
      this.axios
          .post(this.$urlHttp + `/sysRole/add`, {
            roleName: this.ruleForm.roleName,
            moduleIds: moduleIds.toString(),
            buttonIds: buttonIds.toString(),
            createBy: user.userName,
          })
          .then((response) => {
            loading.close();
            console.log('sysRole/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let moduleIds = [];
      let buttonIds = [];
      if (this.ruleForm.moduleIds.length > 0) {
        this.ruleForm.moduleIds.map((value, key) => {
          if (value['buttonName']){
            buttonIds.push(value.id);
          } else {
            moduleIds.push(value.id);
          }
        })
      }
      let user = JSON.parse(sessionStorage.getItem('user'));
      this.axios
          .post(this.$urlHttp + `/sysRole/edit`, {
            id: this.ruleForm.id.toString(),
            roleName: this.ruleForm.roleName,
            moduleIds: moduleIds.toString(),
            buttonIds: buttonIds.toString(),
            updateBy: user.userName,
          })
          .then((response) => {
            loading.close();
            console.log('sysRole/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sysRole/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sysRole/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}



.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
