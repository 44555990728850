<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">目标销售额导入</span>
        </el-col>
        <el-col :span="12" style="display: flex;align-items:center;justify-content: flex-end">
          <!--          <div @click="addOfEdit(true)" class="select_xin">新增</div>-->
          <el-upload
              v-if="$buttonStatus('目标销售额-导入')"
              :action="`${$urlHttp}/targetSales/uploadFile`"
              :before-remove="beforeRemove"
              :data="{createBy:user.userName}"
              :on-change="handleUpdate"
              :on-exceed="handleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :show-file-list="false"
              class="upload-demo"
              multiple>
            <div class="select_xin">选择文件</div>
          </el-upload>
          <a :href="`${$urlHttp}/statics/excel/目标销售额导入模板.xlsx`" style="text-decoration: none">
            <div class="select_xia">下载数据模板</div>
          </a>
          <div class="select_sou" @click="exportExcelSampleList">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :model="searchForm" :rules="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item label="年度" label-width="100px" prop="annual">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.annual"
                  value-format="yyyy"
                  clearable
                  type="year"
                  placeholder="选择年">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="部门" label-width="100px" prop="deptIdList">
              <el-select v-model="searchForm.deptIdList" collapse-tags filterable multiple style="width: 99%"
                         @change="deptChange">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="销售雇员" label-width="100px" prop="mySalesEmployeeList">
              <el-select v-model="searchForm.mySalesEmployeeList" collapse-tags filterable multiple style="width: 99%">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="行业" label-width="100px" prop="industry">
              <el-select v-model="searchForm.industry" collapse-tags filterable multiple style="width: 99%">
                <el-option
                    v-for="(value,key) in deliveryDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分区" label-width="100px" prop="partitionIdList">
              <el-select v-model="searchForm.partitionIdList" collapse-tags filterable multiple style="width: 99%"
                         @change="partitionChange">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户" label-width="100px" prop="customerAbbreviationList">
              <el-select v-model="searchForm.customerAbbreviationList" collapse-tags filterable multiple
                         style="width: 99%">
                <el-option
                    v-for="(value,key) in deliveryDataCustomerDropboxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item label="产品大类" label-width="100px" prop="productCategory">
              <el-select v-model="searchForm.productCategory" collapse-tags filterable multiple style="width: 99%">
                <el-option
                    v-for="(value,key) in deliveryDataProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小组" label-width="100px" prop="groupIdList">
              <el-select v-model="searchForm.groupIdList" collapse-tags filterable multiple style="width: 99%"
                         @change="groupChange">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div class="select_sou" @click="customers_group()">搜 索</div>
                <div class="select_chong" @click="resetForm('searchForm')">重 置</div>
              </div>
            </el-form-item>
            <div style="display: flex">
              <el-popconfirm
                  v-if="$buttonStatus('目标销售额-删除')"
                  title="确认批量删除吗？"
                  @confirm="delBatch"
              >
                <div slot="reference" v-if="$buttonStatus('目标销售额-删除')" class="select_chong">批量删除</div>
              </el-popconfirm>
              <el-popconfirm
                  v-if="$buttonStatus('目标销售额-删除')"
                  title="确认删除搜索结果吗？"
                  @confirm="delBatchSearch"
              >
                <div slot="reference" v-if="$buttonStatus('目标销售额-删除')" class="select_chong">删除搜索结果</div>
              </el-popconfirm>
<!--              <div v-if="$buttonStatus('目标销售额-删除')" class="select_chong" @click="delBatch">批量删除</div>-->
<!--              <div v-if="$buttonStatus('目标销售额-删除')" class="select_chong" @click="delBatchSearch">删除搜索结果-->
<!--              </div>-->
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              :data="tableData"
              style="width: 100%"
              @selection-change="handleSelectionChange">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                label="年度"
                prop="annual"
            >
            </el-table-column>
            <el-table-column
                label="客户代码"
                prop="customerCode"
            >
            </el-table-column>
            <el-table-column
                label="产品大类"
                prop="productCategory"
            >
            </el-table-column>
            <el-table-column
                label="目标金额"
                prop="targetAmount"
            >
            </el-table-column>
            <el-table-column
                label="创建者"
                prop="createBy"
            >
            </el-table-column>
            <el-table-column
                label="创建时间"
                prop="createTime"
            >
            </el-table-column>
            <el-table-column
                label="更新者"
                prop="updateBy"
            >
            </el-table-column>
            <el-table-column
                label="更新时间"
                prop="updateTime"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
            >
              <template slot-scope="scope">
                <el-button
                    v-if="$buttonStatus('目标销售额-编辑')"
                    size="mini"
                    type="warning"
                    @click="addOfEdit(false, scope.row)">编辑
                </el-button>
                <el-popover
                    v-if="$buttonStatus('目标销售额-删除')"
                    v-model="scope.row.tanshan"
                    placement="top"
                    style="margin-left: 10px"
                    width="160">
                  <p>确定删除该目标销售额吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                    <el-button size="mini" type="primary" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            :current-page="currentPage4"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 100,1000]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="tanStatus?'添加目标销售额':'修改目标销售额'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item label="年度" label-width="100px" prop="annual">
          <el-date-picker
              style="width: 50%"
              v-model="ruleForm.annual"
              value-format="yyyy"
              clearable
              type="year"
              placeholder="选择年">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户代码" label-width="100px" prop="customerCode">
          <el-input v-model="ruleForm.customerCode" autocomplete="off" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="产品大类" label-width="100px" prop="productCategory">
          <el-select v-model="ruleForm.productCategory" collapse-tags filterable style="width: 50%">
            <el-option
                v-for="(value,key) in deliveryDataProductCategoryDropboxList"
                :key="key"
                :label="value"
                :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标金额" label-width="100px" prop="targetAmount">
          <el-input v-model="ruleForm.targetAmount" autocomplete="off" style="width: 50%" type="number"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <!--          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import {mixins} from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderImport",
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      ruleForm: {
        id: "",//id
        annual: "",//年度
        customerCode: "",//客户代码
        productCategory: "",//产品大类
        targetAmount: "",//目标金额
        updateBy: "",//更新者
      },
      rules: {
        annual: [
          {required: true, message: '请输入年度', trigger: 'blur'}
        ],
        customerCode: [
          {required: true, message: '请输入客户代码', trigger: 'blur'}
        ],
        productCategory: [
          {required: true, message: '请选择产品大类', trigger: 'blur'}
        ],
        targetAmount: [
          {required: true, message: '请输入目标金额', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        deliveryYearsInt: [],
        actualDeliveryDate: [],
        orderFirstDeliveryDate: [],
        customerAbbreviationList: [],
        deptIdList: "",
        mySalesEmployeeList: [],
        partitionIdList: "",
        annual: "",
        groupIdList: "",
        industry: "",
        productCategory: "",
      },
      IndustryList: [],
      product_categoryList: [],
      selectList: [],
      customerRatingDropDownBoxList: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      partitionDropDownBoxList: [],
      deliveryDataIndustryDropboxList: [],
      deliveryDataProductCategoryDropboxList: [],
      deliveryDataCustomerDropboxList: [],
      groupDropDownList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
    }
  },
  mixins: [mixins],
  mounted() {
    // "id": id,
    // "customerCode": 客户代码,
    // "productCategory": 产品大类,
    // "targetAmount": 目标金额,
    // "createBy": 创建者,
    // "createTime": 创建时间,
    // "updateBy": 更新者,
    // "updateTime": 更新时间


    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();

    // this.IndustryFunction();
    // this.product_categoryFunction();
    // this.customerRatingDropDownBox();
    // this.salesEmployeeDropDownBox();
    this.deptDropDownBox();
    this.deliveryDataIndustryDropbox();
    this.deliveryDataProductCategoryDropbox();
    this.deliveryDataCustomerDropbox();

    // this.partitionDropDownBox();
  },
  methods: {
    exportExcelSampleList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // data.append('industryList', this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "");
      // data.append('productCategoryList', this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "");
      // data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.toString());
      // data.append('deptIdList', this.searchForm.deptIdList.toString());
      // data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.toString());
      // data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      // data.append('groupIdList', this.searchForm.groupIdList.toString());
      window.location.href = this.$urlHttp + `/targetSales/exportExcel?industryList=${this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : ""}&productCategoryList=${this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : ""}&customerAbbreviationList=${this.searchForm.customerAbbreviationList.toString()}&deptIdList=${this.searchForm.deptIdList.toString()}&mySalesEmployeeList=${this.searchForm.mySalesEmployeeList.toString()}&partitionIdList=${this.searchForm.partitionIdList.toString()}&groupIdList=${this.searchForm.groupIdList.toString()}
      `
      loading.close();
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.salesEmployeeDropDownBox();
    },
    groupDropDown() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptIdList}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptIdList.toString()}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, {
            deptId: this.searchForm.deptIdList.toString(),
            partitionId: this.searchForm.partitionIdList.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: "",
          annual: "",
          customerCode: "",
          productCategory: "",
          targetAmount: "",
          updateBy: "",
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.customerCode = data.customerCode;
        this.ruleForm.productCategory = data.productCategory;
        this.ruleForm.annual = data.annual;
        this.ruleForm.targetAmount = data.targetAmount;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      console.log(this.searchForm);
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', this.pageSize);
      data.append('industryList', this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "");
      data.append('productCategoryList', this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "");
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.toString());
      data.append('deptIdList', this.searchForm.deptIdList.toString());
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.toString());
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      data.append('groupIdList', this.searchForm.groupIdList.toString());
      data.append('annual', this.searchForm.annual.toString());
      this.axios
          .post(this.$urlHttp + `/targetSales/list`, data)
          .then((response) => {
            console.log('targetSales/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('customerRating', this.ruleForm.customerRating);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('customerCode', this.ruleForm.customerCode);
      data.append('productCategory', this.ruleForm.productCategory);
      data.append('targetAmount', this.ruleForm.targetAmount);
      data.append('annual', this.ruleForm.annual);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/targetSales/edit`, data)
          .then((response) => {
            loading.close();
            console.log('targetSales/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/targetSales/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('targetSales/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择目标销售额');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/targetSales/delBatch?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('targetSales/delBatch', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatchSearch() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/targetSales/deleteSearchResults`, {
            industryList: this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "",
            productCategoryList: this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "",
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
          })
          .then((response) => {
            loading.close();
            console.log('targetSales/deleteSearchResults', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
