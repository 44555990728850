<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">首页中台</span>
        </el-col>
      </el-row>
      <el-form ref="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item label="下拉框" label-width="100px" prop="rules">
              <el-select v-model="rules"
                         :disabled="rules.length > 5"
                         :multiple-limit="5"
                         collapse-tags
                         filterable
                         multiple
                         style="width: 99%"
                         @change="addCommonFunctions">
                <el-option
                    v-for="(value,key) in sysDictDataDropDownBoxList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="`${value.dictValue}`">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="echarts">
        <div v-for="item in rules" :id="'myChart'+item" @click="urlClick(item)" style="width: 50%;height: 300px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "YinShunHome",
  mixins: [mixins],
  components: {},
  data() {
    return {
      sysDictDataDropDownBoxList: [],
      rules: [],
      queryTheLatestMonthList: 0,
      startTime:'',
      endTime:'',
    }
  },
  watch: {
    'rules'(newName, oldName) {
      if (newName.toString() !== oldName.toString()) {
        newName.map((value, key) => {
          this['Function' + value](value);
        })
      }
    }
  },
  mounted() {
    let timeDate = new Date();
    this.startTime = timeDate.getFullYear() + '/01';
    this.endTime = timeDate.getFullYear() + '/12';
    this.sysDictDataDropDownBox();
    this.detailsCommonFunctions();
    // this.queryTheLatestMonth();
  },
  methods: {
    urlClick(item){
      let urlList = {
        1 : 'OrderListOut',
        2 : 'OrderListHuo',
        3 : 'OrderListXiao',
        4 : 'SampleInputAndOutput',
        5 : 'SampleDeliveryRate',
        6 : 'SalesTarget',
        7 : 'SalesTarget',
        8 : 'SalesSource',
        9 : 'SalesSource',
        10 : 'UnshippedData',
      }
      this.$router.push({
        name: urlList[item],
        params: {},
      });
    },
    queryTheLatestMonth() {
      return new Promise(resolve => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.axios
            .get(this.$urlHttp + `/unshippedDetails/queryTheLatestMonth`)
            .then((response) => {
              console.log('/unshippedDetails/queryTheLatestMonth', response.data);
              loading.close();
              if (response.data.code === 200) {
                this.queryTheLatestMonthList = response.data.month;
              } else {
                this.$message(response.data.message);
              }

              resolve();
            })
            .catch((error) => console.log(error, "error"));
      })

    },
    timeGeShi(i) {
      console.log("获取近i月的时间段", i)
      var now = new Date()
      var year = now.getFullYear()
      var month = now.getMonth() + 1
      var day = now.getDate()
      var dateObj = {}
      dateObj.endTime = year + "-" + month + "-" + day
      var nowMonthDay = new Date(year, month, 0).getDate() //当前月的总天数
      if (i == 12) {
        //如果是12月，年数往前推一年<br>
        dateObj.startTime = year - 1 + "-" + month + "-" + day
      } else if (month - i <= 0) {
        // 如果前推i月小于0，年数往前推一年<br>
        dateObj.startTime = year - 1 + "-" + 12 + "-" + day
      } else {
        var endTimeMonthDay = new Date(year, parseInt(month) - i, 0).getDate()
        if (endTimeMonthDay < day) {
          // i个月前所在月的总天数小于现在的天日期
          if (day < nowMonthDay) {
            // 当前天日期小于当前月总天数
            dateObj.startTime = year + "-" + (month - i) + "-" + (endTimeMonthDay - (nowMonthDay - day))
          } else {
            dateObj.startTime = year + "-" + (month - i) + "-" + endTimeMonthDay
          }
        } else {
          dateObj.startTime = year + "-" + (month - i) + "-" + day
        }
      }
      return dateObj
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    sysDictDataDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=middleStageDisplay`)
          .then((response) => {
            console.log('sysDictType/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sysDictDataDropDownBoxList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    detailsCommonFunctions() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'))
      this.axios
          .get(this.$urlHttp + `/homepage/detailsCommonFunctions?userId=${user.id}`)
          .then((response) => {
            console.log('homepage/detailsCommonFunctions', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.rules = response.data.data.rules.length > 0 ? response.data.data.rules.split(',') : []
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    oneEcharts(list, name, time, flag, text) {
      // 基于准备好的dom，初始化echarts实例
      this['echats_date' + flag] = this.$echarts.init(document.getElementById('myChart' + flag))
      let option = {
        title: {
          text: text
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: time,
        },
        legend: {
          data: name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'value'
        },
        series: list,
      };

      // 使用刚指定的配置项和数据显示图表。
      this['echats_date' + flag].setOption(option);
    },
    sixEcharts(list, name, time, flag, text) {
      // 基于准备好的dom，初始化echarts实例
      this['echats_date' + flag] = this.$echarts.init(document.getElementById('myChart' + flag))
      let option = {
        title: {
          text: text
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: time,
        },
        legend: {
          data: name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}%'
          }
        },
        series: list,
      };

      // 使用刚指定的配置项和数据显示图表。
      this['echats_date' + flag].setOption(option);
    },
    addCommonFunctions(e) {
      console.log(111, e)
      if (this.rules.length > 5) {
        this.$message('最多展示5个');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('userId', user.id);
      data.append('rules', this.rules.toString());
      this.axios
          .post(this.$urlHttp + `/homepage/addCommonFunctions`, data)
          .then((response) => {
            console.log('homepage/addCommonFunctions', response.data);
            loading.close();
            if (response.data.code === 200) {

            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function1(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/order/summaryAnalysis`, {
            "calendarMonthIntStart": this.startTime, // "2022/01",
            "calendarMonthIntEnd": this.endTime, // "2022/06",
            "dictType": "1",
            "combination": "0",
            "level": "3",
            "customerAbbreviationList": "",
            "industryList": "",
            "mySalesEmployeeList": "",
            "deptIdList": "",
            "partitionIdList": "",
            "customerRating": "",
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/summaryAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult['按全部']['全部'];
              let arr = {};
              Object.keys(date).map((value, key) => {
                Object.keys(date[value]).map((val, ind) => {
                  if (arr[val]) {
                    arr[val].push(date[value][val])
                  } else {
                    arr[val] = [date[value][val]]
                  }
                })
              })
              let list = [];
              Object.keys(arr).map((value, key) => {
                list.push({
                  type: 'line',
                  name: value,
                  data: arr[value]
                })
              })
              let time = Object.keys(date);
              let name = Object.keys(arr);
              this.oneEcharts(list, name, time, value, '销售订单分布')
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function2(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/order/orderActivityAnalysis`, {
            "flag": "9", // 暂时先用9
            "calendarMonthIntStart":this.startTime, //this.startTime, //this.startTime, //this.startTime, //this.startTime, //this.startTime, //this.startTime, // "2022/01",
            "calendarMonthIntEnd":this.endTime, //this.endTime, //this.endTime, //this.endTime, //this.endTime, //this.endTime, //this.endTime, // "2022/03",
            "industryList": "",
            "mySalesEmployeeList": "",
            "deptIdList": "",
            "partitionIdList": "",
            "groupIdList": "",
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/orderActivityAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult['新增客户'];
              let list = {
                type: 'line',
                name: '新增客户',
                data: Object.values(date)
              };
              let time = Object.keys(date);
              let name = ['新增客户'];
              this.oneEcharts(list, name, time, value, '客户分析')
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function3(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/order/orderSalesGrowthIndex`, {
            "calendarMonthIntStart":this.$moment().subtract(12,'month').format('YYYY/MM'), // "2022/01",
            "calendarMonthIntEnd":this.$moment().subtract(1,'month').format('YYYY/MM'), // "2022/06",
            "flag": "1",
            "combination": "0",
            "industryS": "",
            "productCategoryS": "",
            "deptS": "",
            "partitionS": "",
            "groupS": "",
            "mySalesEmployeeS": "",
            "customerRatingS": "",
            "customerAbbreviationS": "",
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/orderSalesGrowthIndex', response.data);
            if (response.data.code === 200) {
              let date = response.data.mapResult['全部']['指数'];
              let nameDate = '全部-指数'
              let list = {
                type: 'line',
                name: '全部-指数',
                data: Object.values(date)
              };
              let time = Object.keys(date);
              let name = [nameDate];
              this.oneEcharts(list, name, time, value, '销售额指数')
            } else {
              this.$message(response.data.message);
            }
            loading.close();
          })
          .catch((error) => console.log(error, "error"));
    },
    Function4(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/input0utPutCurve`, {
            "calendarMonthStart":this.$moment().subtract(12,'month').format('YYYY/MM'), // "2022/01",
            "calendarMonthEnd":this.$moment().subtract(1,'month').format('YYYY/MM'), // "2022/06",
            "industryList": "",
            "productCategoryList": "",
            "deptIdList": "",
            "mySalesEmployeeList": "",
            "customerAbbreviationList": "",
            "customerRatingList": "",
            "materialList": "",
            "partitionIdList": "",
            "groupIdList": "",
            "flag": "5",
            "combination": "0",
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/input0utPutCurve', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult[Object.keys(response.data.mapResult)[0]];
              let list = [
                {
                  type: 'line',
                  name: '全部-产出',
                  data: Object.values(date['产出'])
                },
                {
                  type: 'line',
                  name: '全部-投入',
                  data: Object.values(date['投入'])
                },
              ];
              let time = Object.keys(date['产出']);
              let name = ['全部-产出', '全部-投入'];
              this.oneEcharts(list, name, time, value, '投入产出曲线图')
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function5(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let time = new Date();
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/cumulativeSampleCloseRate`, {
            "month": this.$moment().format('YYYY/MM'),//"2022/03",
            "type": "2",
            "flag": "",
            "industryS": "",
            "productCategoryS": "",
            "deptIdS": "",
            "partitionIdS": "",
            "groupIdS": "",
            "mySalesEmployeeS": "",
            "customerAbbreviationS": "",
            "customerRating": "",
            "combination": "0",
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/cumulativeSampleCloseRate', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult['过去12个月'];
              let list = [
                {
                  type: 'line',
                  name: '过去12个月',
                  data: Object.values(date).map((value, key) => {
                    return value *= 100
                  })
                },
              ];
              let time = Object.keys(date).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
              let name = ['过去18个月'];
              this.sixEcharts(list, name, time, value, '累计送样成交率曲线图')
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function6(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/targetSales/goalAchievementRate`, {
            "calendarMonthStart":this.startTime, // "2022/01",
            "calendarMonthEnd":this.endTime, // "2022/02",
            "flag": "1",
            "combination": "0",
            "industryS": "",
            "productCategoryS": "",
            "deptIdS": "",
            "partitionS": "",
            "groupS": "",
            "mySalesEmployeeS": "",
            "customerRatingS": "",
            "customerAbbreviationS": "",
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('targetSales/goalAchievementRate', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult['全部'];
              let list = [
                {
                  type: 'line',
                  name: '目标达成率',
                  barWidth: '20%',
                  data: Object.values(date).map(res=>{
                    return (res * 100).toFixed(2)
                  })
                },
              ];
              let time = Object.keys(date);
              let name = ['全部'];
              this.sixEcharts(list, name, time, value, '目标达成率')
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function7(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/targetSales/growthContributionRate`, {
            "calendarMonthStart":this.startTime, // "2022/01",
            "calendarMonthEnd":this.endTime, // "2022/02",
            "flag": "1",
            "combination": "0",
            "industryS": "",
            "productCategoryS": "",
            "deptIdS": "",
            "partitionS": "",
            "groupS": "",
            "mySalesEmployeeS": "",
            "customerRatingS": "",
            "customerAbbreviationS": "",
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('targetSales/growthContributionRate', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult['全部'];
              let list = [
                {
                  type: 'line',
                  name: '增长贡献率',
                  barWidth: '20%',
                  data: Object.values(date).map(res=>{
                    return (res * 100).toFixed(2)
                  })
                },
              ];
              let time = Object.keys(date);
              let name = ['全部'];
              this.sixEcharts(list, name, time, value, '增长贡献率')
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function8(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/order/originalBusinessYearOnYear`, {
            "flag": "1",
            "combination": "0",
            "industryS": "",
            "productCategoryS": "",
            "deptIdS": "",
            "partitionIdS": "",
            "groupIdS": "",
            "mySalesEmployeeS": "",
            "customerRatingS": "",
            "customerAbbreviationS": "",
            // "calendarMonthStart": this.$moment().subtract(3,'month').format('YYYY/MM'),
            // "calendarMonthEnd": this.$moment().format('YYYY/MM'),
            "calendarMonthStart":this.startTime, // "2023/01",
            "calendarMonthEnd":this.endTime, // "2023/02",
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/originalBusinessYearOnYear', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult['全部'];
              let list = [
                {
                  type: 'line',
                  name: '原有业务同比',
                  barWidth: '20%',
                  data: Object.values(date).map(res=>{
                    return (res * 100).toFixed(2)
                  })
                },
              ];
              let time = Object.keys(date);
              let name = ['全部'];
              this.sixEcharts(list, name, time, value, '原有业务同比')
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function9(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/order/proportionOfNewBusiness`, {
            "flag": "1",
            "combination": "0",
            "industryS": "",
            "productCategoryS": "",
            "deptIdS": "",
            "partitionIdS": "",
            "groupIdS": "",
            "mySalesEmployeeS": "",
            "customerRatingS": "",
            "customerAbbreviationS": "",
            // "calendarMonthStart": this.$moment().subtract(3,'month').format('YYYY/MM'),
            // "calendarMonthEnd": this.$moment().format('YYYY/MM'),
            "calendarMonthStart":this.startTime, // "2023/01",
            "calendarMonthEnd":this.endTime, // "2023/02",,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/proportionOfNewBusiness', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult['全部'];
              let list = [
                {
                  type: 'line',
                  name: '新业务占比',
                  barWidth: '20%',
                  data: Object.values(date).map(res=>{
                    return (res * 100).toFixed(2)
                  })
                },
              ];
              let time = Object.keys(date);
              let name = ['全部'];
              this.sixEcharts(list, name, time, value, '新业务占比')
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    Function10(value) {
      this.queryTheLatestMonth()
          .then(res=>{
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.axios
                .post(this.$urlHttp + `/unshippedDetails/unshippedCoefficientDDO`, {
                  "startMonth":this.$moment().subtract(12,'month').format('YYYY-MM'), // "2022-01",
                  "endMonth":this.$moment().subtract(1,'month').format('YYYY-MM'), // "2022-06",
                  "industryS": "",
                  "productCategoryS": "",
                  "deptIdS": "",
                  "partitionIdS": "",
                  "groupIdS": "",
                  "mySalesEmployeeS": "",
                  "customerAbbreviationS": "",
                  "customerRatingS": "",
                  "flag": "1",
                  "combination": "0",
                  roleName:this.user_date.roleName,
                  deptId:this.user_date.deptId,
                  partitionId:this.user_date.partitionId,
                  groupId:this.user_date.groupId,
                  mySalesEmployee:this.user_date.saleEmployee,
                })
                .then((response) => {
                  console.log('/unshippedDetails/unshippedCoefficientDDO', response.data);
                  loading.close();
                  if (response.data.code === 200) {
                    let date = response.data.mapResult['全部'];
                    let list = [
                      {
                        type: 'line',
                        name: '未出货系数DDO',
                        barWidth: '20%',
                        data: Object.values(date),
                      },
                    ];
                    let time = Object.keys(date);
                    let name = ['全部'];
                    this.oneEcharts(list, name, time, value, '未出货系数DDO')
                  } else {
                    this.$message(response.data.message);
                  }
                })
                .catch((error) => console.log(error, "error"));
          })

    },
  },
}
</script>

<style scoped>
.echarts {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
