<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">用户管理</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div v-if="$buttonStatus('用户-添加')" @click="addOfEdit(true)" class="select_xin">新增</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchForm" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="userName" label="用户名" label-width="80px">
              <el-input style="width: 90%" v-model="searchForm.userName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="saleName" label="销售雇员" label-width="80px">
              <el-input style="width: 90%" v-model="searchForm.saleName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="roleId" label="角色" label-width="80px">
              <el-select filterable v-model="searchForm.roleId">
                <el-option
                    v-for="(value,key) in roleDropDownBoxList"
                    :key="key"
                    :label="value.roleName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="customers_group()" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table

              :data="tableData"
              style="width: 100%">
            <el-table-column
                prop="id"
                type="index"
                label="编号"
            >
            </el-table-column>
            <el-table-column
                prop="userName"
                label="用户名"
            >
            </el-table-column>
            <el-table-column
                prop="saleName"
                label="销售雇员"
            >
            </el-table-column>
            <el-table-column
                prop="roleName"
                label="角色"
            >
            </el-table-column>
            <el-table-column
                prop="createBy"
                label="创建者"
            >
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
            >
            </el-table-column>
            <el-table-column
                prop="updateBy"
                label="更新者"
            >
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="更新时间"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="260"
            >
              <template slot-scope="scope">
                <el-button
                    v-if="$buttonStatus('用户-编辑')"
                    size="mini"
                    type="warning"
                    @click="addOfEdit(false, scope.row)">编辑
                </el-button>
                <el-popover
                    v-if="$buttonStatus('用户-删除')"
                    style="margin-left: 10px"
                    placement="top"
                    width="160"
                    v-model="scope.row.tanshan">
                  <p>确定删除该用户吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>
                <el-popover
                    v-if="$buttonStatus('用户-重置密码')"
                    style="margin-left: 10px"
                    placement="top"
                    width="160"
                    v-model="scope.row.tanshan1">
                  <p>确定重置该用户密码吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan1 = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="resetPwd(scope.row.id,scope.row.tanshan1)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">重置密码
                  </el-button>
                </el-popover>

              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="tanStatus?'添加用户':'修改用户'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="userName" label="用户名" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.userName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :prop="tanStatus ? 'pwd' : ''" label="密码" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.pwd" :disabled="!tanStatus" type="password"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="saleId" label="销售雇员" label-width="100px">
          <el-select filterable style="width: 50%" v-model="ruleForm.saleId">
            <el-option
                v-for="(value,key) in salesEmployeeDropDownBoxList"
                :key="key"
                :label="value.saleEmployee"
                :value="value.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="roleId" label="角色" label-width="100px">
          <el-select filterable style="width: 50%" v-model="ruleForm.roleId">
            <el-option
                v-for="(value,key) in roleDropDownBoxList"
                :key="key"
                :label="value.roleName"
                :value="value.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item prop="createBy" label="创建者" label-width="100px">-->
        <!--          <el-input style="width: 50%" v-model="ruleForm.createBy" autocomplete="off"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList,buttonStatus} from "@/components/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "UserList",
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      ruleForm: {
        id: 0,
        userName: "",
        pwd: "",
        saleId: "",
        roleId: "",
        createBy: "",
      },
      rules: {
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        pwd: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        roleId: [
          {required: true, message: '请选择角色', trigger: 'blur'}
        ],
        createBy: [
          {required: true, message: '请输入创建者', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      tableData: [],
      searchForm: {
        userName: '',
        saleName: '',
        roleId: '',
      },
      salesEmployeeDropDownBoxList: [],
      roleDropDownBoxList: [],
    }
  },
  mounted() {
    this.customers_group();
    this.salesEmployeeDropDownBox();
    this.roleDropDownBox();
  },
  methods: {
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,
          userName: "",
          pwd: "",
          saleId: "",
          roleId: "",
          createBy: "",
        }
      } else {
        this.ruleForm.id = data.id;
        this.ruleForm.userName = data.userName;
        this.ruleForm.pwd = data.pwd;
        this.ruleForm.saleId = data.saleId || '';
        this.ruleForm.roleId = data.roleId;
        this.ruleForm.createBy = data.createBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', 20);
      data.append('userName', this.searchForm.userName);
      data.append('saleName', this.searchForm.saleName);
      data.append('roleId', this.searchForm.roleId);
      this.axios
          .post(this.$urlHttp + `/sysUser/list`, data)
          .then((response) => {
            console.log('sysUser/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDownBox`, {
            deptIdList: '',
            partitionIdList: '',
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDownBox', response.data);
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    roleDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/roleDropDownBox`, {})
          .then((response) => {
            console.log('dropDownBox/roleDropDownBox', response.data);
            if (response.data.code === 200) {
              this.roleDropDownBoxList = response.data.roleList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('userName', this.ruleForm.userName);
      data.append('pwd', this.ruleForm.pwd);
      data.append('saleId', this.ruleForm.saleId);
      data.append('roleId', this.ruleForm.roleId);
      data.append('createBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysUser/add`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('userName', this.ruleForm.userName);
      data.append('pwd', '');
      data.append('saleId', this.ruleForm.saleId);
      data.append('roleId', this.ruleForm.roleId);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysUser/edit`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sysUser/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sysUser/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}



.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
