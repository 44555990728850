<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">菜单管理</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div v-if="$buttonStatus('菜单-添加')" @click="addOfEdit(true)" class="select_xin">新增</div>
        </el-col>
      </el-row>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table

              :data="tableData"
              row-key="id"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
              style="width: 100%">
            <el-table-column
                prop="id"
                type="index"
                label="编号"
            >
            </el-table-column>
            <el-table-column
                prop="modelName"
                label="菜单名称"
            >
            </el-table-column>
            <el-table-column
                min-width="120"
                prop="parentName"
                label="上级菜单名称"
            >
            </el-table-column>
            <el-table-column
                prop="buttonName"
                label="按钮名称"
            >
            </el-table-column>
            <el-table-column
                prop="path"
                label="路径"
            >
            </el-table-column>
            <el-table-column
                prop="sorting"
                label="排序"
            >
            </el-table-column>
            <el-table-column
                prop="createBy"
                label="创建者"
            >
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
            >
            </el-table-column>
            <el-table-column
                prop="updateBy"
                label="更新者"
            >
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="更新时间"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="240"
            >
              <template slot-scope="scope">
                <el-button
                    v-if="scope.row.buttonName && $buttonStatus('菜单-编辑')"
                    size="mini"
                    type="warning"
                    @click="childEdit(false, scope.row)">编辑按钮
                </el-button>
                <el-popover
                    v-if="scope.row.buttonName && $buttonStatus('菜单-删除')"
                    style="margin-left: 10px"
                    placement="top"
                    width="160"
                    v-model="scope.row.tanshan1">
                  <p>确定删除该按钮吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan1 = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="childDel(scope.row.id)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>
                <el-button
                    v-if="scope.row.parentId > 0 && $buttonStatus('菜单-添加')"
                    size="mini"
                    type="warning"
                    @click="childEdit(true, scope.row)">添加按钮
                </el-button>
                <el-button
                    v-if="scope.row.parentId > 0 && $buttonStatus('菜单-编辑')"
                    size="mini"
                    type="warning"
                    @click="addOfEdit(false, scope.row)">编辑
                </el-button>
                <el-popover
                    v-if="scope.row.parentId > 0 && $buttonStatus('菜单-删除')"
                    style="margin-left: 10px"
                    placement="top"
                    width="160"
                    v-model="scope.row.tanshan">
                  <p>确定删除该菜单吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>

              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog :title="tanStatus?'添加菜单':'修改菜单'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="modelName" label="菜单名称" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.modelName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="parentId" label="上级菜单" label-width="100px">
          <el-select filterable style="width: 50%" :disabled="ruleForm.parentId === 0" v-model="ruleForm.parentId">
            <el-option
                v-for="(value,key) in getModelTreeList"
                :key="key"
                :label="value.modelName"
                :value="value.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="path" label="路径" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.path" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="sorting" label="排序" label-width="100px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.sorting" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="childrenStatus?'添加按钮':'修改按钮'" :visible.sync="childrenFormVisible">
      <el-form ref="childrenForm" :rules="childrens" :model="childrenForm">
        <el-form-item prop="buttonName" label="按钮名称" label-width="100px">
          <el-input style="width: 50%" v-model="childrenForm.buttonName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="childSubmit('childrenForm')">确认</el-button>
<!--          <el-button type="primary" @click="resetForm('childrenForm')">重置</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";

export default {
  name: "ClassList",
  components: {},
  data() {
    return {
      childrenFormVisible: false,
      dialogFormVisible: false,
      childrenStatus: true,
      tanStatus: true,
      ruleForm: {
        id: 0,
        modelName: "",
        parentId: "",
        path: "",
        sorting: "",
        createBy: "",
      },
      rules: {
        modelName: [
          {required: true, message: '请输入菜单名称', trigger: 'blur'}
        ],
        path: [
          {required: true, message: '请输入路径', trigger: 'blur'}
        ],
        sorting: [
          {required: true, message: '请输入排序', trigger: 'blur'}
        ],
        createBy: [
          {required: true, message: '请输入创建者', trigger: 'blur'}
        ],
      },
      childrenForm: {
        id: 0,
        modelId: 0,
        buttonName: "",
      },
      childrens: {
        buttonName: [
          {required: true, message: '请输入按钮名称', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      tableData: [],
      getModelTreeList: [],
    }
  },
  mounted() {
    this.customers_group();
    this.getModelTree();
  },
  methods: {
    childEdit(type, data) {
      this.childrenStatus = type;
      if (type) {
        this.childrenForm = {
          id: 0,
          modelId: data.id,
          buttonName: "",
        }
      } else {
        this.childrenForm.id = data.id;
        this.childrenForm.modelId = 0;
        this.childrenForm.buttonName = data.buttonName;
      }
      this.childrenFormVisible = true;
    },
    childAddFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('buttonName', this.childrenForm.buttonName);
      data.append('modelId', this.childrenForm.modelId);
      data.append('createBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysModelButton/add`, data)
          .then((response) => {
            loading.close();
            console.log('sysModelButton/add', response.data);
            if (response.data.code === 200) {
              this.childrenFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    childEditFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.childrenForm.id);
      data.append('buttonName', this.childrenForm.buttonName);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysModelButton/edit`, data)
          .then((response) => {
            loading.close();
            console.log('sysModelButton/edit', response.data);
            if (response.data.code === 200) {
              this.childrenFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    childDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sysModelButton/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sysModelButton/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    childSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.childrenStatus) {
            this.childAddFunction();
          } else {
            this.childEditFunction();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },


    getModelTree() {
      this.axios
          .get(this.$urlHttp + `/sysModel/getModelTree`, {})
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDownBox', response.data);
            if (response.data.code === 200) {
              this.getModelTreeList = response.data.data;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,
          modelName: "",
          parentId: "",
          path: "",
          sorting: "",
          createBy: "",
        }
      } else {
        this.ruleForm.id = data.id;
        this.ruleForm.modelName = data.modelName;
        this.ruleForm.parentId = data.parentId;
        this.ruleForm.path = data.path;
        this.ruleForm.sorting = data.sorting;
        this.ruleForm.createBy = data.createBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', 20);
      this.axios
          .post(this.$urlHttp + `/sysModel/list`, data)
          .then((response) => {
            console.log('sysModel/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.data;
              if (date.length > 0) {
                date.map((value) => {
                  value['children'] = value.chiLds;
                  if (value.chiLds && value.chiLds.length > 0) {
                    value.chiLds.map((val) => {
                      val['children'] = val.buttonList;
                    })
                  }
                })
              }
              console.log(111, date);
              this.tableData = date;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('modelName', this.ruleForm.modelName);
      data.append('parentId', this.ruleForm.parentId);
      data.append('path', this.ruleForm.path);
      data.append('sorting', this.ruleForm.sorting);
      data.append('createBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysModel/add`, data)
          .then((response) => {
            loading.close();
            console.log('sysModel/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('modelName', this.ruleForm.modelName);
      data.append('parentId', this.ruleForm.parentId);
      data.append('path', this.ruleForm.path);
      data.append('sorting', this.ruleForm.sorting);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sysModel/edit`, data)
          .then((response) => {
            loading.close();
            console.log('sysModel/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sysModel/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sysModel/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs.ruleForm.resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}



.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
