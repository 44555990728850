import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import * as echarts from 'echarts'
import * as moment from 'moment'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.prototype.$echarts = echarts
Vue.prototype.$XLSX = XLSX
Vue.prototype.$FileSaver = FileSaver
Vue.prototype.$moment = moment
Vue.prototype.$buttonStatus = (name) => {
    return JSON.parse(sessionStorage.getItem('buttonList'))[name]
}
// Vue.prototype.$urlHttp = 'http://116.6.226.236:8182';
Vue.prototype.$urlHttp = 'https://port.issealing.com';
// Vue.prototype.$urlHttp = '';

Vue.use(ElementUI);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false
Vue.use(BaiduMap, {
    ak: 'BZOZxRdaO0kpeqYdwvNd4Fy9adZgv3s9'
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
