<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">交货数据分析</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="deliveryYearsInt" label="统计时间" label-width="120px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.deliveryYearsInt"
                  value-format="yyyy-MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="industryS" label="行业" label-width="120px">
              <el-select filterable style="width: 99%" collapse-tags multiple
                         :disabled="searchForm.classList !== 2 && searchForm.classList !== 7 && searchForm.classList !== 8"
                         v-model="searchForm.industryS">
                <el-option
                    v-for="(value,key) in deliveryDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="deptIdS" label="部门" label-width="120px">
              <el-select @change="deptChange" filterable multiple collapse-tags :disabled="searchForm.classZu !== '1' && searchForm.classList !== 11" style="width: 99%"
                         v-model="searchForm.deptIdS">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployeeS" label="销售雇员" label-width="120px">
              <el-select filterable multiple collapse-tags style="width: 99%" :disabled="searchForm.classZu !== '1' && searchForm.classList !== 14"
                         v-model="searchForm.mySalesEmployeeS">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item prop="flag" label="统计类型" label-width="120px">
              <el-select filterable style="width: 99%"
                         v-model="searchForm.flag">
                <el-option
                    v-for="(value,key) in deliveryDataAnalysisTypeList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="productCategoryS" label="产品大类" label-width="120px">
              <el-select filterable style="width: 99%" collapse-tags multiple
                         :disabled="searchForm.classList !== 3 && searchForm.classList !== 7 && searchForm.classList !== 8"
                         v-model="searchForm.productCategoryS">
                <el-option
                    v-for="(value,key) in deliveryDataProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdS" label="分区" label-width="120px">
              <el-select :disabled="searchForm.classZu !== '1' && searchForm.classList !== 12" multiple @change="partitionChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.partitionIdS">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerRating" label="评级" label-width="120px">
              <el-select :disabled="searchForm.classList !== 10" filterable multiple collapse-tags style="width: 99%"
                         v-model="searchForm.customerRating">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictLabel">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item prop="classList" label="统计方式" label-width="120px">
              <el-select @change="selectChange" filterable style="width: 99%"
                         v-model="searchForm.classList">
                <el-option
                    v-for="(value,key) in deliveryDataAnalysisModeList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerAbbreviationS" label="客户" label-width="120px">
              <el-select filterable multiple collapse-tags :disabled="searchForm.classList !== 4" style="width: 99%"
                         v-model="searchForm.customerAbbreviationS">
                <el-option
                    v-for="(value,key) in deliveryDataCustomerDropboxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="groupIdList" label="小组" label-width="120px">
              <el-select @change="groupChange" :disabled="searchForm.classZu !== '1' && searchForm.classList !== 13" multiple filterable collapse-tags style="width: 99%"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="classZu" label="是否组合查询" label-width="120px">
              <el-select filterable collapse-tags style="width: 99%"
                         @change="classZuChange"
                         :disabled="(searchForm.classList !== 2 && searchForm.classList !== 3 && searchForm.classList !== 7 && searchForm.classList !== 8) || user_identity"
                         v-model="searchForm.classZu">
                <el-option
                    label="是"
                    value="1">
                </el-option>
                <el-option
                    label="否"
                    value="0">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="addSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
              <div @click="addOfEdit('')" style="margin-top: 10px" class="select_sou">曲线图</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="name"
                :label="tableName[searchForm.classList]"
            >
            </el-table-column>
            <el-table-column
                prop="names"
                :label="searchForm.classList === 7 || searchForm.classList === 8 || searchForm.classZu == 1 ? '分类' : ''"
            >
            </el-table-column>
            <el-table-column
                v-for="(item,key) in tableDataTime"
                :key="key"
                :prop="item"
                :label="item"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
                v-if="tableCao"
            >
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="warning"
                    @click="addOfEdit(scope.row)">
                  曲线图
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="曲线图" :visible.sync="dialogFormVisible">
      <div id="myChart" style="width: 100%;height: 500px"></div>
    </el-dialog>
    <el-dialog title="搜索" :visible.sync="searchVisible">
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">


        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('searchForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderOut",
  mixins: [mixins],
  components: {},
  data() {
    return {
      searchVisible: false,
      dialogFormVisible: false,
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      tableDataTime: [],
      tableName: {
        1: '订单总数',
        2: '按行业',
        3: '按产品大类',
        4: '按客户',
        7: '按行业/产品大类',
        8: '按产品大类/行业',
        10: '按评级',
      },
      searchForm: {
        deliveryYearsInt: [],
        classZu: "0",
        classList: "",
        deliveryYearsIntStart: "2022-01",
        deliveryYearsIntEnd: "2023-01",
        flag: "",
        orderTotal: "",
        dictType: "industry",
        industryS: [],
        customerRating: [],
        productCategoryS: [],
        customerAbbreviationS: [],
        deptIdS: [],
        groupIdList: [],
        mySalesEmployeeS: [],
        partitionIdS: [],
      },
      searchs: {
        deliveryYearsInt: [
          {required: true, message: '请选择统计时间', trigger: 'blur'}
        ],
        flag: [
          {required: true, message: '请选择统计类型', trigger: 'blur'}
        ],
        classList: [
          {required: true, message: '请选择统计方式', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      selectList: [],
      user: {},
      echartsList: [],
      echartsName: [],
      deliveryDataAnalysisModeList: [],
      deliveryDataAnalysisTypeList: [],
      customerRatingDropDownBoxList: [],
      groupDropDownList: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      partitionDropDownBoxList: [],
      deliveryDataSalesEmployeeDropboxList: [],
      deliveryDataIndustryDropboxList: [],
      deliveryDataProductCategoryDropboxList: [],
      ratingFunctionList: [],
      deliveryDataCustomerDropboxList: [],
      echats_date: '',
      tableCao: true,
      roleMap:{},
      salesEmployee:{},
    }
  },
  mounted() {
// “id”: id,
// “deliveryYears”: 交货年月,
// “industry”: 行业,
// “productCategory”: 产品大类,
// “deliveryTime”: 交货日期,
// “actualDeliveryTime”: 实际发货日,
// “orderFirstDeliveryTime”: 订单首个交货日,
// “leaveManifestPromptness”: 开出货单及时率,
// “storehouseDeliveryPromptness”: 仓库发货及时率,
// “createBy”: 创建者,
// “createTime”: 创建时间,
// “updateBy”: 更新者,
// “updateTime”: 更新时间
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    // this.IndustryFunction();
    // this.product_categoryFunction();

    this.deliveryDataAnalysisMode();
    this.deliveryDataAnalysisType();
    this.salesEmployeeDropDownBox();
    this.deptDropDownBox();
    this.customerRatingDropDownBox();
    // this.partitionDropDownBox();
    // this.deliveryDataSalesEmployeeDropbox();
    this.deliveryDataIndustryDropbox();
    this.deliveryDataProductCategoryDropbox();
    this.deliveryDataCustomerDropbox();
    this.ratingFunction();
  },
  methods: {
    classZuChange(e) {
      console.log(111, e);
      if (e === '0') {
        this.$set(this.searchForm, 'deptIdS', []);
        this.$set(this.searchForm, 'partitionIdS', []);
        this.$set(this.searchForm, 'groupIdList', []);
        this.$set(this.searchForm, 'mySalesEmployeeS', []);
      }
    },
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=rating`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=rating', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    echartsTitle() {
      let name = '曲线图';
      if (this.searchForm.flag) {
        let date = this.deliveryDataAnalysisTypeList.find((value) => {
          return value.dictValue === this.searchForm.flag
        })
        if (date) {
          name = date.dictLabel + name
        }
      }
      return name
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

      if (this.searchForm.classZu === '1') {
        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
      }

    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

      if (this.searchForm.classZu === '1') {
        this.groupDropDown();
        this.salesEmployeeDropDownBox();
      }
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

      if (this.searchForm.classZu === '1') {
        this.salesEmployeeDropDownBox();
      }
    },
    groupDropDown() {
      let date = `roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptIdS.toString()}`
      if (this.searchForm.classList === 13) {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&groupId=${this.user_date.groupId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      let date = `roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptIdS.toString()}`;
      console.log('this.searchForm.flag',this.searchForm.flag);
      if (this.searchForm.classList === 12) {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/pListDropDownBox', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataSalesEmployeeDropbox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deliveryDataSalesEmployeeDropbox`, {})
          .then((response) => {
            console.log('dropDownBox/deliveryDataSalesEmployeeDropbox', response.data);
            if (response.data.code === 200) {
              this.deliveryDataSalesEmployeeDropboxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    selectChange(e) {
      console.log(e);
      this.searchForm.classZu = "0";
      this.searchForm.groupIdList = "";
      this.searchForm.partitionIdS = "";
      this.searchForm.deptIdS = "";
      this.searchForm.customerAbbreviationS = "";
      this.searchForm.mySalesEmployeeS = "";
      this.searchForm.industryS = "";
      this.searchForm.productCategoryS = "";
      this.searchForm.customerRating = "";

      if ( e=== 14) {
        this.salesEmployeeDropDownBox();
      } else if (e === 12) {
        this.partitionDropDownBox();
      } else if (e === 13) {
        this.groupDropDown();
      }
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = {
        deptId: this.searchForm.deptIdS.toString(),
        partitionId: this.searchForm.partitionIdS.toString(),
        groupId: this.searchForm.groupIdList.toString(),
        roleName: this.roleMap.roleName,
        id: JSON.parse(sessionStorage.getItem('user')).id,
      }

      if (this.searchForm.classList === 14) {
        date = {
          deptId: this.user_date.deptId,
          partitionId: this.user_date.partitionId,
          groupId: this.user_date.groupId,
          roleName: this.user_date.roleName,
          id: JSON.parse(sessionStorage.getItem('user')).id,
        }
      }
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, date)
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "交货数据分析导出.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    radioChange(e) {
      console.log(111, e);
      this.searchForm.industryS = "";
      this.searchForm.productCategoryS = "";
    },
    drawLine(list, name) {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'))
      let option = {
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: this.tableDataTime
        },
        legend: {
          data: name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'value'
        },
        series: list,
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    handleSelectionChange(val) {
      console.log(111, val);
      this.selectList = val;

    },
    addOfEdit(name) {
      let {classZu, deptIdS, partitionIdS, groupIdList, mySalesEmployeeS} = this.searchForm;
      if (classZu === '1' && !deptIdS.toString() && !partitionIdS.toString() && !groupIdList.toString() && !mySalesEmployeeS.toString()) {
        this.$message('部门、分区、小组、销售雇员至少选一个');
        return;
      }
      if (this.echats_date) {
        this.echats_date.clear();
      }
      this.dialogFormVisible = true;
      let list = [];
      let list_name = [];
      if (name) {
        if ((this.searchForm.classList === 7 || this.searchForm.classList === 8) && this.searchForm.classZu != 1) {
          console.log(666,this.echartsList,name)
          let arrData = this.echartsList.find((value) => {
            return value.name === name.name + '_' + name.names
          });
          list = [arrData];
          list_name = [name.name + '_' + name.names];
        } else {
          console.log(2,name)
          if (this.searchForm.classZu === '1'){
            let arrData = this.echartsList.find((value) => {
              console.log(2,value)
              return value.name === name.name + '-' + name.names
            });
            list = [arrData];
            list_name = [name.name];
          } else {
            let arrData = this.echartsList.find((value) => {
              console.log(2,value)
              return value.name === name.name
            });
            list = [arrData];
            list_name = [name.name];
          }

        }
      } else {
        console.log('this.selectList',this.selectList);
        if (this.selectList.length > 0) {
          this.selectList.map((values) => {
            let arrData = this.echartsList.find((value) => {

              if (this.searchForm.classList === 1) {
                return value.name === values.name
              } else if (this.searchForm.classList === 7 || this.searchForm.classList === 8) {
                return value.name === values.name + '_' + values.names
              } else {
                return value.name === values.name
              }
            });
            console.log(111, arrData);
            list.push(arrData);
            list_name.push(arrData.name);
          })
        } else {
          list = this.echartsList;
          list_name = this.echartsName;
        }
      }
      console.log(666,list,list_name)
      setTimeout(() => {
        this.drawLine(list, list_name);
      }, 300)
    },
    IfFunction(){
      let date = {
        2: 'industryS',
        4: 'customerAbbreviationS',
        9: 'partitionIdS',
        10: 'customerRating',
      }
      if (date[this.searchForm.classList] && this.searchForm[date[this.searchForm.classList]].length <= 0) {
        this.$message('所选统计方式不能为空');
        return true;
      } else {
        return false;
      }
    },
    digui2(arrs, num) {

      let arr = [];
      let arrTime = [];
      let arrObj = {};
      let arrValue = [{}];
      let name = '';
      let names = '';
      let echartList = [];
      let echartName = [];

      if (this.searchForm.classZu === '1'){
        arrValue = [];
        let list = (arrs, num, obj)=>{
          if (Object.keys(arrs).length > 0 && typeof arrs === 'object') {
            Object.keys(arrs).map((res,keys) => {
              console.log(666,res,num);
              if (num===0){
              } else if (num === 1){
                name = res;
              } else if (num === 2){
                names = res;
                arrTime = [];
                arrValue.push({
                  name:name,
                  names:res,
                })
              } else if (num === 3){
                arrTime.push(res);
                arrObj[res] = arrs[res];

                if (!arrValue[arrValue.length - 1]['arrObj']){
                  arrValue[arrValue.length - 1]['arrObj'] = {}
                }
                arrValue[arrValue.length - 1]['arrObj'][res] = arrs[res];
              }
              return list(arrs[res],num+1, obj)
            })
          }
        }
        list(arrs,num, {});

        arrValue.map(res=>{
          echartName.push(res.names + '-' + res.name);
          echartList.push({
            name:res.names + '-' + res.name,
            type:'line',
            data:Object.values(res.arrObj)
          })
          arr.push({
            name:res.names,
            names:res.name,
            level:res.names,
            ...res.arrObj
          })
        })
      } else {
        arrValue = [];
        let list = (arrs, num, obj)=>{
          if (Object.keys(arrs).length > 0 && typeof arrs === 'object') {
            arrTime = []

            Object.keys(arrs).map((res,keys) => {
              console.log(666,res,num);
              if (this.searchForm.classList == 7 || this.searchForm.classList == 8) {
                console.log('这里')
                if (num === 0){
                  // arrValue = Object.keys(arrs[res]);
                } else if (num===1){
                  name = res;
                } else if (num === 2){
                  names = res;
                  arrValue.push({
                    name,
                    names
                  })
                } else if (num === 3){
                  arrTime.push(res);

                  if (!arrValue[arrValue.length - 1]['arrObj']){
                    arrValue[arrValue.length - 1]['arrObj'] = {}
                  }
                  arrValue[arrValue.length - 1]['arrObj'][res] = arrs[res];
                  // arrObj[res] = arrs[res];
                }
              } else {
                console.log('这里1')
                if (num===0){
                  name = res;
                } else if (num === 1){
                  names = res;
                  arrValue.push({
                    name,
                    names
                  })
                } else if (num === 2){
                  arrTime.push(res);
                  if (!arrValue[arrValue.length - 1]['arrObj']){
                    arrValue[arrValue.length - 1]['arrObj'] = {}
                  }
                  arrValue[arrValue.length - 1]['arrObj'][res] = arrs[res];
                  // arrObj[res] = arrs[res];
                } else if (num === 3){
                  arrTime.push(res);
                }
              }

              return list(arrs[res],num+1, obj)
            })
          }
        }
        list(arrs,num, {});

        arrValue.map(res=>{
          echartName.push(this.searchForm.classList == 7 || this.searchForm.classList == 8 ? res.name + '_' + res.names :res.names);
          echartList.push({
            name:this.searchForm.classList == 7 || this.searchForm.classList == 8 ? res.name + '_' + res.names :res.names,
            type:'line',
            data:Object.values(res.arrObj)
          })
          arr.push({
            name:this.searchForm.classList == 7 || this.searchForm.classList == 8 ? res.name : res.names,
            names:this.searchForm.classList == 7 || this.searchForm.classList == 8 ? res.names : '',
            level:res.names,
            ...res.arrObj
          })
        })
      }




      console.log(555,arr,arrTime,arrObj,echartList);
      return {
        arr,
        arrTime,
        echartList,
        echartName
      };
    },
    customers_group() {
      if (this.IfFunction()) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let deliveryYearsInt = this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/orderShipment/promptnessStatisticalAnalysis`, {
            deliveryYearsIntStart: deliveryYearsInt[0],
            deliveryYearsIntEnd: deliveryYearsInt[1],
            flag: this.searchForm.flag.toString(),
            dictType: this.searchForm.classList.toString(),
            industryS: this.searchForm.industryS.toString(),
            productCategoryS: this.searchForm.productCategoryS.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviationS.toString(),
            deptIdS: this.searchForm.deptIdS.toString(),
            partitionIdS: this.searchForm.partitionIdS.toString(),
            groupIdS:this.searchForm.groupIdList.toString(),
            mySalesEmployeeS: this.searchForm.mySalesEmployeeS.toString(),
            customerRatingS: this.searchForm.customerRating.toString(),
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('orderShipment/promptnessStatisticalAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {
              const {
                arr,
                arrTime,
                echartList,
                echartName
              } = this.digui2(response.data.mapResult, 0);
              console.log(111, arrTime[arrTime.length - 1]);
              let a = arr.sort((a, b) => {
                return b[arrTime[arrTime.length - 1]] - a[arrTime[arrTime.length - 1]]
              });
              console.log(222, arr,
                  arrTime,
                  echartList,
                  echartName);
              this.tableData = a;
              this.tableDataTime = arrTime;
              this.echartsList = echartList;
              this.echartsName = echartName;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    digui(valcombinationue, key) {
      let arr = [];
      let arrTime = [];
      let echartList = [];
      let echartName = [];

      let a = (value, key, obj = {}) => {
        let Obj_Value = []
        if (typeof value === 'object') {
          Obj_Value = Object.keys(value);
        }
        if (Obj_Value.length > 0) {
          Obj_Value.map((value_v, value_k) => {
            if (this.searchForm.classList === 1) {
              if (key === 1) {
                obj['name'] = value_v;
              } else if (key === 2) {
                arrTime = Obj_Value;
                obj[value_v] = value[value_v];
                if (Obj_Value.length - 1 === value_k) {
                  arr.push(JSON.parse(JSON.stringify(obj)));
                  echartName.push(obj['name']);
                  echartList.push({
                    name: obj['name'],
                    type: 'line',
                    data: Object.values(value),
                  })
                }
              }
            } else if (this.searchForm.classList === 7 || this.searchForm.classList === 8) {
              if (key === 1) {
                console.log(111, value_v)
                obj['name'] = value_v;
              } else if (key === 2) {
                obj['names'] = value_v;
              } else if (key === 3) {
                arrTime = Obj_Value;
                obj[value_v] = value[value_v];
                if (Obj_Value.length - 1 === value_k) {
                  arr.push(JSON.parse(JSON.stringify(obj)));
                  echartName.push(obj['name'] + '_' + obj['names']);
                  echartList.push({
                    name: obj['name'] + '_' + obj['names'],
                    type: 'line',
                    data: Object.values(value),
                  })
                }
              }
            } else {
              if (key === 1) {
                console.log(111, value_v)
                obj['name'] = value_v;
              } else if (key === 2) {
                arrTime = Obj_Value;
                obj[value_v] = value[value_v];
                if (Obj_Value.length - 1 === value_k) {
                  arr.push(JSON.parse(JSON.stringify(obj)));
                  echartName.push(obj['name']);
                  echartList.push({
                    name: obj['name'],
                    type: 'line',
                    data: Object.values(value),
                  })
                }
              }
            }

            a(value[value_v], key + 1, JSON.parse(JSON.stringify(obj)));
          })
        }
      }

      a(value, key);
      console.log(arr);
      return {
        arr,
        arrTime,
        echartList,
        echartName
      };
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataAnalysisType() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=deliveryDataAnalysisType`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=deliveryDataAnalysisType', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataAnalysisTypeList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataAnalysisMode() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=deliveryDataAnalysisMode`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=deliveryDataAnalysisMode', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataAnalysisModeList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let {classZu, deptIdS, partitionIdS, groupIdList, mySalesEmployeeS} = this.searchForm;
          if (classZu === '1' && !deptIdS.toString() && !partitionIdS.toString() && !groupIdList.toString() && !mySalesEmployeeS.toString()) {
            this.$message('部门、分区、小组、销售雇员至少选一个');
            return;
          }
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
