<template>
 <div class="content">
   <div class="left">
   </div>
   <div class="left">
     <div class="left_div">
       <img style="width: 36px;height: 36px;" src="../assets/home26.png" alt="" srcset="">
       <span class="left_div_span">姓名:{{user.userName}}</span>
     </div>
     <div @click="dialogFormVisible = true" class="left_div">
       <img style="width: 36px;height: 36px;" src="../assets/home26.png" alt="" srcset="">
       <span class="left_div_span">修改密码</span>
     </div>
     <div @click="outLogin" class="left_div">
       <img style="width: 26px;height: 31px;" src="../assets/home9.png" alt="" srcset="">
       <span class="left_div_span">退出登录</span>
     </div>
   </div>
   <el-dialog title="修改密码" :visible.sync="dialogFormVisible">
     <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
       <el-form-item prop="newPwd" label="新密码" label-width="100px">
         <el-input style="width: 50%" v-model="ruleForm.newPwd" type="password" autocomplete="off"></el-input>
       </el-form-item>
       <el-form-item prop="quePwd" label="确认密码" label-width="100px">
         <el-input style="width: 50%" v-model="ruleForm.quePwd" type="password" autocomplete="off"></el-input>
       </el-form-item>
       <el-form-item style="margin-left:100px;">
         <el-button @click="addSubmit('ruleForm')">确认</el-button>
         <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
       </el-form-item>
     </el-form>
   </el-dialog>
 </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "RightHeader",
  data(){
    return {
      user:{},
      dialogFormVisible:false,
      ruleForm: {
        id: 0,
        newPwd: "",
        quePwd: "",
      },
      rules: {
        newPwd: [
          {required: true, message: '请输入新密码', trigger: 'blur'}
        ],
        quePwd: [
          {required: true, message: '请输入确认密码', trigger: 'blur'}
        ],
      },
    }
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  },
  methods:{
    updatePwd() {
      if (this.ruleForm.newPwd !== this.ruleForm.quePwd){
        this.$message('请确认密码一致');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));



      let data = new FormData();
      data.append('id', user.id);
      data.append('newPwd', this.ruleForm.newPwd);

      if (roleMap.roleName === '系统管理员') {
        this.axios
            .post(this.$urlHttp + `/sysUser/updatePwd`, data)
            .then((response) => {
              loading.close();
              console.log('/sysUser/updatePwd', response.data);
              this.$message(response.data.message);
              if (response.data.code === 200) {
                this.dialogFormVisible = false;
              }
            })
            .catch((error) => console.log(error, "error"));
      } else {
        this.axios
            .post(this.$urlHttp + `/salesEmployee/updatePwd`, data)
            .then((response) => {
              loading.close();
              console.log('salesEmployee/updatePwd', response.data);
              this.$message(response.data.message);
              if (response.data.code === 200) {
                this.dialogFormVisible = false;
              }
            })
            .catch((error) => console.log(error, "error"));
      }


    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updatePwd();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    urlClick(url){
      console.log(url)
      this.$router.push({
        name: url,
        params: {},
      });
    },
    outLogin(){
      sessionStorage.removeItem('user')
      this.$router.push({
        name: "Login",
        params: {},
      });
    }
  }
}
</script>

<style scoped>
  .left_span2 {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #1B7EBC;
    line-height: 16px;
    margin-right: 5px;
  }
  .left_span1 {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }
  .left_div .left_div_span {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-left: 17px;
  }
  .left_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 38px;
  }
  .content {
    width: 100%;
    height: 70px;
    background-color: rgba(242, 242, 242, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 33px;
    box-sizing: border-box;
  }
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
