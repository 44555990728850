<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">累计送样/送样成交率</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">

        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchRule" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="month" label="日期" label-width="120px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.month"
                  value-format="yyyy/MM"
                  type="month"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="industry" label="行业" label-width="120px">
              <el-select :disabled="searchForm.dictType !== '1' &&  searchForm.dictType !== '9' && searchForm.dictType !== '10'" filterable multiple collapse-tags style="width: 99%"
                         v-model="searchForm.industry">
                <el-option
                    v-for="(value,key) in deliveryDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="deptId" label="部门" label-width="120px">
              <el-select :disabled="searchForm.classZu !== '1' && searchForm.dictType !== '11'"
                         multiple
                         filterable collapse-tags style="width: 99%"
                         @change="deptChange"
                         v-model="searchForm.deptId">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployee" label="销售雇员" label-width="120px">
              <el-select :disabled="searchForm.classZu !== '1' && searchForm.dictType !== '14'" filterable multiple collapse-tags style="width: 99%"
                         v-model="searchForm.mySalesEmployee">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="type" label="统计类型" label-width="120px">
              <el-select style="width: 99%" clearable v-model="searchForm.type" placeholder="请选择">
                <el-option
                    label="过去6个月"
                    value="1">
                </el-option>
                <el-option
                    label="过去12个月"
                    value="2">
                </el-option>
                <el-option
                    label="过去18个月"
                    value="3">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="productCategory" label="产品大类" label-width="120px">
              <el-select multiple :disabled="searchForm.dictType !== '4' &&  searchForm.dictType !== '9' && searchForm.dictType !== '10'" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.productCategory">
                <el-option
                    v-for="(value,key) in deliveryDataProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionId" label="分区" label-width="120px">
              <el-select :disabled="searchForm.classZu !== '1' && searchForm.dictType !== '12'" filterable multiple collapse-tags style="width: 99%"
                         @change="partitionChange"
                         v-model="searchForm.partitionId">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="customerRating" label="评级" label-width="120px">
              <el-select :disabled="searchForm.dictType !== '2'" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.customerRating">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>


          </el-col>
          <el-col :span="6">
            <el-form-item prop="dictType" label="统计方式" label-width="120px">
              <el-select @change="dictTypeChange" style="width: 99%" v-model="searchForm.dictType" placeholder="请选择">
                <el-option
                    v-for="item in IndustryList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="`${item.dictValue}`">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerAbbreviation" label="客户" label-width="120px">
              <el-select :disabled="searchForm.dictType !== '7'" filterable multiple collapse-tags style="width: 99%"
                         v-model="searchForm.customerAbbreviation">
                <el-option
                    v-for="(value,key) in ssCrDropDownBoxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="120px">
              <el-select filterable multiple collapse-tags style="width: 99%"
                         @change="groupChange"
                         :disabled="searchForm.classZu !== '1' && searchForm.dictType !== '13'"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>


            <el-form-item prop="classZu" label="是否组合查询" label-width="120px">
              <el-select filterable collapse-tags style="width: 99%"
                         @change="classZuChange"
                         :disabled="(searchForm.dictType !== '1' && searchForm.dictType !== '4' && searchForm.dictType !== '9' && searchForm.dictType !== '10') || user_identity"
                         v-model="searchForm.classZu">
                <el-option
                    label="是"
                    value="1">
                </el-option>
                <el-option
                    label="否"
                    value="0">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="quXianClick()" style="width: 140px;" class="select_sou">送样成交率搜索</div>
                <div @click="quXianClick()" style="width: 140px;" class="select_sou">送样成交率曲线图</div>
                <!--                <div @click="searchSubmit('searchForm')" class="select_sou">搜 索</div>-->
<!--                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>-->
              </div>
              <div style="display: flex">
                <div @click="LeiJiQuXianClick()" style="width: 180px;margin-top: 10px" class="select_sou">
                  累计送样成交率搜索
                </div>
                <div @click="LeiJiQuXianClick()" style="width: 180px;margin-top: 10px" class="select_sou">
                  累计送样成交率曲线图
                </div>
              </div>
              <div style="display: flex;margin-top: 10px">
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>

            </el-form-item>


          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div id="myChart" style="width: 100%;height: 500px"></div>
      </div>
    </div>
    <!--    <el-dialog title="曲线图" :visible.sync="dialogFormVisibleAA">-->
    <!--      <div id="myChart" style="width: 100%;height: 500px"></div>-->
    <!--    </el-dialog>-->
    <el-dialog :title="tanStatus?'添加订单出货':'修改订单出货'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="deliveryYears" label="交货年月" label-width="120px">
          <el-date-picker
              style="width: 50%"
              v-model="ruleForm.deliveryYears"
              value-format="yyyy-MM"
              type="month">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="industry" label="行业" label-width="120px">
          <el-select filterable style="width: 50%" v-model="ruleForm.industry">
            <el-option
                v-for="(value,key) in IndustryList"
                :key="key"
                :label="value"
                :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="productCategory" label="产品大类" label-width="120px">
          <el-select filterable style="width: 50%" v-model="ruleForm.productCategory">
            <el-option
                v-for="(value,key) in product_categoryList"
                :key="key"
                :label="value"
                :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="deliveryDate" label="交货日期" label-width="120px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.deliveryDate"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="actualDeliveryDate" label="实际发货日" label-width="120px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.actualDeliveryDate"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="orderFirstDeliveryDate" label="订单首个交货日" label-width="120px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.orderFirstDeliveryDate"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderImport",
  mixins: [mixins],
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisibleAA: false,
      tanStatus: true,
      dictTypeList:[
        {
          dictLabel:'按全部',
          dictValue:'8',
        },
        {
          dictLabel:'按行业',
          dictValue:'1',
        },
        {
          dictLabel:'按评级',
          dictValue:'2',
        },
        {
          dictLabel:'按产品大类',
          dictValue:'4',
        },
        {
          dictLabel:'按客户',
          dictValue:'7',
        },
        {
          dictLabel:'按行业/产品大类',
          dictValue:'9',
        },
        {
          dictLabel:'按产品大类/行业',
          dictValue:'10',
        },
        {
          dictLabel:'按部门',
          dictValue:'11',
        },
        {
          dictLabel:'按分区',
          dictValue:'12',
        },
        {
          dictLabel:'按小组',
          dictValue:'13',
        },
        {
          dictLabel:'按销售雇员',
          dictValue:'14',
        },
      ],
      ruleForm: {
        id: '',
        deliveryYears: '',
        industry: '',
        productCategory: '',
        deliveryDate: '',
        actualDeliveryDate: '',
        orderFirstDeliveryDate: '',
        updateBy: '',
      },
      rules: {
        deliveryYears: [
          {required: true, message: '请选择交货年月', trigger: 'blur'}
        ],
        industry: [
          {required: true, message: '请选择行业', trigger: 'blur'}
        ],
        productCategory: [
          {required: true, message: '请选择产品大类', trigger: 'blur'}
        ],
        deliveryDate: [
          {required: true, message: '请选择交货日期', trigger: 'blur'}
        ],
        actualDeliveryDate: [
          {required: true, message: '请选择实际发货日', trigger: 'blur'}
        ],
        orderFirstDeliveryDate: [
          {required: true, message: '请选择订单首个交货日', trigger: 'blur'}
        ],
        updateBy: [
          {required: true, message: '请输入更新者', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        month: "",
        type: "",
        industry: "",
        productCategory: "",
        deptId: "",
        partitionId: "",
        groupIdList: "",
        mySalesEmployee: "",
        customerRating: "",
        customerAbbreviation: "",
        dictType: "",
        classZu: "",
      },
      searchRule: {
        month: [
          {required: true, message: '请选择日期', trigger: 'blur'}
        ],
        dictType: [
          {required: true, message: '请选择统计方式', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      groupDropDownList: [],
      product_categoryList: [],
      selectList: [],
      customerRatingDropDownBoxList: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      partitionDropDownBoxList: [],
      deliveryDataIndustryDropboxList: [],
      deliveryDataProductCategoryDropboxList: [],
      deliveryDataCustomerDropboxList: [],
      ssCrDropDownBoxList: [],
      top100CompaniesInSalesList: [],
      ratingFunctionList: [],
      tableTime: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
    }
  },
  created() {
    this.dictTypeList = this.listFormData__(this.dictTypeList)
  },
  mounted() {
// “customerAbbreviation”: 客户名称,
// “industry”: 行业,
// “productCategory”: 产品大类,
// “material”: 物料,
// “mySalesEmployee”: 销售雇员,
// “sampleTotal”: 样品总金额,
// “sumQuota”: 总计销售额

    // “sumSalesQuota”: {每年总销货额集合
// “2020”: 52000.0,
// “2021”: 0.0
//     },

    console.log(111,this.$router)
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    this.IndustryFunction();
    this.product_categoryFunction();
    this.customerRatingDropDownBox();
    this.salesEmployeeDropDownBox();
    this.deptDropDownBox();
    this.deliveryDataIndustryDropbox();
    this.deliveryDataProductCategoryDropbox();
    this.deliveryDataCustomerDropbox();
    // this.partitionDropDownBox();
    this.ssCrDropDownBox();

    this.top100CompaniesInSales();
    this.ratingFunction();
  },
  methods: {
    classZuChange(e) {
      console.log(111, e);
      if (e === '0') {
        this.$set(this.searchForm, 'deptId', []);
        this.$set(this.searchForm, 'partitionId', []);
        this.$set(this.searchForm, 'groupIdList', []);
        this.$set(this.searchForm, 'mySalesEmployee', []);
      }
    },
    groupDropDown() {
      let date = `roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionId.toString()}&deptId=${this.searchForm.deptId}`
      if (this.searchForm.dictType === '13') {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&groupId=${this.user_date.groupId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    drawLine(list, name, time, title = '') {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'));
      let option = {}
      if (this.searchForm.flag === 1) {
        option = {
          title: {
            text: title
          },
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: time
          },
          legend: {
            data: name
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: list,
        };
      } else {
        option = {
          title: {
            text: title
          },
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: time
          },
          legend: {
            data: name
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: list,
        };
      }

      console.log(999, option)

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    timeObject(obj) {
      let time = Object.keys(obj).sort();
      let arr = [];
      time.map((value, key) => {
        console.log(963,obj[value]);
        arr.push(Number(obj[value].toFixed(2)));
      })
      return arr;
    },
    proportionOfInputCategories() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = this.searchForm;

      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleCloseRate`, {
            month: date.month,
            industryS: date.industry.toString(),
            productCategoryS: date.productCategory.toString(),
            partitionIdS: date.partitionId.toString(),
            type: date.type,
            deptIdS: date.deptId.toString(),
            mySalesEmployeeS: date.mySalesEmployee.toString(),
            customerRating: date.customerRating,
            customerAbbreviationS: date.customerAbbreviation.toString(),
            groupIdS: date.groupIdList.toString(),
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
            flag:this.searchForm.dictType == 10 ? '7' : '',
          })
          .then((response) => {
            console.log('sampleDataAnalysis/sampleCloseRate', response.data.mapResult);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              let title = response.data.name;
              if (Object.keys(date).length > 0) {

                if (this.searchForm.type) {
                  arr.push({
                    name: Object.keys(date)[0],
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: [...this.timeObject(date[Object.keys(date)[0]])],
                  });
                  name.push(Object.keys(date)[0]);
                  time = Object.keys(date[Object.keys(date)[0]]).sort();
                } else {
                  arr.push({
                    name: '过去6个月',
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ...this.timeObject(date['过去6个月'])],
                  });

                  arr.push({
                    name: '过去12个月',
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: [0, 0, 0, 0, 0, 0, ...this.timeObject(date['过去12个月'])],
                  });

                  arr.push({
                    name: '过去18个月',
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: [...this.timeObject(date['过去18个月'])],
                  });

                  name.push('过去6个月');
                  name.push('过去12个月');
                  name.push('过去18个月');

                  time = Object.keys(date['过去18个月']).sort();
                }


              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisibleAA = true;
              setTimeout(() => {
                this.drawLine(arr, name, time, title);
              }, 300)
              console.log(arr, name, time, title);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    cumulativeSampleCloseRate() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = this.searchForm;

      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/cumulativeSampleCloseRate`, {
            month: date.month,
            industryS: date.industry.toString(),
            productCategoryS: date.productCategory.toString(),
            partitionIdS: date.partitionId.toString(),
            type: date.type,
            deptIdS: date.deptId.toString(),
            mySalesEmployeeS: date.mySalesEmployee.toString(),
            customerRating: date.customerRating,
            customerAbbreviationS: date.customerAbbreviation.toString(),
            groupIdS: date.groupIdList.toString(),
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
            flag:this.searchForm.dictType == 10 ? '7' : '',
          })
          .then((response) => {
            console.log('sampleDataAnalysis/cumulativeSampleCloseRate', response.data.mapResult);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              let title = response.data.name;

              if (Object.keys(date).length > 0) {

                if (this.searchForm.type) {
                  arr.push({
                    name: Object.keys(date)[0],
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: [...this.timeObject(date[Object.keys(date)[0]])],
                  });
                  name.push(Object.keys(date)[0]);
                  time = Object.keys(date[Object.keys(date)[0]]).sort();
                } else {
                  arr.push({
                    name: '过去6个月',
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ...this.timeObject(date['过去6个月'])],
                  });

                  arr.push({
                    name: '过去12个月',
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: [0, 0, 0, 0, 0, 0, ...this.timeObject(date['过去12个月'])],
                  });

                  arr.push({
                    name: '过去18个月',
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: [...this.timeObject(date['过去18个月'])],
                  });

                  name.push('过去6个月');
                  name.push('过去12个月');
                  name.push('过去18个月');

                  time = Object.keys(date['过去18个月']).sort();
                }


              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisibleAA = true;
              setTimeout(() => {
                this.drawLine(arr, name, time, title);
              }, 300)
              console.log(arr, name, time, title);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    quXianClick() {
      this.$refs['searchForm'].validate((valid) => {
        if (valid) {
          let {classZu, deptId, partitionId, groupIdList, mySalesEmployee} = this.searchForm;
          if (classZu === '1' && !deptId.toString() && !partitionId.toString() && !groupIdList.toString() && !mySalesEmployee.toString()) {
            this.$message('部门、分区、小组、销售雇员至少选一个');
            return;
          }
          console.log(this.searchForm.dictType, 111)
          if (this.searchForm.dictType && this.searchForm.dictType !== '6') {
            if (this.searchForm.dictType !== '8') {
              let date = {
                1: 'industry',
                2: 'customerRating',
                3: 'deptId',
                4: 'productCategory',
                5: 'mySalesEmployee',
                6: 'partition',
                7: 'customerAbbreviation',
                9: 'partitionId',
                11: 'deptId',
                12: 'partitionId',
                13: 'groupIdList',
                14: 'mySalesEmployee',
              }
              if (this.searchForm.dictType === '9' || this.searchForm.dictType === '10') {
                if (this.searchForm.industry.length <= 0 || !this.searchForm.productCategory) {
                  this.$message('所选统计方式数量不为1');
                  return;
                }
              } else if (this.searchForm.dictType === '1' || this.searchForm.dictType === '7' || this.searchForm.dictType === '9') {
                if (this.searchForm[date[this.searchForm.dictType]].length <= 0) {
                  this.$message('所选统计方式数量不为1');
                  return;
                }
              } else {
                if (!this.searchForm[date[this.searchForm.dictType]]) {
                  this.$message('所选统计方式数量不为1');
                  return;
                }
              }

              // if (!this.searchForm[date[this.searchForm.dictType]]) {
              //   this.$message('所选统计方式数量不为1');
              //   return;
              // }
            }

            this.proportionOfInputCategories();
          } else if (this.searchForm.dictType === '6') {
            this.$message('曲线图不支持分区');
          } else {
            this.$message('请选择统计方式');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    LeiJiQuXianClick() {
      this.$refs['searchForm'].validate((valid) => {
        if (valid) {
          let {classZu, deptId, partitionId, groupIdList, mySalesEmployee} = this.searchForm;
          if (classZu === '1' && !deptId.toString() && !partitionId.toString() && !groupIdList.toString() && !mySalesEmployee.toString()) {
            this.$message('部门、分区、小组、销售雇员至少选一个');
            return;
          }
          console.log(this.searchForm.dictType, 111)
          if (this.searchForm.dictType && this.searchForm.dictType !== '6') {
            if (this.searchForm.dictType !== '8') {
              let date = {
                1: 'industry',
                2: 'customerRating',
                3: 'deptId',
                4: 'productCategory',
                5: 'mySalesEmployee',
                6: 'partition',
                7: 'customerAbbreviation',
                9: 'partitionId',
                11: 'deptId',
                12: 'partitionId',
                13: 'groupIdList',
                14: 'mySalesEmployee',
              }

              if (this.searchForm.dictType === '9' || this.searchForm.dictType === '10') {
                if (this.searchForm.industry.length <= 0 || !this.searchForm.productCategory) {
                  this.$message('所选统计方式数量不为1');
                  return;
                }
              }else if (this.searchForm.dictType === '1' || this.searchForm.dictType === '7' || this.searchForm.dictType === '9') {
                if (this.searchForm[date[this.searchForm.dictType]].length <= 0) {
                  this.$message('所选统计方式数量不为1');
                  return;
                }
              } else {
                if (!this.searchForm[date[this.searchForm.dictType]]) {
                  this.$message('所选统计方式数量不为1');
                  return;
                }
              }

              // if (!this.searchForm[date[this.searchForm.dictType]]) {
              //   this.$message('所选统计方式数量不为1');
              //   return;
              // }
            }

            this.cumulativeSampleCloseRate();
          } else if (this.searchForm.dictType === '6') {
            this.$message('曲线图不支持分区');
          } else {
            this.$message('请选择统计方式');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    ssCrDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ssCrDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    dictTypeChange(e) {
      this.searchForm.classZu = "0";
      this.searchForm.groupIdList = "";
      this.searchForm.industry = "";
      this.searchForm.productCategory = "";
      this.searchForm.deptId = "";
      this.searchForm.partitionId = "";
      this.searchForm.partition = "";
      this.searchForm.mySalesEmployee = "";
      this.searchForm.customerRating = "";
      this.searchForm.customerAbbreviation = "";

      if (e === '12') {
        this.partitionDropDownBox();
      }else if (e === '13') {
        this.groupDropDown();
      }else if (e === '14') {
        this.salesEmployeeDropDownBox();
      }
    },
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/rateDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/rateDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = response.data.rateList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    top100CompaniesInSales() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=top100CompaniesInSales`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=top100CompaniesInSales', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.top100CompaniesInSalesList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptChange() {
      this.searchForm.partitionId = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
      }
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
      }
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.salesEmployeeDropDownBox();
      }
    },
    partitionDropDownBox() {
      let date = `roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptId.toString()}`
      if (this.searchForm.dictType === '12') {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = {
        deptId: this.searchForm.deptId.toString(),
        partitionId: this.searchForm.partitionId.toString(),
        groupId: this.searchForm.groupIdList.toString(),
        roleName: this.roleMap.roleName,
        id: JSON.parse(sessionStorage.getItem('user')).id,
      }

      if (this.searchForm.dictType === '14') {
        date = {
          deptId: this.user_date.deptId,
          partitionId: this.user_date.partitionId,
          groupId: this.user_date.groupId,
          roleName: this.user_date.roleName,
          id: JSON.parse(sessionStorage.getItem('user')).id,
        }
      }
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, date)
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deliveryDataCustomerDropbox`)
          .then((response) => {
            console.log('dropDownBox/deliveryDataCustomerDropbox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataCustomerDropboxList = response.data.customerList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,
          deliveryYears: "",
          industry: "",
          productCategory: "",
          deliveryDate: "",
          actualDeliveryDate: "",
          orderFirstDeliveryDate: "",
          updateBy: "",
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.deliveryYears = data.deliveryYears;
        this.ruleForm.industry = data.industry;
        this.ruleForm.productCategory = data.productCategory;
        this.ruleForm.deliveryDate = data.deliveryTime;
        this.ruleForm.actualDeliveryDate = data.actualDeliveryTime;
        this.ruleForm.orderFirstDeliveryDate = data.orderFirstDeliveryTime;
        this.ruleForm.updateBy = data.updateBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = this.searchForm;
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleCloseRate`, {
            month: date.month,
            industry: date.industry,
            productCategory: date.productCategory,
            deptId: date.deptId,
            // partition: date.partition,
            mySalesEmployee: date.mySalesEmployee.toString(),
            customerRating: date.customerRating,
            customerAbbreviation: date.customerAbbreviation,
            combination: this.searchForm.classZu,
            flag:this.searchForm.dictType == 10 ? '7' : '',
          })
          .then((response) => {
            console.log('sampleDataAnalysis/top100CompaniesInSales', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.list;
              if (response.data.list.length > 0) {
                this.tableTime = Object.keys(response.data.list[0].sumSalesQuota);
              }
              // this.total = response.data.total;
              // this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=closeRateMode`)
          .then((response) => {
            console.log('dropDownBox/sampleOrderIndustryDropbox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('customerRating', this.ruleForm.customerRating);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('deliveryYears', this.ruleForm.deliveryYears);
      data.append('industry', this.ruleForm.industry);
      data.append('productCategory', this.ruleForm.productCategory);
      data.append('deliveryTime', this.ruleForm.deliveryDate);
      data.append('actualDeliveryTime', this.ruleForm.actualDeliveryDate);
      data.append('orderFirstDeliveryTime', this.ruleForm.orderFirstDeliveryDate);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/orderShipment/edit`, data)
          .then((response) => {
            loading.close();
            console.log('orderShipment/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/orderShipment/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('orderShipment/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择订单');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/orderShipment/delBatch?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('orderShipment/delBatch', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatchSearch() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      let deliveryYearsInt = this.searchForm.deliveryYearsInt && this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      let actualDeliveryDate = this.searchForm.actualDeliveryDate && this.searchForm.actualDeliveryDate.length > 0 ? this.searchForm.actualDeliveryDate : ['', '']
      let orderFirstDeliveryDate = this.searchForm.orderFirstDeliveryDate && this.searchForm.orderFirstDeliveryDate.length > 0 ? this.searchForm.orderFirstDeliveryDate : ['', '']
      data.append('deliveryYearsStart', deliveryYearsInt[0]);
      data.append('deliveryYearsEnd', deliveryYearsInt[1]);
      data.append('actualDeliveryDateStart', actualDeliveryDate[0]);
      data.append('actualDeliveryDateEnd', actualDeliveryDate[1]);
      data.append('orderFirstDeliveryDateStart', orderFirstDeliveryDate[0]);
      data.append('orderFirstDeliveryDateEnd', orderFirstDeliveryDate[1]);
      data.append('industryList', this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "");
      data.append('productCategoryList', this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "");
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.toString());
      data.append('deptIdList', this.searchForm.deptIdList.toString());
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.toString());
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      this.axios
          .post(this.$urlHttp + `/orderShipment/deleteSearchResults`, {
            deliveryYearsStart: deliveryYearsInt[0],
            deliveryYearsEnd: deliveryYearsInt[1],
            actualDeliveryDateStart: actualDeliveryDate[0],
            actualDeliveryDateEnd: actualDeliveryDate[1],
            orderFirstDeliveryDateStart: orderFirstDeliveryDate[0],
            orderFirstDeliveryDateEnd: orderFirstDeliveryDate[1],
            industryList: this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "",
            productCategoryList: this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "",
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
          })
          .then((response) => {
            loading.close();
            console.log('orderShipment/deleteSearchResults', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    searchSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.customers_group();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
    a() {
      let a = 1;
      let b = a-- + --a;

    }
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
