<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">物料主档管理</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <el-upload
              :show-file-list="false"
              class="upload-demo"
              :data="{createBy:user.userName}"
              :action="`${$urlHttp}/materialManage/uploadMmListFile`"
              :on-change="handleUpdate"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :on-exceed="handleExceed">
            <div class="select_xin">导入物料主档</div>
          </el-upload>
            <div @click="exportExcelSampleList" class="select_xia">导出物料主档</div>
          <a :href="`${$urlHttp}/statics/excel/物料主档导入模板.xlsx`" style="text-decoration: none">
            <div class="select_xia">下载导入模版</div>
          </a>
<!--          <div v-if="$buttonStatus('物料主档-添加')" @click="addOfEdit(true)" class="select_xin">新增</div>-->
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchForm" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="material" label="新料号/旧料号" label-width="120px">
              <el-input style="width: 99%" v-model="searchForm.material" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="productCategory" label="产品大类" label-width="120px">
              <el-select filterable collapse-tags style="width: 99%" v-model="searchForm.productCategory">
                <el-option
                    v-for="(value,key) in deliveryDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="standard" label="标准" label-width="120px">
              <el-input style="width: 99%" v-model="searchForm.standard" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="materialCode" label="材料信息代码" label-width="120px">
              <el-input style="width: 99%" v-model="searchForm.materialCode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="customers_group()" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table

              :data="tableData"
              style="width: 100%">
            <el-table-column
                prop="id"
                type="index"
                label="编号"
            >
            </el-table-column>
            <el-table-column
                prop="material"
                label="新料号"
            >
            </el-table-column>
            <el-table-column
                prop="oldMaterial"
                label="旧料号"
            >
            </el-table-column>
            <el-table-column
                prop="productCategory"
                label="产品大类"
            >
            </el-table-column>
            <el-table-column
                prop="describes"
                label="描述"
            >
            </el-table-column>
            <el-table-column
                prop="insideDiameter"
                label="内径"
            >
            </el-table-column>
            <el-table-column
                prop="wireDiameter"
                label="线径"
            >
            </el-table-column>
            <el-table-column
                prop="standard"
                label="标准"
            >
            </el-table-column>
            <el-table-column
                prop="materialCode"
                label="材料信息代码"
            >
            </el-table-column>
            <el-table-column
                prop="createBy"
                label="创建者"
            >
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
            >
            </el-table-column>
            <el-table-column
                prop="updateBy"
                label="更新者"
            >
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="更新时间"
            >
            </el-table-column>
<!--            <el-table-column-->
<!--                label="操作"-->
<!--                width="200"-->
<!--            >-->
<!--              <template slot-scope="scope">-->
<!--                <el-button-->
<!--                    v-if="$buttonStatus('物料主档-编辑')"-->
<!--                    size="mini"-->
<!--                    type="warning"-->
<!--                    @click="addOfEdit(false, scope.row)">编辑-->
<!--                </el-button>-->
<!--                <el-popover-->
<!--                    v-if="$buttonStatus('物料主档-删除')"-->
<!--                    style="margin-left: 10px"-->
<!--                    placement="top"-->
<!--                    width="160"-->
<!--                    v-model="scope.row.tanshan">-->
<!--                  <p>确定删除该物料主档吗？</p>-->
<!--                  <div style="text-align: right; margin: 0">-->
<!--                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>-->
<!--                    <el-button type="primary" size="mini" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">-->
<!--                      确定-->
<!--                    </el-button>-->
<!--                  </div>-->
<!--                  <el-button-->
<!--                      slot="reference"-->
<!--                      size="mini"-->
<!--                      type="danger">删除-->
<!--                  </el-button>-->
<!--                </el-popover>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </div>
        <el-pagination
            background
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="tanStatus?'添加物料主档管理':'修改物料主档管理'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="material" label="新料号" label-width="120px">
          <el-input style="width: 50%" v-model="ruleForm.material" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="oldMaterial" label="旧料号" label-width="120px">
          <el-input style="width: 50%" v-model="ruleForm.oldMaterial" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="productCategory" label="产品大类" label-width="120px">
          <el-select filterable style="width: 50%" v-model="ruleForm.productCategory">
            <el-option
                v-for="(value,key) in deliveryDataIndustryDropboxList"
                :key="key"
                :label="value"
                :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="describes" label="描述" label-width="120px">
          <el-input style="width: 50%" v-model="ruleForm.describes" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="insideDiameter" label="内径" label-width="120px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.insideDiameter" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="wireDiameter" label="线径" label-width="120px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.wireDiameter" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="standard" label="标准" label-width="120px">
          <el-input style="width: 50%" v-model="ruleForm.standard" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="materialCode" label="材料信息代码" label-width="120px">
          <el-input style="width: 50%" v-model="ruleForm.materialCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";

//     "id": id,
//     "customerCode": 客户代码,
//     "customerFullName": 客户全称,
//     "customerAbbreviation": 客户简称,
//     "industry": 行业,
//     "salesEmployee": 销售雇员,
//     "customerRating": 客户评级,
//     "createBy": 创建者,
//     "createTime": 创建时间,
//     "updateBy": 更新者,
//     "updateTime": 更新时间

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "MaterialManageList",
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      user: {},
      ruleForm: {
        id: 0,
        material: "", //新料号
        oldMaterial: "", //旧料号
        productCategory: "", //产品大类
        describes: "", //描述
        insideDiameter: "", //内径
        wireDiameter: "", //线径
        standard: "", //标准
        materialCode: "", //材料信息代码
        createBy: "", //创建者
      },
      rules: {
        material: [
          {required: true, message: '请输入新料号', trigger: 'blur'}
        ],
        oldMaterial: [
          {required: true, message: '请输入旧料号', trigger: 'blur'}
        ],
        productCategory: [
          {required: true, message: '请选择产品大类', trigger: 'blur'}
        ],
        describes: [
          {required: true, message: '请输入描述', trigger: 'blur'}
        ],
        insideDiameter: [
          {required: true, message: '请输入内径', trigger: 'blur'}
        ],
        wireDiameter: [
          {required: true, message: '请输入线径', trigger: 'blur'}
        ],
        standard: [
          {required: true, message: '请输入标准', trigger: 'blur'}
        ],
        materialCode: [
          {required: true, message: '请输入材料信息代码', trigger: 'blur'}
        ],
        createBy: [
          {required: true, message: '请输入创建者', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      tableData: [],
      ratingFunctionList: [],
      deliveryDataIndustryDropboxList: [],
      salesEmployeeDropDownBoxList: [],
      searchForm: {
        material: "",
        productCategory: "",
        standard: "",
        materialCode: "",
      },
    }
  },
  mounted() {
    // "id": id,
    // "material": 新料号,
    // "oldMaterial": 旧料号,
    // "productCategory": 产品大类,
    // "describes": 描述,
    // "insideDiameter": 内径,
    // "wireDiameter": 线径,
    // "standard": 标准,
    // "materialCode": 材料信息代码,
    // "createBy": 创建者,
    // "createTime": 创建时间,
    // "updateBy": 更新者,
    // "updateTime": 更新时间
    let user = JSON.parse(sessionStorage.getItem('user'));
    this.user = user;
    this.customers_group();
    // this.ratingFunction();
    this.deliveryDataIndustryDropbox();
    // this.salesEmployeeDropDownBox();
  },
  methods: {
    exportExcelSampleList() {
      let queryString = Object.keys(this.searchForm).map(key => key + '=' + this.searchForm[key]).join('&');
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      window.location.href = this.$urlHttp + `/materialManage/exportExcel?${queryString}`
      loading.close();
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/personnelDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.personnelList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataIndustryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      // this.resetForm('ruleForm');
      if (type) {
        this.ruleForm = {
          id: 0,
          material:"",
          oldMaterial:"",
          productCategory:"",
          describes:"",
          insideDiameter:"",
          wireDiameter:"",
          standard:"",
          materialCode:"",
        }
      } else {
        this.ruleForm.id = data.id;
        this.ruleForm.material = data.material;
        this.ruleForm.oldMaterial = data.oldMaterial;
        this.ruleForm.productCategory = data.productCategory;
        this.ruleForm.describes = data.describes;
        this.ruleForm.insideDiameter = data.insideDiameter;
        this.ruleForm.wireDiameter = data.wireDiameter;
        this.ruleForm.standard = data.standard;
        this.ruleForm.materialCode = data.materialCode;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', 20);
      data.append('material', this.searchForm.material);
      data.append('productCategory', this.searchForm.productCategory);
      data.append('standard', this.searchForm.standard);
      data.append('materialCode', this.searchForm.materialCode);
      this.axios
          .post(this.$urlHttp + `/materialManage/list`, data)
          .then((response) => {
            console.log('customerRating/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('material', this.ruleForm.material);
      data.append('oldMaterial', this.ruleForm.oldMaterial);
      data.append('productCategory', this.ruleForm.productCategory);
      data.append('describes', this.ruleForm.describes);
      data.append('insideDiameter', this.ruleForm.insideDiameter);
      data.append('wireDiameter', this.ruleForm.wireDiameter);
      data.append('standard', this.ruleForm.standard);
      data.append('materialCode', this.ruleForm.materialCode);
      data.append('createBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/materialManage/add`, data)
          .then((response) => {
            loading.close();
            console.log('materialManage/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('material', this.ruleForm.material);
      data.append('oldMaterial', this.ruleForm.oldMaterial);
      data.append('productCategory', this.ruleForm.productCategory);
      data.append('describes', this.ruleForm.describes);
      data.append('insideDiameter', this.ruleForm.insideDiameter);
      data.append('wireDiameter', this.ruleForm.wireDiameter);
      data.append('standard', this.ruleForm.standard);
      data.append('materialCode', this.ruleForm.materialCode);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/materialManage/edit`, data)
          .then((response) => {
            loading.close();
            console.log('materialManage/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/materialManage/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('materialManage/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
