<template>
 <div class="content">
   <span>数据管理驾驶舱</span>
 </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "RightHeader"
}
</script>

<style scoped>
  .content {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
</style>
