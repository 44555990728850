<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">订单明细导入</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <el-upload
              v-if="$buttonStatus('订单明细-导入')"
              :show-file-list="false"
              class="upload-demo"
              :data="{createBy:user.userName}"
              :action="`${$urlHttp}/order/uploadFile`"
              :on-change="handleUpdate"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :on-exceed="handleExceed">
            <div class="select_xin">选择文件</div>
          </el-upload>
          <a :href="`${$urlHttp}/statics/excel/订单数据导入模板.xlsx`" style="text-decoration: none">
            <div class="select_xia">下载数据模板</div>
          </a>
          <div @click="exportExcelSampleList" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchForm" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="calendarMonthInt" label="历年月" label-width="80px">
              <el-date-picker
                  style="width:99%"
                  v-model="searchForm.calendarMonthInt"
                  value-format="timestamp"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="deptId" label="部门" label-width="80px">
              <el-select @change="deptChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.deptId">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployee" label="销售雇员" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.mySalesEmployee">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="industry" label="行业" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.industry">
                <el-option
                    v-for="(value,key) in orderDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdList" label="分区" label-width="80px">
              <el-select @change="partitionChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.partitionIdList">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerAbbreviation" label="客户" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.customerAbbreviation">
                <el-option
                    v-for="(value,key) in orderDataCustomerDropboxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="productGroup" label="产品大类" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.productGroup">
                <el-option
                    v-for="(value,key) in orderDataProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="80px">
              <el-select @change="groupChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerRating" label="客户评级" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.customerRating">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="customers_group()" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
            </el-form-item>
            <div style="display: flex">
              <el-popconfirm
                  v-if="$buttonStatus('订单明细-删除')"
                  title="确认批量删除吗？"
                  @confirm="delBatch"
              >
                <div slot="reference" v-if="$buttonStatus('订单明细-删除')" class="select_chong">批量删除</div>
              </el-popconfirm>
              <el-popconfirm
                  v-if="$buttonStatus('订单明细-删除')"
                  title="确认删除搜索结果吗？"
                  @confirm="delBatchSearch"
              >
                <div slot="reference" v-if="$buttonStatus('订单明细-删除')" class="select_chong">删除搜索结果</div>
              </el-popconfirm>
<!--              <div v-if="$buttonStatus('订单明细-删除')" @click="delBatch" class="select_chong">批量删除</div>-->
<!--              <div v-if="$buttonStatus('订单明细-删除')" @click="delBatchSearch" class="select_chong">删除搜索结果</div>-->
            </div>

          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table

              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="calendarMonth"
                label="历年月"
            >
            </el-table-column>
            <el-table-column
                prop="salesDocument"
                label="销售凭证"
            >
            </el-table-column>

            <el-table-column
                prop="customerCode"
                label="客户代码"
            >
            </el-table-column>
            <el-table-column
                prop="material"
                label="物料"
            >
            </el-table-column>
            <el-table-column
                prop="customerMaterial"
                label="客户物料"
            >
            </el-table-column>
            <el-table-column
                prop="amount"
                label="销售量"
            >
            </el-table-column>
            <el-table-column
                prop="sales"
                label="销货额"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
            >
              <template slot-scope="scope">
                <el-button
                    v-if="$buttonStatus('订单明细-编辑')"
                    size="mini"
                    type="warning"
                    @click="addOfEdit(false, scope.row)">编辑
                </el-button>
                <el-popover
                    v-if="$buttonStatus('订单明细-删除')"
                    style="margin-left: 10px"
                    placement="top"
                    width="160"
                    v-model="scope.row.tanshan">
                  <p>确定删除该订单数据吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 100,1000]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="tanStatus?'添加订单出货':'修改订单数据'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="calendarMonth" label="历年月" label-width="80px">
          <el-date-picker
              style="width: 50%"
              v-model="ruleForm.calendarMonth"
              value-format="yyyy/MM"
              type="month">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="salesDocument" label="销售凭证" label-width="80px">
          <el-input style="width: 50%" v-model="ruleForm.salesDocument" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="customerCode" label="客户代码" label-width="80px">
          <el-input style="width: 50%" v-model="ruleForm.customerCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="material" label="物料" label-width="80px">
          <el-input style="width: 50%" v-model="ruleForm.material" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="customerMaterial" label="客户物料" label-width="80px">
          <el-input style="width: 50%" v-model="ruleForm.customerMaterial" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="amount" label="销售量" label-width="80px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.amount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="sales" label="销货额" label-width="80px">
          <el-input style="width: 50%" v-model="ruleForm.sales" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <!--          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";
import {mixins} from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderListImport",
  components: {},
  mixins: [mixins],
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      ruleForm: {
        id: 0,//id
        calendarMonth: "",//历年月
        salesDocument: "",//销售凭证
        customerCode: "",//客户代码
        material: "",//物料
        customerMaterial: "",//客户物料
        amount: "",//销售量
        sales: "",//销货额
        updateBy: "",//更新者
      },
      rules: {
        calendarMonth: [
          {required: true, message: '请选择历年月', trigger: 'blur'}
        ],
        salesDocument: [
          {required: true, message: '请选择销售凭证', trigger: 'blur'}
        ],
        customerCode: [
          {required: true, message: '请选择客户代码', trigger: 'blur'}
        ],
        material: [
          {required: true, message: '请选择物料', trigger: 'blur'}
        ],
        customerMaterial: [
          {required: true, message: '请选择客户物料', trigger: 'blur'}
        ],
        amount: [
          {required: true, message: '请选择销售量', trigger: 'blur'}
        ],
        sales: [
          {required: true, message: '请选择销货额', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        calendarMonthInt: [],
        calendarMonthIntStart: "",
        calendarMonthIntEnd: "",
        customerAbbreviation: "",
        industry: "",
        productGroup: "",
        mySalesEmployee: "",
        customerRating: "",
        deptId: "",
        partitionIdList: [],
        groupIdList: [],
      },
      IndustryList: [],
      ratingFunctionList: [],
      product_categoryList: [],
      selectList: [],
      groupDropDownList: [],
      customerRatingDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      deptDropDownBoxList: [],
      partitionDropDownBoxList: [],
      orderDataIndustryDropboxList: [],
      orderDataProductCategoryDropboxList: [],
      orderDataCustomerDropboxList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
    }
  },
  mounted() {
    // "id": id,
    // "calendarMonth": 历年月,
    // "salesDocument": 销售凭证,
    // "customerCode": 客户代码,
    // "material": 物料,
    // "customerMaterial": 客户物料,
    // "amount": 销售量,
    // "sales": 销货额,
    // "createBy": 创建者,
    // "createTime": 创建时间,
    // "updateBy": 更新者,
    // "updateTime": 更新时间

    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    this.ratingFunction(); // 评级
    // this.IndustryFunction(); // 行业
    // this.product_categoryFunction(); // 产品大类
    this.customerRatingDropDownBox(); //客户
    // this.salesEmployeeDropDownBox(); //销售雇员
    this.deptDropDownBox(); //部门
    this.orderDataIndustryDropbox(); //搜索行业
    this.orderDataProductCategoryDropbox(); //搜索产品大类
    this.orderDataCustomerDropbox(); //搜索客户
  },
  methods: {
    exportExcelSampleList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      window.location.href = this.$urlHttp + `/order/exportExcel?calendarMonthIntStart=${calendarMonthInt[0] ? calendarMonthInt[0] / 1000 : ''}&calendarMonthIntEnd=${calendarMonthInt[1] ? calendarMonthInt[1] / 1000 : ''}&customerAbbreviationList=${this.searchForm.customerAbbreviation.length > 0 ? this.searchForm.customerAbbreviation.toString() : ""}&industryList=${this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : ""}&productCategoryList=${this.searchForm.productGroup.length > 0 ? this.searchForm.productGroup.toString() : ""}&mySalesEmployeeList=${this.searchForm.mySalesEmployee.length > 0 ? this.searchForm.mySalesEmployee.toString() : ""}&customerRatingList=${this.searchForm.customerRating.length > 0 ? this.searchForm.customerRating.toString() : ""}&deptIdList=${this.searchForm.deptId.length > 0 ? this.searchForm.deptId.toString() : ""}&partitionIdList=${this.searchForm.partitionIdList.toString()}`
      loading.close();
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.salesEmployeeDropDownBox();
    },
    groupDropDown() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptId}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptId}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,
          calendarMonth: "",
          salesDocument: "",
          customerCode: "",
          material: "",
          customerMaterial: "",
          amount: "",
          sales: "",
          updateBy: "",
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.calendarMonth = data.calendarMonth;
        this.ruleForm.salesDocument = data.salesDocument;
        this.ruleForm.customerCode = data.customerCode;
        this.ruleForm.material = data.material;
        this.ruleForm.customerMaterial = data.customerMaterial;
        this.ruleForm.amount = data.amount;
        this.ruleForm.sales = data.sales;
        this.ruleForm.updateBy = data.updateBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', this.pageSize);
      data.append('calendarMonthIntStart', calendarMonthInt[0] ? calendarMonthInt[0] / 1000 : '');
      data.append('calendarMonthIntEnd', calendarMonthInt[1] ? calendarMonthInt[1] / 1000 : '');
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviation.length > 0 ? this.searchForm.customerAbbreviation.toString() : "");
      data.append('industryList', this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "");
      data.append('productCategoryList', this.searchForm.productGroup.length > 0 ? this.searchForm.productGroup.toString() : "");
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployee.length > 0 ? this.searchForm.mySalesEmployee.toString() : "");
      data.append('customerRatingList', this.searchForm.customerRating.length > 0 ? this.searchForm.customerRating.toString() : "");
      data.append('deptIdList', this.searchForm.deptId.length > 0 ? this.searchForm.deptId.toString() : "");
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      data.append('groupIdList', this.searchForm.groupIdList.toString());
      this.axios
          .post(this.$urlHttp + `/order/list`, data)
          .then((response) => {
            console.log('order/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/rateDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/rateDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = response.data.rateList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, {
            deptId: this.searchForm.deptId.toString(),
            partitionId: this.searchForm.partitionIdList.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('customerRating', this.ruleForm.customerRating);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('calendarMonth', this.ruleForm.calendarMonth);
      data.append('salesDocument', this.ruleForm.salesDocument);
      data.append('customerCode', this.ruleForm.customerCode);
      data.append('material', this.ruleForm.material);
      data.append('customerMaterial', this.ruleForm.customerMaterial);
      data.append('amount', this.ruleForm.amount);
      data.append('sales', this.ruleForm.sales);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/order/edit`, data)
          .then((response) => {
            loading.close();
            console.log('order/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/order/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('order/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择订单');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/order/delBatch?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('order/delBatch', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatchSearch() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/order/deleteSearchResults`, {
            calendarMonthIntStart: calendarMonthInt[0] ? calendarMonthInt[0] / 1000 : '',
            calendarMonthIntEnd: calendarMonthInt[1] ? calendarMonthInt[1] / 1000 : '',
            customerAbbreviationList: this.searchForm.customerAbbreviation.length > 0 ? this.searchForm.customerAbbreviation.toString() : "",
            industryList: this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "",
            productCategoryList: this.searchForm.productGroup.length > 0 ? this.searchForm.productGroup.toString() : "",
            mySalesEmployeeList: this.searchForm.mySalesEmployee.length > 0 ? this.searchForm.mySalesEmployee.toString() : "",
            customerRatingList: this.searchForm.customerRating.length > 0 ? this.searchForm.customerRating.toString() : "",
            deptIdList: this.searchForm.deptId.length > 0 ? this.searchForm.deptId.toString() : "",
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
          })
          .then((response) => {
            loading.close();
            console.log('order/delBatch', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
