<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">样品销售额导入</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <!--          <div @click="addOfEdit(true)" class="select_xin">新增</div>-->
          <div @click="exportExcelSampleList" class="select_sou">导出搜索结果</div>
          <el-upload
              v-if="$buttonStatus('出货数据-导入')"
              :show-file-list="false"
              class="upload-demo"
              :data="{createBy:user.userName}"
              :action="`${$urlHttp}/sampleDataAnalysis/uploadSalesVolumeFile`"
              :on-change="handleUpdate"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :on-exceed="handleExceed">
            <div class="select_xin">选择文件</div>
          </el-upload>
          <a :href="`${$urlHttp}/statics/excel/销售额导入模板.xlsx`" style="text-decoration: none">
            <div class="select_xia">下载数据模板</div>
          </a>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="calendarMonth" label="订单年月" label-width="80px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.calendarMonth"
                  value-format="yyyy/MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="customerAbbreviationList" label="客户" label-width="80px">
              <el-select filterable multiple collapse-tags
                         style="width: 99%" v-model="searchForm.customerAbbreviationList">
                <el-option
                    v-for="(value,key) in sampleSalesVolumeCustomerDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="material" label="新旧料号" label-width="80px">
              <el-input style="width: 99%" placeholder="请输入" v-model="searchForm.material"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="searchSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
            </el-form-item>
            <div style="display: flex;margin-bottom: 20px">
              <el-popconfirm
                  v-if="$buttonStatus('出货数据-删除')"
                  title="确认批量删除吗？"
                  @confirm="delBatch"
              >
                <div slot="reference" v-if="$buttonStatus('出货数据-删除')" class="select_chong">批量删除</div>
              </el-popconfirm>
              <el-popconfirm
                  v-if="$buttonStatus('出货数据-删除')"
                  title="确认删除搜索结果吗？"
                  @confirm="delBatchSearch"
              >
                <div slot="reference" v-if="$buttonStatus('出货数据-删除')" class="select_chong">删除搜索结果</div>
              </el-popconfirm>
<!--              <div v-if="$buttonStatus('出货数据-删除')" @click="delBatch" class="select_chong">批量删除</div>-->
<!--              <div v-if="$buttonStatus('出货数据-删除')" @click="delBatchSearch" class="select_chong">删除搜索结果</div>-->
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              height="500"
              id="tableDao"
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="calendarMonth"
                label="历年月"
            >
            </el-table-column>
            <el-table-column
                prop="customerAbbreviation"
                label="客户"
            >
            </el-table-column>
            <el-table-column
                prop="material"
                label="物料"
            >
            </el-table-column>
            <el-table-column
                prop="sales"
                label="销售量"
            >
            </el-table-column>
            <el-table-column
                prop="salesQuota"
                label="销货额"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
            >
              <template slot-scope="scope">
                <el-button
                    v-if="$buttonStatus('出货数据-编辑')"
                    size="mini"
                    type="warning"
                    @click="addOfEdit(false, scope.row)">编辑
                </el-button>
                <el-popover
                    v-if="$buttonStatus('出货数据-删除')"
                    style="margin-left: 10px"
                    placement="top"
                    width="160"
                    v-model="scope.row.tanshan">
                  <p>确定删除该样品订单吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 100,1000]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="tanStatus?'添加样品销售额导入':'修改样品销售额导入'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="calendarMonth" label="历年/月" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="ruleForm.calendarMonth"
              value-format="yyyy-MM"
              type="month">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="customerAbbreviation" label="客户" label-width="100px">
          <el-select filterable style="width: 50%"
                     v-model="ruleForm.customerAbbreviation">
            <el-option
                v-for="(value,key) in customerRatingDropDownBoxList"
                :key="key"
                :label="value.customerAbbreviation"
                :value="value.customerAbbreviation">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="material" label="物料" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.material" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="sales" label="销售量" label-width="100px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.sales" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="salesQuota" label="销售额" label-width="100px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.salesQuota" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
<!--          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="搜索" :visible.sync="searchVisible">
      <el-form ref="searchForm" :model="searchForm">
        <el-form-item prop="deliveryYearsInt" label="交货年月" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="searchForm.deliveryYearsInt"
              value-format="yyyy-MM"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="actualDeliveryDate" label="实际发货日" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="searchForm.actualDeliveryDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="orderFirstDeliveryDate" label="订单首个交货日" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="searchForm.orderFirstDeliveryDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="industry" label="行业" label-width="100px">
          <el-select filterable style="width: 50%" v-model="searchForm.industry">
            <el-option
                v-for="(value,key) in IndustryList"
                :key="key"
                :label="value.dictLabel"
                :value="value.dictLabel">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="productCategory" label="产品大类" label-width="100px">
          <el-select filterable style="width: 50%" v-model="searchForm.productCategory">
            <el-option
                v-for="(value,key) in product_categoryList"
                :key="key"
                :label="value.dictLabel"
                :value="value.dictLabel">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="customers_group()">确认</el-button>
          <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";
import {mixins} from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderImport",
  components: {},
  mixins: [mixins],
  data() {
    return {
      searchVisible: false,
      dialogFormVisible: false,
      tanStatus: true,
      ruleForm: {
        id: "",
        calendarMonth: "",
        customerAbbreviation: "",
        material: "",
        sales: "",
        salesQuota: "",
        updateBy: "",
      },
      rules: {
        calendarMonth: [
          {required: true, message: '请选择历年/月', trigger: 'blur'}
        ],
        customerAbbreviation: [
          {required: true, message: '请选择客户', trigger: 'blur'}
        ],
        material: [
          {required: true, message: '请输入物料', trigger: 'blur'}
        ],
        sales: [
          {required: true, message: '请输入销售量', trigger: 'blur'}
        ],
        salesQuota: [
          {required: true, message: '请输入销货额', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        material: "",
        customerAbbreviationList: [],
        calendarMonth: [],
      },
      searchs: {
        deliveryYearsInt: [
          {required: true, message: '请选择统计时间', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      customerRatingDropDownBoxList: [],
      deptDropDownBoxList: [],
      selectList: [],
      orderReasonList: [],
      statisticsOptionsList: [],
      salesEmployeeDropDownBoxList: [],
      sampleSalesVolumeCustomerDropboxList: [],
      tableTime: [],
      user: {}
    }
  },
  mounted() {
// “calendarMonth”: 历年月,
// “customerAbbreviation”: 客户,
// “material”: 物料,
// “sales”: 销售量,
// “salesQuota”: 销货额,


// “createBy”: 创建者,
// “createTime”: 创建时间,
// “updateBy”: 更新者,
// “updateTime”: 更新时间
    // this.IndustryFunction();
    // this.salesEmployeeDropDownBox();
    // this.product_categoryFunction();
    // this.deptDropDownBox();
    // this.statisticsOptions();
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.customerRatingDropDownBox();
    this.sampleSalesVolumeCustomerDropbox();
    // this.orderReason();
  },
  methods: {
    delBatchSearch() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonth = this.searchForm.calendarMonth.length > 0 ? this.searchForm.calendarMonth : ['', ''];
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/deleteSearchResultsSalesVolume`, {
            calendarMonthStart:calendarMonth[0],
            calendarMonthEnd:calendarMonth[1],
            material:this.searchForm.material,
            customerAbbreviationList:this.searchForm.customerAbbreviationList.toString(),
          })
          .then((response) => {
            loading.close();
            console.log('sampleDataAnalysis/deleteSearchResultsSalesVolume', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    exportExcelSampleList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonth = this.searchForm.calendarMonth.length > 0 ? this.searchForm.calendarMonth : ['', ''];
      window.location.href = this.$urlHttp + `/sampleDataAnalysis/exportExcelSalesVolume?calendarMonthStart=${calendarMonth[0]}&calendarMonthEnd=${calendarMonth[1]}&material=${this.searchForm.material}&customerAbbreviationList=${this.searchForm.customerAbbreviationList.toString()}`
      loading.close();
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "样品销售额导入.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    statisticsOptions() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=statisticsOptions`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=statisticsOptions', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.statisticsOptionsList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderReason() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=orderReason`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=orderReason', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderReasonList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDownBox`,{
            deptIdList: '',
            partitionIdList: '',
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/deptDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: "",
          calendarMonth: "",
          customerAbbreviation: "",
          material: "",
          sales: "",
          salesQuota: "",
          updateBy: "",
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.calendarMonth = data.calendarMonth;
        this.ruleForm.customerAbbreviation = data.customerAbbreviation;
        this.ruleForm.material = data.material;
        this.ruleForm.sales = data.sales;
        this.ruleForm.salesQuota = data.salesQuota;
        this.ruleForm.updateBy = data.updateBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonth = this.searchForm.calendarMonth.length > 0 ? this.searchForm.calendarMonth : ['', ''];
      let data = new FormData();
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', this.pageSize);
      data.append('calendarMonthStart', calendarMonth[0]);
      data.append('calendarMonthEnd', calendarMonth[1]);
      data.append('material', this.searchForm.material);
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.toString());
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/salesVolumeList`, data)
          .then((response) => {
            console.log('sampleDataAnalysis/salesVolumeList', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              // if (response.data.list.length > 0){
              //   this.tableTime = Object.keys(response.data.list[0].sumSalesQuota);
              // }
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleSalesVolumeCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sampleSalesVolumeCustomerDropbox`)
          .then((response) => {
            console.log('dropDownBox/sampleSalesVolumeCustomerDropbox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleSalesVolumeCustomerDropboxList = response.data.customerList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('customerRating', this.ruleForm.customerRating);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('calendarMonth', this.ruleForm.calendarMonth);
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('material', this.ruleForm.material);
      data.append('sales', this.ruleForm.sales);
      data.append('salesQuota', this.ruleForm.salesQuota);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/editSalesVolume`, data)
          .then((response) => {
            loading.close();
            console.log('sampleDataAnalysis/editSalesVolume', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sampleDataAnalysis/delSalesVolume?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sampleDataAnalysis/delSalesVolume', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择订单');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/sampleDataAnalysis/delBatchSalesVolume?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('sampleDataAnalysis/delBatchSalesVolume', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    searchSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}



.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
