import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import UserList from '../views/UserList.vue'
import ClassList from '../views/ClassList.vue'
import RoleList from '../views/RoleList.vue'
import OrderImport from '../views/OrderImport.vue'
import DepartmentList from '../views/DepartmentList.vue'
import DictionariesClassList from '../views/DictionariesClassList.vue'
import DictionariesList from '../views/DictionariesList.vue'
import SaleUserList from '../views/SaleUserList.vue'
import CustomerUserList from '../views/CustomerUserList.vue'
import OrderOut from '../views/OrderOut.vue'
import OrderOutFrequency from '../views/OrderOutFrequency.vue'
import OrderListImport from '../views/OrderListImport.vue'
import OrderListOut from '../views/OrderListOut.vue'
import OrderListHuo from '../views/OrderListHuo.vue'
import OrderListXiao from '../views/OrderListXiao.vue'
import SampleList from '../views/SampleList.vue'
import SalesVolume from '../views/SalesVolume.vue'
import SampleDataAnalysis from '../views/SampleDataAnalysis.vue'
import Partition from '../views/Partition.vue'
import SampleInputAndOutput from '../views/SampleInputAndOutput.vue'
import customerAbbreviationSample from '../views/customerAbbreviationSample.vue'
import top100CompaniesInSales from '../views/top100CompaniesInSales.vue'
import SampleDeliveryRate from '../views/SampleDeliveryRate.vue'
import MaterialManageList from '../views/MaterialManageList.vue'
import YinShunHome from '../views/YinShunHome.vue'
import TargetSalesImport from '../views/TargetSalesImport.vue'
import UnshippedDetailsImport from '../views/UnshippedDetailsImport.vue'
import SalesTarget from '../views/SalesTarget.vue'
import UnshippedData from '../views/UnshippedData.vue'
import SalesSource from '../views/SalesSource.vue'
import SalesStatistics from '../views/SalesStatistics.vue'
import LoginLog from '../views/LoginLog.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/LoginLog',
    name: 'LoginLog',
    component: LoginLog
  },
  {
    path: '/SalesSource',
    name: 'SalesSource',
    component: SalesSource
  },
  {
    path: '/SalesStatistics',
    name: 'SalesStatistics',
    component: SalesStatistics
  },
  {
    path: '/UnshippedData',
    name: 'UnshippedData',
    component: UnshippedData
  },
  {
    path: '/SalesTarget',
    name: 'SalesTarget',
    component: SalesTarget
  },
  {
    path: '/UnshippedDetailsImport',
    name: 'UnshippedDetailsImport',
    component: UnshippedDetailsImport
  },
  {
    path: '/TargetSalesImport',
    name: 'TargetSalesImport',
    component: TargetSalesImport
  },
  {
    path: '/YinShunHome',
    name: 'YinShunHome',
    component: YinShunHome
  },
  {
    path: '/MaterialManageList',
    name: 'MaterialManageList',
    component: MaterialManageList
  },
  {
    path: '/SampleDeliveryRate',
    name: 'SampleDeliveryRate',
    component: SampleDeliveryRate
  },
  {
    path: '/top100CompaniesInSales',
    name: 'top100CompaniesInSales',
    component: top100CompaniesInSales
  },
  {
    path: '/customerAbbreviationSample',
    name: 'customerAbbreviationSample',
    component: customerAbbreviationSample
  },
  {
    path: '/SampleInputAndOutput',
    name: 'SampleInputAndOutput',
    component: SampleInputAndOutput
  },
  {
    path: '/Partition',
    name: 'Partition',
    component: Partition
  },
  {
    path: '/SampleDataAnalysis',
    name: 'SampleDataAnalysis',
    component: SampleDataAnalysis
  },
  {
    path: '/SalesVolume',
    name: 'SalesVolume',
    component: SalesVolume
  },
  {
    path: '/OrderImport',
    name: 'OrderImport',
    component: OrderImport
  },
  {
    path: '/ClassList',
    name: 'ClassList',
    component: ClassList
  },
  {
    path: '/RoleList',
    name: 'RoleList',
    component: RoleList
  },
  {
    path: '/DepartmentList',
    name: 'DepartmentList',
    component: DepartmentList
  },
  {
    path: '/DictionariesClassList',
    name: 'DictionariesClassList',
    component: DictionariesClassList
  },
  {
    path: '/DictionariesList',
    name: 'DictionariesList',
    component: DictionariesList
  },
  {
    path: '/SaleUserList',
    name: 'SaleUserList',
    component: SaleUserList
  },
  {
    path: '/CustomerUserList',
    name: 'CustomerUserList',
    component: CustomerUserList
  },
  {
    path: '/OrderOut',
    name: 'OrderOut',
    component: OrderOut
  },
  {
    path: '/OrderOutFrequency',
    name: 'OrderOutFrequency',
    component: OrderOutFrequency
  },
  {
    path: '/OrderListImport',
    name: 'OrderListImport',
    component: OrderListImport
  },
  {
    path: '/OrderListOut',
    name: 'OrderListOut',
    component: OrderListOut
  },
  {
    path: '/OrderListHuo',
    name: 'OrderListHuo',
    component: OrderListHuo
  },
  {
    path: '/OrderListXiao',
    name: 'OrderListXiao',
    component: OrderListXiao
  },
  {
    path: '/UserList',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/SampleList',
    name: 'SampleList',
    component: SampleList
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).id) {
    next()
  } else if (to.name != 'Login') {
    next({
      name: 'Login'
    })
  } else {
    next()
  }
  // ...
})


export default router
