<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">送样回报分析</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="calendarMonthInt" label="历年" label-width="120px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.calendarMonthInt"
                  value-format="yyyy/MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="industryList" label="行业" label-width="120px">
              <el-select filterable style="width: 99%" collapse-tags multiple
                         v-model="searchForm.industryList">
                <el-option
                    v-for="(value,key) in sampleOrderIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="deptIdList" label="部门" label-width="120px">
              <el-select filterable multiple :disabled="searchForm.classZu !== '1'" collapse-tags style="width: 99%"
                         @change="deptChange"
                         v-model="searchForm.deptIdList">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployeeList" label="销售雇员" label-width="120px">
              <el-select filterable style="width: 99%" :disabled="searchForm.classZu !== '1'" collapse-tags multiple
                         v-model="searchForm.mySalesEmployeeList">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="flag" label="统计类型" label-width="120px">
              <el-select clearable filterable style="width: 99%"
                         @change="flagChange"
                         v-model="searchForm.flag">
                <el-option
                    v-for="(value,key) in salesIndexStatisticsModeList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="productCategoryList" label="产品大类" label-width="120px">
              <el-select filterable style="width: 99%" collapse-tags multiple
                         v-model="searchForm.productCategoryList">
                <el-option
                    v-for="(value,key) in sampleOrderProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdList" label="分区" label-width="120px">
              <el-select :disabled="searchForm.classZu !== '1'" filterable multiple collapse-tags style="width: 99%"
                         @change="partitionChange"
                         v-model="searchForm.partitionIdList">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerRatingList" label="评级" label-width="120px">
              <el-select filterable style="width: 99%"
                         v-model="searchForm.customerRatingList">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="customerAbbreviationList" label="客户" label-width="120px">
              <el-select filterable multiple collapse-tags style="width: 99%"
                         v-model="searchForm.customerAbbreviationList">
                <el-option
                    v-for="(value,key) in ssCrDropDownBoxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="120px">
              <el-select filterable multiple collapse-tags style="width: 99%"
                         @change="groupChange"
                         :disabled="searchForm.classZu !== '1'"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="classZu" label="是否组合查询" label-width="120px">
              <el-select filterable collapse-tags style="width: 99%"
                         @change="classZuChange"
                         :disabled="user_identity"
                         v-model="searchForm.classZu">
                <el-option
                    label="是"
                    value="1">
                </el-option>
                <el-option
                    label="否"
                    value="0">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="addSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
              <div @click="quXianClick()" style="margin-top: 10px" class="select_sou">饼图/柱状图</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <!--            <el-table-column-->
            <!--                prop="table_name"-->
            <!--                :label="tableName()"-->
            <!--            >-->
            <!--            </el-table-column>-->
            <el-table-column
                prop="calendarMonth"
                label="历年/月"
            >
            </el-table-column>
            <el-table-column
                prop="customerAbbreviation"
                label="客户"
            >
            </el-table-column>
            <el-table-column
                prop="industry"
                label="行业"
            >
            </el-table-column>
            <el-table-column
                prop="productCategory"
                label="产品大类"
            >
            </el-table-column>
            <el-table-column
                prop="material"
                label="物料"
            >
            </el-table-column>
            <el-table-column
                prop="saleSpanMonth"
                label="样品产出周期（月）"
            >
            </el-table-column>
            <el-table-column
                prop="repeatSampleFrequency"
                label="重复送样次数"
            >
            </el-table-column>
            <el-table-column
                prop="sampleRateOfReturn"
                label="送样回报率"
            >
            </el-table-column>
            <el-table-column
                prop="sampleTurnoverRate"
                label="送样成交率"
            >
            </el-table-column>
            <!--            <el-table-column-->
            <!--                label="操作"-->
            <!--                width="100"-->
            <!--                v-if="tableCao"-->
            <!--            >-->
            <!--              <template slot-scope="scope">-->
            <!--                <el-button-->
            <!--                    v-if="searchForm.flag === 4"-->
            <!--                    size="mini"-->
            <!--                    type="warning"-->
            <!--                    @click="effectiveSampleDelivery(scope.row)">图表-->
            <!--                </el-button>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="曲线图" :visible.sync="dialogFormVisible">
      <div id="myChart" style="width: 100%;height: 500px"></div>
    </el-dialog>
    <el-dialog title="搜索" :visible.sync="searchVisible">
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">


        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('searchForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderListXiao",
  mixins: [mixins],
  components: {},
  data() {
    return {
      searchVisible: false,
      dialogFormVisible: false,
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      tableDataTime: [],
      searchForm: {
        calendarMonthInt: [],
        calendarMonthIntStart: "",
        calendarMonthIntEnd: "",
        groupIdList: "",
        classZu: "0",
        flag: "",
        industryList: [],
        customerAbbreviationList: [],
        partitionIdList: [],
        productCategoryList: [],
        mySalesEmployeeList: [],
        deptIdList: [],
        customerRatingS: [],
        customerRatingList: [],
      },
      searchs: {
        calendarMonthInt: [
          {required: true, message: '请选择历年', trigger: 'blur'}
        ],
        dictType: [
          {required: true, message: '请选择统计方式', trigger: 'blur'}
        ],
      },
      groupDropDownList: [],
      IndustryList: [],
      product_categoryList: [],
      partitionDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      selectList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
      echartsList: [],
      echartsName: [],
      sumSalesQuota: [],
      sumSampleTotal: [],
      salesIndexStatisticsModeList: [],
      partitionListDropDownBoxList: [],
      inputOutputAnalysisTypeList: [],
      ssCrDropDownBoxList: [],
      deptDropDownBoxList: [],
      ratingFunctionList: [],
      sampleOrderIndustryDropboxList: [],
      sampleOrderProductCategoryDropboxList: [],
      echats_date: '',
      tableCao: true,
    }
  },
  mounted() {
// “calendarMonth”: 历年/月,
// “customerAbbreviation”: 客户,
// “industry”: 行业,
// “productCategory”: 产品大类,
// “material”: 物料,
// “saleSpanMonth”: 样品产出周期（月）,
// “repeatSampleFrequency”: 重复送样次数,
// “sampleRateOfReturn”: 送样回报率,
// “sampleTurnoverRate”: 送样成交率,
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    this.salesEmployeeDropDownBox();
    // this.IndustryFunction();
    // this.product_categoryFunction();
    this.salesIndexStatisticsMode();
    this.deptDropDownBox();
    this.inputOutputAnalysisType();
    this.ssCrDropDownBox();
    this.ratingFunction();
    this.sampleOrderIndustryDropbox();
    this.sampleOrderProductCategoryDropbox();
    // this.partitionDropDownBox();
    // this.groupDropDown();
    // this.partitionListDropDownBox();
  },
  methods: {
    classZuChange(e) {
      console.log(111, e);
      if (e === '0') {
        this.$set(this.searchForm, 'deptIdList', []);
        this.$set(this.searchForm, 'partitionIdList', []);
        this.$set(this.searchForm, 'groupIdList', []);
        this.$set(this.searchForm, 'mySalesEmployeeList', []);
      }
    },
    flagChange(e){
      console.log(111,e);
      if (e !== ''){
        this.searchForm.dictType = ''
      }
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.salesEmployeeDropDownBox();
    },
    groupDropDown() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptIdList}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptIdList.toString()}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleOrderProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleOrderProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleOrderIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleOrderIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/rateDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/rateDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = response.data.rateList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    tableName() {
      // this.searchForm.dictType
      let date = this.inputOutputAnalysisTypeList.find((value, key) => {
        return value.dictValue === this.searchForm.dictType
      })

      return date ? date.dictLabel : ''
    },
    echartsTitle() {
      let name = '';
      if (this.searchForm.flag) {
        let date = this.salesIndexStatisticsModeList.find((value) => {
          return value.dictValue === this.searchForm.flag
        })
        if (date) {
          name = date.dictLabel + name
        }
      }
      return name
    },
    selectChange(e) {
      console.log(e);
      this.searchForm.classZu = "0";
      this.searchForm.groupIdList = "";
      this.searchForm.industryList = "";
      this.searchForm.productCategoryList = "";
      this.searchForm.mySalesEmployeeList = "";
      this.searchForm.deptIdList = "";
      this.searchForm.customerAbbreviationList = "";
      this.searchForm.customerRatingList = "";
      this.searchForm.partitionIdList = "";
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionListDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionListDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/partitionListDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.partitionListDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesIndexStatisticsMode() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=customerInputOutputAnalysisMode`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=customerInputOutputAnalysisMode', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesIndexStatisticsModeList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    inputOutputAnalysisType() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=customerInputOutputAnalysisType`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=customerInputOutputAnalysisType', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.inputOutputAnalysisTypeList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    toPoint(num) {
      let str = num.replace("%", "");
      return str
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "送样回报分析导出.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    radioChange(e) {
      console.log(111, e);
      this.searchForm.customerAbbreviationS = "";
      this.searchForm.industryList = "";
      this.searchForm.productCategoryList = "";
      this.searchForm.mySalesEmployeeList = "";
      this.searchForm.customerRatingS = "";
      this.searchForm.customerAbbreviationS_s = "";
    },
    drawLine(list, name, time, title = '') {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'));
      let option = {}
      if (this.searchForm.flag === 1) {
        option = {
          title: {
            text: title
          },
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: time
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
          },
          legend: {
            data: name,
          },
          series: list
        };
        // option = {
        //   xAxis: {
        //     type: 'category',
        //     axisTick: {
        //       alignWithLabel: true
        //     },
        //     data: time
        //   },
        //   legend: {
        //     data: name,
        //   },
        //   tooltip: {
        //     trigger: 'item',
        //     formatter: '{b}: {c} ({d}%)',
        //   },
        //   grid: {
        //     left: '1%',
        //     right: '1%',
        //     bottom: '1%',
        //     containLabel: true
        //   },
        //   yAxis: {
        //     type: 'value',
        //     axisLabel: {
        //       formatter: '{value}'
        //     }
        //   },
        //   series: list,
        // };
      } else {
        option = {
          title: {
            text: title
          },
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: time
          },
          legend: {
            data: name
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: list,
        };
      }

      console.log(999, option)

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(name, level) {
      if (this.echats_date) {
        this.echats_date.clear();
      }
      this.dialogFormVisible = true;
      let list = [];
      let list_name = [];
      if (name) {
        let arrData = this.echartsList.find((value) => {
          return value.name === name + '-' + level
        });
        list = [arrData];
        list_name = [name + '-' + level];
      } else {
        if (this.selectList.length > 0) {
          this.selectList.map((values) => {
            let arrData = this.echartsList.find((value) => {
              if (this.searchForm.classList === '1') {
                return value.name === values.name
              } else {
                return value.name === values.name + '-' + values.level
              }
            });
            list.push(arrData);
            list_name.push(arrData.name);
          })
        } else {
          list = this.echartsList;
          list_name = this.echartsName;
        }
      }
      setTimeout(() => {
        this.drawLine(list, list_name);
      }, 300)
    },
    quXianClick() {
      let {classZu, deptIdList, partitionIdList, groupIdList, mySalesEmployeeList} = this.searchForm;
      if (classZu === '1' && !deptIdList.toString() && !partitionIdList.toString() && !groupIdList.toString() && !mySalesEmployeeList.toString()) {
        this.$message('部门、分区、小组、销售雇员至少选一个');
        return;
      }
      // if (this.searchForm.dictType !== 7 && this.searchForm.dictType !== 6) {
      //   let date = {
      //     1: 'industryList',
      //     2: 'productCategoryList',
      //     3: 'deptIdList',
      //     4: 'mySalesEmployeeList',
      //     5: 'customerAbbreviationList',
      //     6: 'customerRatingList',
      //     8: 'partitionIdList',
      //   }
      //   if (this.searchForm.dictType === 1 || this.searchForm.dictType === 5 || this.searchForm.dictType === 8) {
      //     if (this.searchForm[date[this.searchForm.dictType]].length <= 0) {
      //       this.$message('所选统计方式数量不为1');
      //       return;
      //     }
      //   } else {
      //     if (this.searchForm[date[this.searchForm.dictType]].length !== 1) {
      //       this.$message('所选统计方式数量不为1');
      //       return;
      //     }
      //   }
      // } else if (this.searchForm.dictType === 6 && this.searchForm.customerRatingList.length < 1) {
      //   this.$message('所选统计方式数量不为1');
      //   return;
      // }

      if (this.searchForm.flag === 1) {
        this.sampleRateOfReturn();
      } else if (this.searchForm.flag === 3) {
        this.input0utPutHistogram();
      } else {
        this.$message('请选择统计类型');
      }
    },
    digui(value) {
      let arr = [];
      let sumSalesQuota = [];//销货额
      let sumSampleTotal = [];//样品金额
      let echartList = [];
      let echartName = [];

      if (typeof value === "object") {
        if (Object.keys(value).length > 0) {
          Object.keys(value).map((val) => {
            Object.keys(value[val]).map((vals) => {
              value[val][vals]['table_name'] = vals;
              arr.push(value[val][vals]);
            })
            // value[val]['table_name'] = val;
            // arr.push(value[val]);
            // sumSalesQuota = Object.keys(value[val].sumSalesQuota);
            // sumSampleTotal = Object.keys(value[val].sumSampleTotal);
          })
        }
      }
      console.log(111, arr, sumSalesQuota, sumSampleTotal);
      return {
        arr,
        sumSalesQuota,
        sumSampleTotal,
        echartList,
        echartName
      };
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/customerAbbreviationSample`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.dictType?.toString(),
            industryList: this.searchForm.industryList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            productCategoryList: this.searchForm.productCategoryList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            customerRatingList: this.searchForm.customerRatingList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/inputOutputAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {
              // const {
              //   arr,
              //   sumSalesQuota,
              //   sumSampleTotal,
              //   echartList,
              //   echartName
              // } = this.digui(response.data.mapResult, 0);
              this.tableData = response.data.mapResult;
              // this.tableDataTime = arrTime;
              // this.sumSalesQuota = sumSalesQuota;
              // this.sumSampleTotal = sumSampleTotal;
              // this.echartsList = echartList;
              // this.echartsName = echartName;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleRateOfReturn() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      // "calendarMonthStart": "2020/01",
      // "calendarMonthEnd": "2021/01",
      // "industryList": "",
      // "productCategoryList": "",
      // "deptIdList": "",
      // "mySalesEmployeeList": "",
      // "customerAbbreviationList": "",
      // "customerRatingList": "",
      // "flag": "1"
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleRateOfReturn`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            industryS: this.searchForm.industryList.toString(),
            productCategory: this.searchForm.productCategoryList.toString(),
            deptIdS: this.searchForm.deptIdList.toString(),
            mySalesEmployeeS: this.searchForm.mySalesEmployeeList.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviationList.toString(),
            customerRating: this.searchForm.customerRatingList.toString(),
            partitionIdS: this.searchForm.partitionIdList.toString(),
            groupIdS: this.searchForm.groupIdList.toString(),
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/sampleRateOfReturn', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              // response.data.mapResult
              let name = [];
              let arr = [];
              let time = [];
              let title = response.data.name
              console.log('date', date);
              if (Object.keys(date).length > 0) {
                Object.keys(date).map((value, key) => {
                  console.log(111, value);
                  let data = [];
                  // time = Object.keys(date[value][val]);
                  Object.keys(date[value]).map((val, ind) => {
                    console.log(222, val);
                    data.push({
                      name: val,
                      value: date[value][val]
                    })
                  })
                  arr.push({
                    name: value,
                    radius: [20, 100],
                    center: key === 0 ? ['25%', '50%'] : ['75%', '50%'],
                    label: {
                      formatter: '{b}: {c} ({d}%)',
                      fontSize: 14
                    },
                    type: 'pie',// bar柱状图 pie饼图 line折线图
                    data: data,
                  });
                  name.push(value);
                })
                // name = Object.keys(date);
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time, title);
              }, 300)
              console.log(arr, name, time, title);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    proportionOfInputCategories() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleCloseRate`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.dictType.toString(),
            industryS: this.searchForm.industryList.toString(),
            productCategory: this.searchForm.productCategoryList.toString(),
            deptId: this.searchForm.deptIdList.toString(),
            mySalesEmployee: this.searchForm.mySalesEmployeeList.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviationList.toString(),
            customerRating: this.searchForm.customerRatingList.toString(),
          })
          .then((response) => {
            console.log('sampleDataAnalysis/sampleCloseRate', response.data.mapResult);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              if (Object.keys(date).length > 0) {
                Object.keys(date).map((value, key) => {
                  arr.push({
                    name: value,
                    type: 'line',// bar柱状图 pie饼图 line折线图
                    data: Object.keys(date[value]).sort().map((val) => {
                      return date[value][val]
                    }),
                  });
                  time = Object.keys(date[value]).sort();
                  name.push(value);
                  // Object.keys(date[value]).map((val)=>{
                  //
                  // })
                  console.log(111, value);

                })
                time = time.sort();
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(arr, name, time);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    input0utPutHistogram() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleProductionCycle`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            industryS: this.searchForm.industryList.toString(),
            partitionIdS: this.searchForm.partitionIdList.toString(),
            productCategory: this.searchForm.productCategoryList.toString(),
            deptIdS: this.searchForm.deptIdList.toString(),
            mySalesEmployeeS: this.searchForm.mySalesEmployeeList.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviationList.toString(),
            customerRating: this.searchForm.customerRatingList.toString(),
            groupIdS: this.searchForm.groupIdList.toString(),
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/sampleProductionCycle', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              let title = response.data.name;
              if (Object.keys(date).length > 0) {
                arr.push({
                  name: '数量',
                  type: 'bar',// bar柱状图 pie饼图 line折线图
                  data: Object.values(date),
                });
                time = Object.keys(date);
                name.push('数量');
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time, title);
              }, 300)
              console.log(arr, name, time, title);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    effectiveSampleDelivery(date) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/effectiveSampleDelivery`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.dictType.toString(),
            industry: this.searchForm.dictType === 1 ? date.industry : '',
            productCategory: this.searchForm.dictType === 2 ? date.productCategory : '',
            deptId: this.searchForm.dictType === 3 ? date.deptId.toString() : '',
            mySalesEmployee: this.searchForm.dictType === 4 ? date.mySalesEmployee : '',
          })
          .then((response) => {
            console.log('sampleDataAnalysis/effectiveSampleDelivery', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              if (Object.keys(date).length > 0) {
                Object.keys(date).map((value) => {
                  console.log(111, value);
                  if (Object.keys(date[value]).length > 0) {
                    Object.keys(date[value]).map((val) => {
                      console.log(222, val, date[value][val]);
                      if (Object.keys(date[value][val]).length > 0) {
                        Object.keys(date[value][val]).map((vals) => {
                          console.log(333, vals);
                          let find = arr.findIndex((vals_) => {
                            console.log(444, vals_)
                            return vals_.name === value + '-' + vals
                          })
                          console.log(555, find, value + '-' + vals)
                          if (find > -1) {
                            arr[find].data.push(date[value][val][vals])
                          } else {
                            arr.push({
                              name: value + '-' + vals,
                              type: 'bar',// bar柱状图 pie饼图 line折线图
                              data: [date[value][val][vals]],
                            });
                          }
                          name.push(value + '-' + vals);
                        })
                      }

                      time = Object.keys(date[value]);
                    })
                  }
                })
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(arr, name, time);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleTurnoverRate(type) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleTurnoverRate`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.dictType.toString(),
            industryList: this.searchForm.industryList.toString(),
            productCategoryList: this.searchForm.productCategoryList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            type: type,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/input0utPutHistogram', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              if (Object.keys(date).length > 0) {
                Object.keys(date).map((value) => {
                  console.log(111, value);
                  if (Object.keys(date[value]).length > 0) {
                    Object.keys(date[value]).map((val) => {
                      console.log(222, val);
                      if (type === "1") {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: [date[value][val].sampleTurnoverRate],
                        });
                      } else if (type === "3") {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: [date[value][val].repeatSampleFrequency],
                        });
                      } else if (type === "4") {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: [date[value][val].saleSpanMonth],
                        });
                      } else if (type === "2") {
                        arr.push({
                          name: value + '-' + val,
                          type: 'bar',// bar柱状图 pie饼图 line折线图
                          data: [date[value][val].sampleRateOfReturn],
                        });
                      }

                      time = [calendarMonthInt[0] + "~" + calendarMonthInt[1]];
                      name.push(value + '-' + val);
                    })
                  }

                })
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisible = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(arr, name, time);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, {
            deptId: this.searchForm.deptIdList.toString(),
            partitionId: this.searchForm.partitionIdList.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    ssCrDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ssCrDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.el-dialog {
  width: 70%;
}

.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
