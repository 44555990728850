<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">订单出货频率统计分析</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="deliveryYearsInt" label="统计时间" label-width="80px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.deliveryYearsInt"
                  value-format="yyyy-MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item prop="flag" label="统计类型" label-width="80px">
              <el-radio v-model="searchForm.flag" label="3">出货频率</el-radio>
            </el-form-item>
            <el-form-item prop="classList" label="" label-width="80px">
              <el-radio @input="radioChange" v-model="searchForm.classList" label="1">订单总数</el-radio>
              <el-radio @input="radioChange" v-model="searchForm.classList" label="2">仅按行业</el-radio>
              <el-radio @input="radioChange" v-model="searchForm.classList" label="3">仅按分类</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="industryS" label="行业" label-width="80px">
              <el-select filterable style="width: 99%" collapse-tags multiple :disabled="searchForm.classList !== '2'"
                         v-model="searchForm.industryS">
                <el-option
                    v-for="(value,key) in IndustryList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictLabel">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="productCategoryS" label="产品大类" label-width="80px">
              <el-select filterable style="width: 99%" collapse-tags multiple :disabled="searchForm.classList !== '3'"
                         v-model="searchForm.productCategoryS">
                <el-option
                    v-for="(value,key) in product_categoryList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictLabel">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="addSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
              <div @click="addOfEdit('')" style="margin-top: 10px" class="select_sou">曲线图</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="name"
                :label="searchForm.classList === '1' ? '订单总数' : searchForm.classList === '2' ? '行业' : searchForm.classList === '3' ? '产品大类' : ''"
            >
            </el-table-column>
            <el-table-column
                prop="names"
                :label="searchForm.classList === '2' ? '产品大类' : searchForm.classList === '3' ? '行业' : ''"
            >
            </el-table-column>
            <el-table-column
                v-for="(item,key) in tableDataTime"
                :key="key"
                :prop="item"
                :label="item"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
                v-if="tableCao"
            >
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="warning"
                    @click="addOfEdit(searchForm.classList === '1' ? scope.row.name :scope.row.name + '_' + scope.row.names)">曲线图
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="曲线图" :visible.sync="dialogFormVisible">
      <div id="myChart" style="width: 100%;height: 500px"></div>
    </el-dialog>
    <el-dialog title="搜索" :visible.sync="searchVisible">
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">


        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('searchForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";
import {mixins} from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Order",
  components: {},
  mixins: [mixins],
  data() {
    return {
      searchVisible: false,
      dialogFormVisible: false,
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      tableDataTime: [],
      searchForm: {
        deliveryYearsInt: [],
        classList: "1",
        deliveryYearsIntStart: "2022-01",
        deliveryYearsIntEnd: "2023-01",
        flag: "3",
        orderTotal: "",
        dictType: "industry",
        industryS: [],
        productCategoryS: []
      },
      searchs: {
        deliveryYearsInt: [
          {required: true, message: '请选择统计时间', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      selectList: [],
      user: {},
      echartsList: [],
      echartsName: [],
      echats_date: '',
      tableCao: true,
    }
  },
  mounted() {
// “id”: id,
// “deliveryYears”: 交货年月,
// “industry”: 行业,
// “productCategory”: 产品大类,
// “deliveryTime”: 交货日期,
// “actualDeliveryTime”: 实际发货日,
// “orderFirstDeliveryTime”: 订单首个交货日,
// “leaveManifestPromptness”: 开出货单及时率,
// “storehouseDeliveryPromptness”: 仓库发货及时率,
// “createBy”: 创建者,
// “createTime”: 创建时间,
// “updateBy”: 更新者,
// “updateTime”: 更新时间
    let user = JSON.parse(sessionStorage.getItem('user'));
    this.user = user;
    // this.customers_group();
    this.IndustryFunction();
    this.product_categoryFunction();
  },
  methods: {
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "订单出货频率统计分析.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    radioChange(e) {
      console.log(111, e);
      this.searchForm.industryS = "";
      this.searchForm.productCategoryS = "";
    },
    drawLine(list, name) {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'))
      let option = {
        xAxis: {
          type: 'category',
          axisTick: {
              alignWithLabel: true
            },
          data: this.tableDataTime
        },
        legend: {
          data: name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'value'
        },
        series: list,
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(name) {
      if (this.echats_date) {
        this.echats_date.clear();
      }
      this.dialogFormVisible = true;
      let list = [];
      let list_name = [];
      if (name) {
        let arrData = this.echartsList.find((value) => {
          return value.name === name
        });
        console.log('arrData',arrData,name)
        list = [arrData];
        list_name = [name];
      } else {
        if (this.selectList.length > 0) {
          this.selectList.map((values) => {
            let arrData = this.echartsList.find((value) => {
              if (this.searchForm.classList === '1') {
                return value.name === values.name
              } else {
                return value.name === values.name + '_' + values.names
              }
            });
            list.push(arrData);
            list_name.push(arrData.name);
          })
        } else {
          list = this.echartsList;
          list_name = this.echartsName;
        }
      }
      setTimeout(() => {
        this.drawLine(list, list_name);
      }, 300)
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let deliveryYearsInt = this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/orderShipment/shippingFrequencyStatisticalAnalysis`, {
            deliveryYearsIntStart: deliveryYearsInt[0],
            deliveryYearsIntEnd: deliveryYearsInt[1],
            flag: this.searchForm.flag,
            orderTotal: this.searchForm.classList === "1" ? "1" : "",
            dictType: this.searchForm.classList === "2" ? "industry" : this.searchForm.classList === "3" ? "productCategory" : "",
            industryS: this.searchForm.industryS.length > 0 ? this.searchForm.industryS.toString() : "",
            productCategoryS: this.searchForm.productCategoryS.length > 0 ? this.searchForm.productCategoryS.toString() : "",
          })
          .then((response) => {
            console.log('orderShipment/shippingFrequencyStatisticalAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {
              const {
                arr,
                arrTime,
                echartList,
                echartName
              } = this.digui(response.data.mapResult, 0);
              console.log(this.digui(response.data.mapResult, 0))
              this.tableData = arr;
              this.tableDataTime = arrTime;
              this.echartsList = echartList;
              this.echartsName = echartName;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    digui(value, key) {
      let arr = [];
      let arrTime = [];
      let echartList = [];
      let echartName = [];

      let a = (value, key, obj = {}) => {
        let Obj_Value = []
        if (typeof value === 'object') {
          Obj_Value = Object.keys(value);
        }
        if (Obj_Value.length > 0) {
          Obj_Value.map((value_v, value_k) => {
            if (this.searchForm.classList === "1") {
              if (key === 1) {
                obj['name'] = value_v;
              } else if (key === 2) {
                arrTime = Obj_Value;
                obj[value_v] = value[value_v];
                if (Obj_Value.length - 1 === value_k) {
                  arr.push(JSON.parse(JSON.stringify(obj)));
                  echartName.push(obj['name']);
                  echartList.push({
                    name: obj['name'],
                    type: 'line',
                    data: Object.values(value),
                  })
                }
              }
            } else {
              if (key === 1) {
                obj['name'] = value_v;
              } else if (key === 2) {
                obj['names'] = value_v;
              } else if (key === 3) {
                arrTime = Obj_Value;
                obj[value_v] = value[value_v];
                if (Obj_Value.length - 1 === value_k) {
                  arr.push(JSON.parse(JSON.stringify(obj)));
                  echartName.push(obj['name'] + '_' + obj['names']);
                  echartList.push({
                    name: obj['name'] + '_' + obj['names'],
                    type: 'line',
                    data: Object.values(value),
                  })
                }
              }
            }

            a(value[value_v], key + 1, JSON.parse(JSON.stringify(obj)));
          })
        }
      }

      a(value, key);
      console.log(arr);
      return {
        arr,
        arrTime,
        echartList,
        echartName
      };
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}



.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
