export const mixins = {
    data() {
        return {
            user_identity : false,
            user_zhu:false,
            user_date:{},
        };
    },
    computed: {},
    created() {
        if (sessionStorage.getItem('roleMap')) {
            this.user_identity = JSON.parse(sessionStorage.getItem('roleMap')).roleName.includes('员工');
            this.user_zhu = JSON.parse(sessionStorage.getItem('roleMap')).roleName.includes('主管');
            let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
            let user = JSON.parse(sessionStorage.getItem('user'));
            this.user_date = {
                roleName: roleMap.roleName || '',
                deptId: user.deptId || '',
                partitionId: user.partitionId || '',
                groupId: user.groupId || '',
                id: user.id || '',
                saleEmployee: user.saleEmployee || '',
            };


        }
    },
    mounted() {

    },
    methods: {
        listFormData__(list){
            let listDate = JSON.parse(JSON.stringify(list));

            if (list.length > 0) {
                if (this.user_identity) {
                    listDate = listDate.filter(res=>{
                        return res.dictLabel !== '按部门' && res.dictLabel !== '按分区' && res.dictLabel !== '按小组' && res.dictLabel !== '按销售雇员'
                    })
                } else if (this.user_zhu) {
                    listDate = listDate.filter(res=>{
                        return res.dictLabel !== '按部门'
                    })
                }
            }
            return listDate;
        },
    },
};