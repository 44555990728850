<template>
  <div class="content">
    <div class="title">
      <img src="../assets/leftNav(2).png" alt="" srcset="">
      <img src="../assets/leftNav(1).png" alt="" srcset="">
    </div>

    <div class="menu">
      <template v-for="(item,key) in list">
        <div @click="clickTitle(key)" :key="item.modelName" class="menu_div">
          <div class="menu_div_leftSS">
            <div class="menu_div_left"></div>
            <span>{{ item.modelName }}</span>
          </div>
          <img v-if="index === key" src="../assets/leftNav(4).png" alt="">
          <img v-else src="../assets/leftNav(3).png" alt="">
        </div>
        <template v-if="item.chiLds && item.chiLds.length > 0">
          <el-collapse-transition :key="key">
            <div v-show="index === key"  class="menu_div_c">
              <div v-for="(val,ind) in item.chiLds" :key="ind" @click="goUrl(val.path)"
                   :class="['menu_divs',val.path === $route.name ? 'menu_divsClick' : '']">
                <div class="menu_div_leftSS">
                  <div class="menu_div_left"></div>
                  <span>{{ val.modelName }}</span>
                </div>
                <img src="../assets/leftNav(3).png" alt="">
              </div>
            </div>
          </el-collapse-transition>
        </template>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "LeftNav",
  data() {
    return {
      list: [],
      index: 0,
    }
  },
  mounted() {
    if (sessionStorage.getItem('leftNav')) {
      this.list = JSON.parse(sessionStorage.getItem('leftNav'));
      this.list.map((value, key) => {
        if (value.chiLds && value.chiLds.length > 0) {
          value.chiLds.map((val) => {
            if (this.$route.name === val.path) {
              this.index = key;
            }
          })
        }
      })
    }
  },
  methods: {
    clickTitle(key) {
      if (this.index === key) {
        this.index = '';
        return;
      }
      this.index = key;
    },
    goUrl(url) {
      if (this.$route.name === url) {
        return;
      }
      this.$router.push({
        name: url,
        params: {},
      });
      console.log(this.$route.name)
    }
  }
}
</script>

<style scoped>
.menu_div_c {
  width: 80%;
}

.menu_divsClick {
  background-color: #d9ecdf;
}
.menu_divsClick span {
  color: #bebdbd!important;
}

.menu_divs:hover {
  background-color: #d9ecdf;
}

.menu_div_leftSS span {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 14px;
}

.menu_div_leftSS {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu_divs .menu_div_left {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #003C9D;
  margin-right: 12px;
}

.menu_div .menu_div_left {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FFFFFF;
  margin-right: 12px;
}

.menu_div {
  cursor: pointer;
  width: 80%;
  height: 40px;
  background-color: #00674f;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 2px 0;
  border: 1px solid #00674f;
}

.menu_divs {
  cursor: pointer;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 2px 0;
  border: 1px solid #d9ecdf;
}

.title img {
  width: 60%;
}

.title {
  width: 100%;
  height: 282px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content >>> .el-menu {
  border: 0;
}

.content {
  min-width: 283px;
  height: 100%;
  background: #94c2a2;
  border-radius: 0px 40px 40px 0px;
  overflow-y: auto;
}
</style>
