<template>
	<div class="content">
		<div class="list">
			<el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
				<el-col :span="12">
					<span style="color: #3D3D3D">人员主档</span>
				</el-col>
				<el-col :span="12" style="display: flex;align-items:center;justify-content: flex-end">
					<el-upload
						:action="`${$urlHttp}/salesEmployee/uploadFile`"
						:before-remove="beforeRemove"
						:data="{createBy:user.userName}"
						:on-change="handleUpdate"
						:on-exceed="handleExceed"
						:on-preview="handlePreview"
						:on-remove="handleRemove"
						:show-file-list="false"
						class="upload-demo"
						multiple>
						<div class="select_xin">导入人员主档</div>
					</el-upload>
					<div @click="exportExcelSampleList" class="select_xia">导出搜索结果</div>
					<a :href="`${$urlHttp}/statics/excel/人员主档导入模板.xlsx`" style="text-decoration: none">
						<div class="select_xia">下载导入模版</div>
					</a>
					<div v-if="$buttonStatus('人员主档-添加')" class="select_xin" @click="addOfEdit(true)">新增</div>
				</el-col>
			</el-row>
			<el-form ref="searchForm" :model="searchForm" :rules="searchForm">
				<el-row style="border-bottom: 1px solid #DCDCDC;">
					<el-col :span="6">
						<el-form-item label="部门" label-width="80px" prop="deptId">
							<el-select v-model="searchForm.deptId" clearable filterable style="width: 99%"
												 @change="deptChange($event,0)">
								<el-option
									v-for="(value,key) in deptDropDownBoxList"
									:key="key"
									:label="value.deptName"
									:value="value.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="销售雇员" label-width="80px" prop="saleEmployee">
							<el-input v-model="searchForm.saleEmployee" autocomplete="off" clearable style="width: 99%"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="分区" label-width="80px" prop="partitionId">
							<el-select v-model="searchForm.partitionId" clearable filterable style="width: 99%"
												 @change="fenquChange($event,0)">
								<el-option
									v-for="(value,key) in partitionDropDownBoxList"
									:key="key"
									:label="value.partitionName"
									:value="value.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="直属上级" label-width="80px" prop="reportTo">
							<el-input v-model="searchForm.reportTo" autocomplete="off" clearable style="width: 99%"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="小组" label-width="80px" prop="groupId">
							<el-select v-model="searchForm.groupId" clearable filterable style="width: 99%">
								<el-option
									v-for="(value,key) in groupByPartitionIdDropDownList"
									:key="key"
									:label="value.groupName"
									:value="value.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="角色" label-width="80px" prop="roleId">
							<el-select v-model="searchForm.roleId" clearable filterable style="width: 99%">
								<el-option
									v-for="(value,key) in roleDropDownBoxList"
									:key="key"
									:label="value.roleName"
									:value="value.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item>
							<div style="display: flex">
								<div class="select_sou" @click="customers_group()">搜 索</div>
								<div class="select_chong" @click="resetForm('searchForm')">重 置</div>
							</div>

						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="tableDiv">
				<div class="tableDiv_div">
					<el-table
						:data="tableData"
						style="width: 100%">
						<el-table-column
							label="编号"
							prop="id"
							type="index"
						>
						</el-table-column>
						<el-table-column
							label="公司"
							prop="company"
						>
						</el-table-column>
						<el-table-column
							label="部门名称"
							prop="deptName"
						>
						</el-table-column>
						<el-table-column
							label="分区名称"
							prop="partitionName"
						>
						</el-table-column>
						<el-table-column
							label="小组名称"
							prop="groupName"
						>
						</el-table-column>
						<el-table-column
							label="销售雇员"
							prop="saleEmployee"
						>
						</el-table-column>
						<el-table-column
							label="直属上级"
							prop="reportTo"
						>
						</el-table-column>
						<el-table-column
							label="角色"
							prop="rank"
						>
						</el-table-column>
						<el-table-column
							label="手机号"
							prop="phone"
						>
						</el-table-column>
						<el-table-column
							label="是否显示"
							prop="phone"
						>
              <template slot-scope="scope">
                <el-switch
                    @change="listCheck(scope.row)"
                    v-model="scope.row.checked"
                    :active-value="1"
                    :inactive-value="2"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
              </template>
						</el-table-column>
						<el-table-column
							label="创建者"
							prop="createBy"
						>
						</el-table-column>
						<el-table-column
							label="创建时间"
							prop="createTime"
						>
						</el-table-column>
						<el-table-column
							label="更新者"
							prop="updateBy"
						>
						</el-table-column>
						<el-table-column
							label="更新时间"
							prop="updateTime"
						>
						</el-table-column>
						<el-table-column
							label="操作"
							width="200"
						>
							<template slot-scope="scope">
								<el-button
									v-if="$buttonStatus('人员主档-编辑')"
									size="mini"
									type="warning"
									@click="addOfEdit(false, scope.row)">编辑
								</el-button>
								<el-popover
									v-if="$buttonStatus('人员主档-删除')"
									v-model="scope.row.tanshan"
									placement="top"
									style="margin:0 10px"
									width="160">
									<p>确定删除该人员吗？</p>
									<div style="text-align: right; margin: 0">
										<el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
										<el-button size="mini" type="primary" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
											确定
										</el-button>
									</div>
									<el-button
										slot="reference"
										size="mini"
										type="danger">删除
									</el-button>
								</el-popover>
								<el-popover
									v-model="scope.row.tanshan1"
									placement="top"
									style="margin-top: 10px"
									width="160">
									<p>确定停用该人员吗？</p>
									<div style="text-align: right; margin: 0">
										<el-button size="mini" type="text" @click="scope.row.tanshan1 = false">取消</el-button>
										<el-button size="mini" type="primary" @click="customers_groupTing(scope.row.id,scope.row.tanshan1)">
											确定
										</el-button>
									</div>
									<el-button
                      style="margin-top: 10px"
										slot="reference"
										size="mini"
										type="danger">停用
									</el-button>
								</el-popover>
								<el-popover
                    v-if="user.userName==='admin'"
									v-model="scope.row.tanshan2"
									placement="top"
									style="margin-left: 10px"
									width="160">
									<p>确定重置该人员密码吗？</p>
									<div style="text-align: right; margin: 0">
										<el-button size="mini" type="text" @click="scope.row.tanshan2 = false">取消</el-button>
										<el-button size="mini" type="primary" @click="customers_groupChong(scope.row.id,scope.row.tanshan2)">
											确定
										</el-button>
									</div>
									<el-button
                      style="margin-top: 10px"
										slot="reference"
										size="mini"
										type="danger">重置密码
									</el-button>
								</el-popover>

							</template>
						</el-table-column>
					</el-table>
				</div>
				<el-pagination
					:current-page="currentPage4"
					:page-size="20"
					:total="total"
					background
					layout="total, prev, pager, next, jumper"
					style="margin-top: 30px"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>

		<el-dialog :title="tanStatus?'添加人员主档':'修改人员主档'" :visible.sync="dialogFormVisible">
			<el-form ref="ruleForm" :model="ruleForm" :rules="rules">
				<el-form-item label="公司" label-width="100px" prop="company">
					<el-input v-model="ruleForm.company" autocomplete="off" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="部门" label-width="100px" prop="deptId">
					<el-select v-model="ruleForm.deptId" clearable filterable style="width: 50%" @change="deptChange($event,1)">
						<el-option
							v-for="(value,key) in deptDropDownBoxList"
							:key="key"
							:label="value.deptName"
							:value="value.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分区" label-width="100px" prop="partitionId">
					<el-select v-model="ruleForm.partitionId" clearable filterable style="width: 50%"
										 @change="fenquChange($event,1)">
						<el-option
							v-for="(value,key) in partitionDropDownBoxList"
							:key="key"
							:label="value.partitionName"
							:value="value.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="小组" label-width="100px" prop="groupId">
					<el-select v-model="ruleForm.groupId" clearable filterable style="width: 50%">
						<el-option
							v-for="(value,key) in groupByPartitionIdDropDownList"
							:key="key"
							:label="value.groupName"
							:value="value.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="销售雇员" label-width="100px" prop="saleEmployee">
					<el-input v-model="ruleForm.saleEmployee" autocomplete="off" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="直属上级" label-width="100px" prop="reportTo">
					<el-input v-model="ruleForm.reportTo" autocomplete="off" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="角色" label-width="100px" prop="roleId">
					<el-select v-model="ruleForm.roleId" clearable filterable style="width: 50%">
						<el-option
							v-for="(value,key) in roleDropDownBoxList"
							:key="key"
							:label="value.roleName"
							:value="value.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="手机号" label-width="100px" prop="phone">
					<el-input v-model="ruleForm.phone" autocomplete="off" style="width: 50%" type="number"></el-input>
				</el-form-item>
				<el-form-item :prop="tanStatus ? 'pwd' : ''" label="密码" label-width="100px">
					<el-input v-model="ruleForm.pwd" :disabled="!tanStatus" autocomplete="off" style="width: 50%"
										type="password"></el-input>
				</el-form-item>
				<el-form-item style="margin-left:100px;">
					<el-button @click="addSubmit('ruleForm')">确认</el-button>
					<el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Order",
	components: {},
	data() {
		return {
			dialogFormVisible: false,
			tanStatus: true,
			ruleForm: {
				id: 0,
				company: "", //公司,
				deptId: "", //部门id（部门下拉框）,
				partitionId: "", //分区id（分区下拉框）,
				groupId: "", //小组id,
				saleEmployee: "", //销售雇员,
				reportTo: "", //直属上级,
				rank: "", //职级,
				phone: "", //手机号,
				createBy: "", //创建者,
				pwd: "", //密码,
			},
			rules: {
				company: [
					{required: true, message: '请输入公司', trigger: 'blur'}
				],
				saleEmployee: [
					{required: true, message: '请输入销售雇员', trigger: 'blur'}
				],
        roleId: [
					{required: true, message: '请输入角色', trigger: 'blur'}
				],
				phone: [
					{required: true, message: '请输入手机号', trigger: 'blur'}
				],
				pwd: [
					{required: true, message: '请输入密码', trigger: 'blur'}
				],
			},
			total: 0,
			currentPage4: 1,
			tableData: [],
			searchForm: {
				saleEmployee: '',
				deptId: '',
				partitionId: '',
				groupId: '',
				reportTo: '',
				roleId: '',
			},
			deptDropDownBoxList: [],
			partitionDropDownBoxList: [],
			groupByPartitionIdDropDownList: [],
			roleDropDownBoxList: [],
			user: {}
		}
	},
	mounted() {
		let user = JSON.parse(sessionStorage.getItem('user'));
		this.user = user;
		// "id": id,
		// "company": 公司,
		// "deptId": 部门id,
		// "deptName": 部门名称,
		// "partitionId": 分区id,
		// "partitionName": 分区名称,
		// "groupId": 小组id,
		// "groupName": 小组名称,
		// "saleEmployee": 销售雇员,
		// "reportTo": 直属上级,
		// "rank": 职级,
		// "phone": 手机号,
		// "createBy": 创建者,
		// "createTime": 创建时间,
		// "updateBy": 更新者,
		// "updateTime": 更新时间
		this.customers_group();
		this.deptDropDownBox();
		this.roleDropDownBox();
	},
	methods: {
    listCheck(item){
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', item.id);
      data.append('checked', item.checked);
      this.axios
          .post(this.$urlHttp + `/salesEmployee/checkedIsShow`, data)
          .then((response) => {
            console.log('salesEmployee/checkedIsShow', response.data);
            loading.close();
            if (response.data.code === 200) {

            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
		handleUpdate(file, fileList) {
			console.log(file, fileList)
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			if (file.response) {
				loading.close();
				this.$message(file.response.msg);
				this.customers_group();
			}
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		handleExceed(files, fileList) {
			this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		},
		beforeRemove(file) {
			return this.$confirm(`确定移除 ${file.name}？`);
		},
		exportExcelSampleList() {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			let {saleEmployee,deptId,partitionId,groupId,reportTo,roleId} = this.searchForm;
			window.location.href = this.$urlHttp + `/salesEmployee/exportExcel?saleEmployee=${saleEmployee}&deptId=${deptId}&partitionId=${partitionId}&groupId=${groupId}&reportTo=${reportTo}&roleId=${roleId}`
			loading.close();
		},
		roleDropDownBox() {
			this.axios
				.get(this.$urlHttp + `/dropDownBox/roleDropDownBox`, {})
				.then((response) => {
					console.log('dropDownBox/roleDropDownBox', response.data);
					if (response.data.code === 200) {
						this.roleDropDownBoxList = response.data.roleList;
					} else {
						this.$message(response.data.message);
					}
				})
				.catch((error) => console.log(error, "error"));
		},
		addOfEdit(type, data) {
			this.tanStatus = type;
			let user = JSON.parse(sessionStorage.getItem('user'));
			if (type) {
				this.ruleForm = {
					id: 0,
					company: "",
					deptId: "",
					partitionId: "",
					groupId: "",
					saleEmployee: "",
					reportTo: "",
					rank: "",
					phone: "",
					createBy: "",
					pwd: "",
				}
			} else {
				this.ruleForm.id = data.id;
				this.ruleForm.company = data.company;
				this.ruleForm.deptId = data.deptId || '';
				this.ruleForm.partitionId = data.partitionId || '';
				this.ruleForm.groupId = data.groupId || '';
				this.ruleForm.saleEmployee = data.saleEmployee;
				this.ruleForm.reportTo = data.reportTo;
				this.ruleForm.rank = data.rank;
				this.ruleForm.roleId = data.roleId;
				this.ruleForm.phone = data.phone;
				this.ruleForm.updateBy = user.userName;
				this.ruleForm.pwd = user.pwd;
				if (data.deptId) {
					this.partitionDropDownBox(data.deptId);
				}
				if (data.partitionId) {
					this.groupByPartitionIdDropDown(data.partitionId);
				}
			}
			this.dialogFormVisible = true;
		},
		customers_group() {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			let data = new FormData();
			data.append('pageNo', this.currentPage4);
			data.append('pageSize', 20);
			data.append('saleEmployee', this.searchForm.saleEmployee);
			data.append('deptId', this.searchForm.deptId);
			data.append('partitionId', this.searchForm.partitionId);
			data.append('groupId', this.searchForm.groupId);
			data.append('reportTo', this.searchForm.reportTo);
			data.append('roleId', this.searchForm.roleId);
			this.axios
				.post(this.$urlHttp + `/salesEmployee/list`, data)
				.then((response) => {
					console.log('salesEmployee/list', response.data);
					loading.close();
					if (response.data.code === 200) {
						this.tableData = response.data.data;
						this.total = response.data.total;
						this.current_page = response.data.current_page
					} else {
						this.$message(response.data.message);
					}
				})
				.catch((error) => console.log(error, "error"));
		},
		deptDropDownBox() {
			this.axios
				.get(this.$urlHttp + `/dropDownBox/deptDropDownBox`, {})
				.then((response) => {
					console.log('dropDownBox/deptDropDownBox', response.data);
					if (response.data.code === 200) {
						this.deptDropDownBoxList = response.data.deptList;
					} else {
						this.$message(response.data.message);
					}
				})
				.catch((error) => console.log(error, "error"));
		},
		deptChange(e, type) {
			console.log(e, type);
			if (type) {
				this.ruleForm.partitionId = "";
				this.ruleForm.groupId = "";
			} else {
				this.searchForm.partitionId = "";
				this.searchForm.groupId = "";
			}
			this.partitionDropDownBoxList = [];
			this.groupByPartitionIdDropDownList = [];
			this.partitionDropDownBox(e);
		},
		fenquChange(e, type) {
			console.log(e, type);
			if (type) {
				this.ruleForm.groupId = "";
			} else {
				this.searchForm.groupId = "";
			}
			this.groupByPartitionIdDropDownList = [];
			this.groupByPartitionIdDropDown(e);
		},
		partitionDropDownBox(deptId) {
			this.axios
				.get(this.$urlHttp + `/dropDownBox/partitionDropDownBox?deptId=${deptId}`, {})
				.then((response) => {
					console.log('dropDownBox/partitionDropDownBox', response.data);
					if (response.data.code === 200) {
						this.partitionDropDownBoxList = response.data.partitionList;
					} else {
						this.$message(response.data.message);
					}
				})
				.catch((error) => console.log(error, "error"));
		},
		groupByPartitionIdDropDown(partitionId) {
			this.axios
				.get(this.$urlHttp + `/dropDownBox/groupByPartitionIdDropDown?partitionId=${partitionId}`, {})
				.then((response) => {
					console.log('dropDownBox/groupByPartitionIdDropDown', response.data);
					if (response.data.code === 200) {
						this.groupByPartitionIdDropDownList = response.data.groupList;
					} else {
						this.$message(response.data.message);
					}
				})
				.catch((error) => console.log(error, "error"));
		},
		resetPwd(id) {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			let data = new FormData();
			data.append('id', id);
			this.axios
				.post(this.$urlHttp + `/sysUser/resetPwd`, data)
				.then((response) => {
					loading.close();
					console.log('sysUser/resetPwd', response.data);
					this.$message(response.data.message);
					if (response.data.code === 200) {
						this.customers_group();
					}
				})
				.catch((error) => console.log(error, "error"));
		},
		customers_groupAdd() {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			let user = JSON.parse(sessionStorage.getItem('user'));
			let data = new FormData();
			data.append('company', this.ruleForm.company);
			data.append('deptId', this.ruleForm.deptId);
			data.append('partitionId', this.ruleForm.partitionId);
			data.append('groupId', this.ruleForm.groupId);
			data.append('saleEmployee', this.ruleForm.saleEmployee);
			data.append('reportTo', this.ruleForm.reportTo);
			data.append('roleId', this.ruleForm.roleId);
			data.append('phone', this.ruleForm.phone);
			data.append('createBy', user.userName);
			data.append('pwd', user.pwd);
			this.axios
				.post(this.$urlHttp + `/salesEmployee/add`, data)
				.then((response) => {
					loading.close();
					console.log('salesEmployee/add', response.data);
					if (response.data.code === 200) {
						this.dialogFormVisible = false;
						this.customers_group();
					} else {
						this.$message(response.data.message);
					}
				})
				.catch((error) => console.log(error, "error"));
		},
		customers_groupEdit() {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			let user = JSON.parse(sessionStorage.getItem('user'));
			let data = new FormData();
			data.append('id', this.ruleForm.id);
			data.append('company', this.ruleForm.company);
			data.append('deptId', this.ruleForm.deptId);
			data.append('partitionId', this.ruleForm.partitionId);
			data.append('groupId', this.ruleForm.groupId);
			data.append('saleEmployee', this.ruleForm.saleEmployee);
			data.append('reportTo', this.ruleForm.reportTo);
			data.append('roleId', this.ruleForm.roleId);
			data.append('phone', this.ruleForm.phone);
			data.append('updateBy', user.userName);
			data.append('pwd', '');
			this.axios
				.post(this.$urlHttp + `/salesEmployee/edit`, data)
				.then((response) => {
					loading.close();
					console.log('salesEmployee/edit', response.data);
					if (response.data.code === 200) {
						this.dialogFormVisible = false;
						this.customers_group();
					} else {
						this.$message(response.data.message);
					}
				})
				.catch((error) => console.log(error, "error"));
		},
		customers_groupDel(id) {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			this.axios
				.delete(this.$urlHttp + `/salesEmployee/del?id=${id}`)
				.then((response) => {
					loading.close();
					console.log('salesEmployee/del', response.data);
					this.$message(response.data.message);
					if (response.data.code === 200) {
						this.customers_group();
					}
				})
				.catch((error) => console.log(error, "error"));
		},
    customers_groupTing(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/salesEmployee/deactivate`, data)
          .then((response) => {
            loading.close();
            console.log('salesEmployee/deactivate', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupChong(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/salesEmployee/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('salesEmployee/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
		addSubmit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.tanStatus) {
						this.customers_groupAdd();
					} else {
						this.customers_groupEdit();
					}
					// this.submit();
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm(formName) {
			console.log(123, formName)
			this.$refs[formName].resetFields();
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.currentPage4 = val;
			this.customers_group();
		},
	},
}
</script>

<style scoped>
.content >>> .el-dialog__title {
	color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
	color: #FFFFFF;
}

.content >>> .el-dialog__header {
	background-color: #4696C8;
}

.ButtonDao {
	background-color: #5BC0DE;
	border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
	color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
	background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
	background: #F3FAFF;
}

.tableDiv_div {
	flex: 1;
	overflow: hidden;
	overflow-y: auto;
	display: flex;
}

.tableDiv {
	overflow: hidden;
	flex: 1;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}

.ButtonDao_div {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
}

.listTitle_div {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.listTitle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.list {
	flex: 1;
	display: flex;
	flex-direction: column;
	border: 1px solid #D0D0D0;
	border-radius: 6px;
	background-color: #FFFFFF;
	padding: 15px 10px;
	box-sizing: border-box;
}

.content {
	height: 100%;
	padding: 26px 37px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}
</style>
