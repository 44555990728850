<template>
  <div class="content">
    <img class="home7" src="../assets/home_a(7).png" alt="">
    <img class="home6" src="../assets/home_a(6).png" alt="">
    <img class="home4" src="../assets/home_a(4).png" alt="">
    <img class="home3" src="../assets/home_a(3).png" alt="">
    <img class="home5" src="../assets/home_a(5).png" alt="">
    <img class="home1" src="../assets/home_a(1).png" alt="">
    <div class="divs">
      <span class="divs_span">系统登录界面</span>
      <el-form :model="formList" :rules="rules" ref="formList" class="form">
        <el-form-item prop="name">
          <div style="margin-top: 15%" class="divs_div">
            <span class="divs_div_span">用户名</span>
            <div class="divs_div_div">
              <el-input v-model="formList.name"></el-input>
              <img src="../assets/home_a(2).png" alt="">
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <div class="divs_div">
            <span class="divs_div_span">密码</span>
            <div class="divs_div_div">
              <el-input v-model="formList.password" show-password></el-input>
              <img style="width: 40px" src="../assets/home_a(9).png" alt="">
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <span @click="submitForm('formList')" class="submit">登录</span>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import {httpList} from '@/components/request'

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      formList: {
        name: '',
        password: '',
        code: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submit() {
      let data = new FormData();
      data.append('phone', this.formList.name);
      data.append('pwd', this.formList.password);
      this.axios
          .post(this.$urlHttp + `/homepage/pcLogin`, data)
          .then((response) => {
            this.$message(response.data.message);
            if (response.data.code === 200) {
              sessionStorage.setItem('user', JSON.stringify(response.data.map.data ? response.data.map.data : {}));
              sessionStorage.setItem('leftNav', JSON.stringify(response.data.map.moduleList ? response.data.map.moduleList : {}));
              sessionStorage.setItem('roleMap', JSON.stringify(response.data.map.roleMap ? response.data.map.roleMap : {}));
              sessionStorage.setItem('salesEmployee', JSON.stringify(response.data.map.data ? response.data.map.data : {}));
              this.urlButtonList();
              this.$router.push({
                name: "YinShunHome",
                params: {},
              });
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    urlButtonList() {
      let list = JSON.parse(sessionStorage.getItem('leftNav'));
      let buttonList = {};
      list.map((value) => {
        if (value.chiLds && value.chiLds.length > 0) {
          value.chiLds.map((value_s) => {
            if (value_s.buttonList && value_s.buttonList.length > 0) {
              value_s.buttonList.map((value_s_s) => {
                buttonList[value_s_s.buttonName] = true;
              })
            }
          })
        }
      });
      sessionStorage.setItem('buttonList', JSON.stringify(buttonList))
      console.log(111, buttonList);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }

}
</script>
<style scoped>
.submit {
  margin-top: 20px;
  display: block;
  width: 140px;
  height: 40px;
  background-image: url("../assets/home_a(8).png");
  background-size: 100% 100%;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #FFFFFF;
}
.divs_div_div img {
  width: 30px;
  height: 30px;
}

.divs_div_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divs_div {
  border-bottom: 1px solid #CECECE;
}

.divs >>> .el-input__inner {
  color: #0358DD;
  border: none;
  padding: 0;
}

.divs_span {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}

.divs {
  width: 20%;
  position: fixed;
  left: 20%;
  top: 30%;
  z-index: 4;
}

.home1 {
  width: 15.78%;
  position: fixed;
  top: 35%;
  right: 20%;
  z-index: 3;
}

.home5 {
  width: 18.65%;
  position: fixed;
  top: 30%;
  right: 20%;
  z-index: 3;
}

.home3 {
  width: 13.54%;
  position: fixed;
  top: 18%;
  right: 20%;
  z-index: 3;
}

.home4 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.home7 {
  width: 100%;
  height: 70%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.home6 {
  width: 30%;
  position: fixed;
  bottom: -25%;
  right: -10%;
  z-index: 1;
}

.content {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}
</style>
