<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">客户分析</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="deliveryYearsInt" label="历年月" label-width="80px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.deliveryYearsInt"
                  value-format="yyyy/MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="deptId" label="部门" label-width="80px">
              <el-select @change="deptChange" multiple filterable collapse-tags style="width: 99%" v-model="searchForm.deptId">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployee" label="销售雇员" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.mySalesEmployee">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="flag" label="统计选项" label-width="80px">
              <el-select filterable collapse-tags style="width: 99%" v-model="searchForm.flag">
                <el-option
                    v-for="(value,key) in statisticsOptionsList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdList" label="分区" label-width="80px">
              <el-select @change="partitionChange" multiple filterable collapse-tags style="width: 99%" v-model="searchForm.partitionIdList">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="industry" label="行业" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.industry">
                <el-option
                    v-for="(value,key) in orderDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="80px">
              <el-select @change="groupChange" multiple filterable collapse-tags style="width: 99%"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="addSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
              <div @click="addOfEdit('')" style="margin-top: 10px" class="select_sou">曲线图</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              style="width: 100%">
            <el-table-column
                prop="name"
                label="活跃度统计"
            >
            </el-table-column>
            <el-table-column
                v-for="(item,key) in tableDataTime"
                :key="key"
                :prop="item"
                :label="item"
            >
              <template slot-scope="scope">
                <span class="titleText" @click="lossFunction(scope.row[item],item)"
                      v-if="scope.row.name === '流失量(连续半年)'">{{ scope.row[item].length }}</span>
                <span class="titleText" @click="customerFunction(item)"
                      v-else-if="scope.row.name === '新增客户'">{{ scope.row[item] }}</span>
                <span v-else-if="scope.row.name === '保有率(半年)' || scope.row.name === '流失率(半年)'">{{ (scope.row[item]*100).toFixed() }}%</span>
                <span v-else>{{ scope.row[item] }}</span>
              </template>
            </el-table-column>
<!--            <el-table-column-->
<!--                label="操作"-->
<!--                width="200"-->
<!--                v-if="tableCao"-->
<!--            >-->
<!--              <template slot-scope="scope">-->
<!--                <el-button-->
<!--                    size="mini"-->
<!--                    type="warning"-->
<!--                    @click="addOfEdit(scope.row.name)">曲线图-->
<!--                </el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="曲线图" :visible.sync="dialogFormVisible">
      <div id="myChart" style="width: 100%;height: 500px"></div>
    </el-dialog>
    <el-dialog title="流失量" :visible.sync="lossVisible">
      <div @click="LiuExportExcel" style="float: right" class="select_sou">导出搜索结果</div>
      <el-table
          id="liushi"
          :data="lossFunctionList"
          style="width: 100%">
        <el-table-column
            prop="customerAbbreviation"
            label="名称"
        >
          <template slot-scope="scope">
            <span>{{scope.row}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="新增客户" :visible.sync="customerVisible">
      <div @click="ZengExportExcel" style="float: right" class="select_sou">导出搜索结果</div>
      <el-table
          id="xinzeng"
          :data="customerFunctionList"
          style="width: 100%">
        <el-table-column
            prop=""
            label="名称"
        >
          <template slot-scope="scope">
            <span>{{scope.row}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderListHuo",
  mixins: [mixins],
  components: {},
  data() {
    return {
      lossVisible: false,
      searchVisible: false,
      customerVisible: false,
      dialogFormVisible: false,
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      tableDataTime: [],
      searchForm: {
        deliveryYearsInt: [],
        flag: 1,
        industry: [],
        mySalesEmployee: [],
        deptId: [],
        partitionIdList: [],
        groupIdList: [],
        productCategoryS: []
      },
      searchs: {
        deliveryYearsInt: [
          {required: true, message: '请选择统计时间', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      selectList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
      echartsList: [],
      echartsName: [],
      lossFunctionList: [],
      groupDropDownList: [],
      customerFunctionList: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      statisticsOptionsList: [],
      partitionDropDownBoxList: [],
      orderDataIndustryDropboxList: [],
      echats_date: '',
      tableCao: true,
    }
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    // this.IndustryFunction();
    // this.salesEmployeeDropDownBox();
    // this.product_categoryFunction();
    this.deptDropDownBox();
    this.statisticsOptions();
    this.orderDataIndustryDropbox();
    // this.partitionDropDownBox();
  },
  methods: {
    echartsTitle() {
      let name = '曲线图';
      if (this.searchForm.flag) {
        let date = this.statisticsOptionsList.find((value)=>{
          return value.dictValue === this.searchForm.flag
        });
        if (date){
          name = date.dictLabel + name
        }
      }
      return name
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
        this.groupDropDown();
        this.salesEmployeeDropDownBox();

    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
        this.salesEmployeeDropDownBox();

    },
    groupDropDown() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptId}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptId.toString()}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionListDropDownBox', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`,{
            deptId: this.searchForm.deptId.toString(),
            partitionId: this.searchForm.partitionIdList.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "客户分析导出.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    LiuExportExcel() {
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#liushi"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "客户活跃度-流失客户.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
      }, 300)
    },
    ZengExportExcel() {
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#xinzeng"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "客户活跃度-新增客户.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
      }, 300)
    },
    lossFunction(date,month) {
      this.lossVisible = true;
      this.lossFunctionList =date;
      return;
      this.lossVisible = true;
      console.log('month',date,month);
      this.lossFunctionList = date;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/order/queryLossCustomers`, {
            month: month,
            industryList: this.searchForm.industry.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployee.toString(),
            deptIdList: this.searchForm.deptId.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/queryLossCustomers', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.lossVisible = true;
              this.lossFunctionList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerFunction(month) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/order/queryNewCustomers`, {
            month: month,
            industryList: this.searchForm.industry.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployee.toString(),
            deptIdList: this.searchForm.deptId.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/queryNewCustomers', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerVisible = true;
              this.customerFunctionList = response.data.list;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    drawLine(list, name) {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'))
      let option = {
        xAxis: {
          type: 'category',
          axisTick: {
              alignWithLabel: true
            },
          data: this.tableDataTime
        },
        legend: {
          data: name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: list,
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(name) {
      if (this.echats_date) {
        this.echats_date.clear();
      }
      this.dialogFormVisible = true;
      let list = [];
      let list_name = [];
      if (name) {
        let arrDatas = this.echartsList.find((value) => {
          return value.name === name
        });
        let arrData = JSON.parse(JSON.stringify(arrDatas));
        if (this.searchForm.flag === 1){
          let arr_ = [];
          arrData.data.map((value,key)=>{
            arr_.push(value.length)
          });
          arrData.data = arr_;
        }
        list = [arrData];
        list_name = [name];
      } else {
        list = JSON.parse(JSON.stringify(this.echartsList));
        list_name = this.echartsName;

        if (this.searchForm.flag === 1){
          let arr_ = [];
          list[0].data.map((value,key)=>{
            arr_.push(value.length)
          });
          list[0].data = arr_;
        }

        console.log(1, list, list_name);
      }
      setTimeout(() => {
        this.drawLine(list, list_name);
      }, 300)
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let deliveryYearsInt = this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/order/orderActivityAnalysis`, {
            calendarMonthIntStart: deliveryYearsInt[0],
            calendarMonthIntEnd: deliveryYearsInt[1],
            industryList: this.searchForm.industry.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployee.toString(),
            deptIdList: this.searchForm.deptId.toString(),
            flag: this.searchForm.flag.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
            groupIdList: this.searchForm.groupIdList.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/orderActivityAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {
              let arr = [];
              let arr_echarts = [];
              let arrTime = [];
              let arrName = [];
              let mapResultObject = response.data.mapResult;
              let mapResult = Object.keys(response.data.mapResult);
              if (mapResult.length > 0) {
                mapResult.map((value) => {
                  let listObject = mapResultObject[value];
                  let list = Object.keys(mapResultObject[value]);
                  console.log(111, value)
                  let obj = {
                    name: value,
                  }
                  arrName.push(value);
                  arr_echarts.push({
                    name: value,
                    type: 'line',
                    data: Object.values(mapResultObject[value]),
                  })
                  arrTime = Object.keys(mapResultObject[value]);
                  if (list.length > 0) {
                    list.map((val) => {
                      obj[val] = listObject[val];
                    })
                  }
                  arr.push(obj);
                })
              }
              console.log('arr', arr, arrTime, arr_echarts);
              this.tableData = arr;
              this.tableDataTime = arrTime;
              this.echartsList = arr_echarts;
              this.echartsName = arrName;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    statisticsOptions() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=statisticsOptions`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=statisticsOptions', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.statisticsOptionsList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.titleText {
  text-decoration: none;
  color: #409EFF;
  cursor: pointer;
}

.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}



.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
