<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">交货数据导入</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <!--          <div @click="addOfEdit(true)" class="select_xin">新增</div>-->
          <el-upload
              v-if="$buttonStatus('交货数据-导入')"
              :show-file-list="false"
              class="upload-demo"
              :data="{createBy:user.userName}"
              :action="`${$urlHttp}/orderShipment/uploadFile`"
              :on-change="handleUpdate"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :on-exceed="handleExceed">
            <div class="select_xin">选择文件</div>
          </el-upload>
          <a :href="`${$urlHttp}/statics/excel/交货数据导入模板.xlsx`" style="text-decoration: none">
            <div class="select_xia">下载数据模板</div>
          </a>
          <div @click="exportExcelSampleList" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchForm" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="deliveryYearsInt" label="交货年月" label-width="100px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.deliveryYearsInt"
                  value-format="yyyy-MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="industry" label="行业" label-width="100px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.industry">
                <el-option
                    v-for="(value,key) in deliveryDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="deptIdList" label="部门" label-width="100px">
              <el-select @change="deptChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.deptIdList">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployeeList" label="销售雇员" label-width="100px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.mySalesEmployeeList">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="actualDeliveryDate" label="实际发货日" label-width="100px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.actualDeliveryDate"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="productCategory" label="产品大类" label-width="100px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.productCategory">
                <el-option
                    v-for="(value,key) in deliveryDataProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdList" label="分区" label-width="100px">
              <el-select @change="partitionChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.partitionIdList">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="orderFirstDeliveryDate" label="首个交货日" label-width="100px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.orderFirstDeliveryDate"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="customerAbbreviationList" label="客户" label-width="100px">
              <el-select filterable multiple collapse-tags style="width: 99%"
                         v-model="searchForm.customerAbbreviationList">
                <el-option
                    v-for="(value,key) in deliveryDataCustomerDropboxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="100px">
              <el-select @change="groupChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="customers_group()" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
              <!--              <el-button @click="customers_group()">确认</el-button>-->
              <!--              <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>-->
            </el-form-item>
            <div style="display: flex">
              <el-popconfirm
                  v-if="$buttonStatus('交货数据-删除')"
                  title="确认批量删除吗？"
                  @confirm="delBatch"
              >
                <div slot="reference" v-if="$buttonStatus('交货数据-删除')" class="select_chong">批量删除</div>
              </el-popconfirm>
              <el-popconfirm
                  v-if="$buttonStatus('交货数据-删除')"
                  title="确认删除搜索结果吗？"
                  @confirm="delBatchSearch"
              >
                <div slot="reference" v-if="$buttonStatus('交货数据-删除')" class="select_chong">删除搜索结果</div>
              </el-popconfirm>
<!--              <div v-if="$buttonStatus('交货数据-删除')" @click="delBatch" class="select_chong">批量删除</div>-->
<!--              <div v-if="$buttonStatus('交货数据-删除')" @click="delBatchSearch" class="select_chong">删除搜索结果</div>-->
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="deliveryYears"
                label="交货年月"
            >
            </el-table-column>
            <el-table-column
                prop="customerCode"
                label="客户代码"
            >
            </el-table-column>
            <el-table-column
                prop="material"
                label="物料"
            >
            </el-table-column>
            <el-table-column
                prop="customerMaterial"
                label="客户物料"
            >
            </el-table-column>
            <el-table-column
                prop="amount"
                label="数量"
            >
            </el-table-column>
            <el-table-column
                prop="deliveryTime"
                label="交货日期"
            >
            </el-table-column>
            <el-table-column
                min-width="100"
                prop="actualDeliveryTime"
                label="实际发货日"
            >
            </el-table-column>
            <el-table-column
                min-width="100"
                prop="orderFirstDeliveryTime"
                label="首个交货日"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
            >
              <template slot-scope="scope">
                <el-button
                    v-if="$buttonStatus('交货数据-编辑')"
                    size="mini"
                    type="warning"
                    @click="addOfEdit(false, scope.row)">编辑
                </el-button>
                <el-popover
                    v-if="$buttonStatus('交货数据-删除')"
                    style="margin-left: 10px"
                    placement="top"
                    width="160"
                    v-model="scope.row.tanshan">
                  <p>确定删除该订单交货吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="scope.row.tanshan = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="customers_groupDel(scope.row.id,scope.row.tanshan)">
                      确定
                    </el-button>
                  </div>
                  <el-button
                      slot="reference"
                      size="mini"
                      type="danger">删除
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            style="margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 100,1000]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="tanStatus?'添加订单出货':'修改订单出货'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="deliveryYears" label="交货年月" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="ruleForm.deliveryYears"
              value-format="yyyy-MM"
              type="month">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="customerCode" label="客户代码" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.customerCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="material" label="物料" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.material" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="customerMaterial" label="客户物料" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.customerMaterial" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="amount" label="数量" label-width="100px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.amount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="deliveryTime" label="交货日期" label-width="100px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.deliveryTime"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="actualDeliveryTime" label="实际发货日" label-width="100px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.actualDeliveryTime"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="orderFirstDeliveryTime" label="首个交货日" label-width="100px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.orderFirstDeliveryTime"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <!--          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";
import {mixins} from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderImport",
  mixins: [mixins],
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      ruleForm: {
        id: "",//id
        deliveryYears: "",//交货年月
        deliveryTime: "",//交货日期
        actualDeliveryTime: "",//实际发货日
        orderFirstDeliveryTime: "",//首个交货日
        customerCode: "",//客户代码
        material: "",//物料
        customerMaterial: "",//客户物料
        amount: "",//数量
        updateBy: "",//更新者
      },
      rules: {
        deliveryYears: [
          {required: true, message: '请选择交货年月', trigger: 'blur'}
        ],
        deliveryTime: [
          {required: true, message: '请选择交货日期', trigger: 'blur'}
        ],
        actualDeliveryTime: [
          {required: true, message: '请选择实际发货日', trigger: 'blur'}
        ],
        orderFirstDeliveryTime: [
          {required: true, message: '请选择首个交货日', trigger: 'blur'}
        ],
        customerCode: [
          {required: true, message: '请输入客户代码', trigger: 'blur'}
        ],
        material: [
          {required: true, message: '请输入物料', trigger: 'blur'}
        ],
        customerMaterial: [
          {required: true, message: '请输入客户物料', trigger: 'blur'}
        ],
        amount: [
          {required: true, message: '请输入数量', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        deliveryYearsInt: [],
        actualDeliveryDate: [],
        orderFirstDeliveryDate: [],
        customerAbbreviationList: [],
        deptIdList: "",
        mySalesEmployeeList: [],
        partitionIdList: "",
        groupIdList: "",
        industry: "",
        productCategory: "",
      },
      IndustryList: [],
      product_categoryList: [],
      selectList: [],
      customerRatingDropDownBoxList: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      partitionDropDownBoxList: [],
      deliveryDataIndustryDropboxList: [],
      deliveryDataProductCategoryDropboxList: [],
      deliveryDataCustomerDropboxList: [],
      groupDropDownList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
    }
  },
  mounted() {
    // "id": id,
    // "deliveryYears": 交货年月,
    // "customerCode": 客户代码,
    // "material": 物料,
    // "customerMaterial": 客户物料,
    // "amount": 数量,
    // "deliveryTime": 交货日期,
    // "actualDeliveryTime": 实际发货日,
    // "orderFirstDeliveryTime": 首个交货日,
    // "createBy": 创建者,
    // "createTime": 创建时间,
    // "updateBy": 更新者,
    // "updateTime":更新时间


    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();

    // this.IndustryFunction();
    // this.product_categoryFunction();
    // this.customerRatingDropDownBox();
    // this.salesEmployeeDropDownBox();
    this.deptDropDownBox();
    this.deliveryDataIndustryDropbox();
    this.deliveryDataProductCategoryDropbox();
    this.deliveryDataCustomerDropbox();

    // this.partitionDropDownBox();
  },
  methods: {
    exportExcelSampleList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let deliveryYearsInt = this.searchForm.deliveryYearsInt && this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      let actualDeliveryDate = this.searchForm.actualDeliveryDate && this.searchForm.actualDeliveryDate.length > 0 ? this.searchForm.actualDeliveryDate : ['', '']
      let orderFirstDeliveryDate = this.searchForm.orderFirstDeliveryDate && this.searchForm.orderFirstDeliveryDate.length > 0 ? this.searchForm.orderFirstDeliveryDate : ['', '']
      window.location.href = this.$urlHttp + `/orderShipment/exportExcel?deliveryYearsStart=${deliveryYearsInt[0]}&deliveryYearsEnd=${deliveryYearsInt[1]}&actualDeliveryDateStart=${actualDeliveryDate[0]}&actualDeliveryDateEnd=${actualDeliveryDate[1]}&orderFirstDeliveryDateStart=${orderFirstDeliveryDate[0]}&orderFirstDeliveryDateEnd=${orderFirstDeliveryDate[1]}&industryList=${this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : ""}&productCategoryList=${this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : ""}&customerAbbreviationList=${this.searchForm.customerAbbreviationList.toString()}&deptIdList=${this.searchForm.deptIdList.toString()}&mySalesEmployeeList=${this.searchForm.mySalesEmployeeList.toString()}&partitionIdList=${this.searchForm.partitionIdList.toString()}`
      loading.close();
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.salesEmployeeDropDownBox();
    },
    groupDropDown() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptIdList}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptIdList.toString()}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, {
            deptId: this.searchForm.deptIdList.toString(),
            partitionId: this.searchForm.partitionIdList.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,//id
          deliveryYears: "",//交货年月
          deliveryTime: "",//交货日期
          actualDeliveryTime: "",//实际发货日
          orderFirstDeliveryTime: "",//首个交货日
          customerCode: "",//客户代码
          material: "",//物料
          customerMaterial: "",//客户物料
          amount: "",//数量
          updateBy: "",//更新者
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.deliveryYears = data.deliveryYears;
        this.ruleForm.deliveryTime = data.deliveryTime;
        this.ruleForm.actualDeliveryTime = data.actualDeliveryTime;
        this.ruleForm.orderFirstDeliveryTime = data.orderFirstDeliveryTime;
        this.ruleForm.customerCode = data.customerCode;
        this.ruleForm.material = data.material;
        this.ruleForm.customerMaterial = data.customerMaterial;
        this.ruleForm.amount = data.amount;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      console.log(this.searchForm);
      let deliveryYearsInt = this.searchForm.deliveryYearsInt && this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      let actualDeliveryDate = this.searchForm.actualDeliveryDate && this.searchForm.actualDeliveryDate.length > 0 ? this.searchForm.actualDeliveryDate : ['', '']
      let orderFirstDeliveryDate = this.searchForm.orderFirstDeliveryDate && this.searchForm.orderFirstDeliveryDate.length > 0 ? this.searchForm.orderFirstDeliveryDate : ['', '']
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', this.pageSize);
      data.append('deliveryYearsStart', deliveryYearsInt[0]);
      data.append('deliveryYearsEnd', deliveryYearsInt[1]);
      data.append('actualDeliveryDateStart', actualDeliveryDate[0]);
      data.append('actualDeliveryDateEnd', actualDeliveryDate[1]);
      data.append('orderFirstDeliveryDateStart', orderFirstDeliveryDate[0]);
      data.append('orderFirstDeliveryDateEnd', orderFirstDeliveryDate[1]);
      data.append('industryList', this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "");
      data.append('productCategoryList', this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "");
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.toString());
      data.append('deptIdList', this.searchForm.deptIdList.toString());
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.toString());
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      data.append('groupIdList', this.searchForm.groupIdList.toString());
      console.log(111, this.searchForm);
      this.axios
          .post(this.$urlHttp + `/orderShipment/list`, data)
          .then((response) => {
            console.log('orderShipment/list', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.tableData = response.data.data;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('customerRating', this.ruleForm.customerRating);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('deliveryYears', this.ruleForm.deliveryYears);
      data.append('deliveryTime', this.ruleForm.deliveryTime);
      data.append('actualDeliveryTime', this.ruleForm.actualDeliveryTime);
      data.append('orderFirstDeliveryTime', this.ruleForm.orderFirstDeliveryTime);
      data.append('customerCode', this.ruleForm.customerCode);
      data.append('material', this.ruleForm.material);
      data.append('customerMaterial', this.ruleForm.customerMaterial);
      data.append('amount', this.ruleForm.amount);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/orderShipment/edit`, data)
          .then((response) => {
            loading.close();
            console.log('orderShipment/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/orderShipment/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('orderShipment/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择订单');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/orderShipment/delBatch?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('orderShipment/delBatch', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatchSearch() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      console.log(this.searchForm);
      let deliveryYearsInt = this.searchForm.deliveryYearsInt && this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      let actualDeliveryDate = this.searchForm.actualDeliveryDate && this.searchForm.actualDeliveryDate.length > 0 ? this.searchForm.actualDeliveryDate : ['', '']
      let orderFirstDeliveryDate = this.searchForm.orderFirstDeliveryDate && this.searchForm.orderFirstDeliveryDate.length > 0 ? this.searchForm.orderFirstDeliveryDate : ['', '']
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', this.pageSize);
      data.append('deliveryYearsStart', deliveryYearsInt[0]);
      data.append('deliveryYearsEnd', deliveryYearsInt[1]);
      data.append('actualDeliveryDateStart', actualDeliveryDate[0]);
      data.append('actualDeliveryDateEnd', actualDeliveryDate[1]);
      data.append('orderFirstDeliveryDateStart', orderFirstDeliveryDate[0]);
      data.append('orderFirstDeliveryDateEnd', orderFirstDeliveryDate[1]);
      data.append('industryList', this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "");
      data.append('productCategoryList', this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "");
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.toString());
      data.append('deptIdList', this.searchForm.deptIdList.toString());
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.toString());
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      data.append('groupIdList', this.searchForm.groupIdList.toString());
      this.axios
          .post(this.$urlHttp + `/orderShipment/deleteSearchResults`, {
            deliveryYearsStart: deliveryYearsInt[0],
            deliveryYearsEnd: deliveryYearsInt[1],
            actualDeliveryDateStart: actualDeliveryDate[0],
            actualDeliveryDateEnd: actualDeliveryDate[1],
            orderFirstDeliveryDateStart: orderFirstDeliveryDate[0],
            orderFirstDeliveryDateEnd: orderFirstDeliveryDate[1],
            industryList: this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "",
            productCategoryList: this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "",
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
          })
          .then((response) => {
            loading.close();
            console.log('orderShipment/deleteSearchResults', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
