<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">有效送样统计数据</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <!--          <div @click="addOfEdit(true)" class="select_xin">新增</div>-->
          <div @click="exportExcel" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchs" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="deliveryYearsInt" label="订单年月" label-width="80px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.deliveryYearsInt"
                  value-format="yyyy/MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="customerAbbreviationList" label="客户" label-width="80px">
              <el-select filterable multiple collapse-tags
                         style="width: 99%" v-model="searchForm.customerAbbreviationList">
                <el-option
                    v-for="(value,key) in sampleOrderCustomerDropboxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="deptId" label="部门" label-width="80px">
              <el-select @change="deptChange" filterable collapse-tags style="width: 99%" v-model="searchForm.deptId">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployee" label="销售雇员" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.mySalesEmployee">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item prop="industry" label="行业" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.industry">
                <el-option
                    v-for="(value,key) in sampleOrderIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="orderReasonList" label="订单原因" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.orderReasonList">
                <el-option
                    v-for="(value,key) in sampleOrderReasonDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partitionIdList" label="分区" label-width="80px">
              <el-select @change="partitionChange" filterable collapse-tags style="width: 99%" v-model="searchForm.partitionIdList">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="material" label="新旧料号" label-width="80px">
              <el-input style="width: 99%" placeholder="请输入" v-model="searchForm.material"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="productCategoryList" label="产品大类" label-width="80px">
              <el-select filterable multiple collapse-tags style="width: 99%" v-model="searchForm.productCategoryList">
                <el-option
                    v-for="(value,key) in sampleOrderProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="flag" label="送样成功" label-width="80px">
              <el-select filterable collapse-tags style="width: 99%" v-model="searchForm.flag">
                <el-option
                    label="请选择"
                    value="">
                </el-option>
                <el-option
                    label="是"
                    value="1">
                </el-option>
                <el-option
                    label="否"
                    value="0">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="80px">
              <el-select @change="groupChange" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="sampleNo" label="样品单号" label-width="80px">
              <el-input style="width: 99%" placeholder="请输入" v-model="searchForm.sampleNo"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="searchSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              height="500"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="calendarMonth"
                min-width="80"
                label="历年/月"
            >
            </el-table-column>
            <el-table-column
                min-width="80"
                prop="customerAbbreviation"
                label="客户"
            >
            </el-table-column>
            <el-table-column
                min-width="160"
                prop="industry"
                label="行业"
            >
            </el-table-column>
            <el-table-column
                min-width="80"
                prop="sampleNo"
                label="样品单号"
            >
            </el-table-column>
            <el-table-column
                min-width="80"
                prop="sampleNum"
                label="送样次数"
            >
              <template slot-scope="scope">
                <span class="titleText" @click="sampleListBySampleNum(scope.row.customerCode,scope.row.material)"
                      v-if="scope.row.sampleNum > 1">{{ scope.row.sampleNum }}</span>
                <span v-else>{{ scope.row.sampleNum }}</span>
              </template>

            </el-table-column>
            <el-table-column
                min-width="120"
                prop="productCategory"
                label="产品大类"
            >
            </el-table-column>
            <el-table-column
                min-width="130"
                prop="material"
                label="物料"
            >
            </el-table-column>
            <el-table-column
                min-width="140"
                prop="samplesSuccess"
                label="送样是否成功"
            >
              <template slot-scope="scope">{{scope.row.samplesSuccess === 0 ? '否' : '是'}}</template>
            </el-table-column>
            <el-table-column
                min-width="80"
                prop="sales"
                label="销售量"
            >
            </el-table-column>
            <el-table-column
                min-width="80"
                prop="salesAmount"
                label="送样金额"
            >
            </el-table-column>
            <el-table-column
                min-width="120"
                prop="orderReason"
                label="订单原因"
            >
            </el-table-column>
            <el-table-column
                min-width="160"
                prop="salesEmployee"
                label="销售雇员"
            >
            </el-table-column>
            <el-table-column
                min-width="160"
                prop="saleSpanMonth"
                label="产生销售跨度（月）"
            >
            </el-table-column>
            <el-table-column
                min-width="100"
                v-for="(item,key) in tableTime"
                :key="key"
                :label="'销售额' + item"
            >
              <template slot-scope="scope">{{ scope.row.sumSalesQuota[item] }}</template>
            </el-table-column>
            <el-table-column
                min-width="100"
                prop="sumQuota"
                label="总计销售额"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="送样次数" :visible.sync="lossVisible">
      <el-table
          id="liushi"
          :data="sampleListBySampleNumList"
          style="width: 100%">
        <el-table-column
            prop="calendarMonth"
            min-width="80"
            label="历年/月"
        >
        </el-table-column>
        <el-table-column
            min-width="80"
            prop="customerAbbreviation"
            label="客户"
        >
        </el-table-column>
        <el-table-column
            min-width="160"
            prop="industry"
            label="行业"
        >
        </el-table-column>
        <el-table-column
            min-width="80"
            prop="sampleNo"
            label="样品单号"
        >
        </el-table-column>
        <el-table-column
            min-width="120"
            prop="productCategory"
            label="产品大类"
        >
        </el-table-column>
        <el-table-column
            min-width="130"
            prop="material"
            label="物料"
        >
        </el-table-column>
        <el-table-column
            min-width="140"
            prop="oldMaterial"
            label="旧物料号码"
        >
        </el-table-column>
        <el-table-column
            min-width="80"
            prop="sales"
            label="销售量"
        >
        </el-table-column>
        <el-table-column
            min-width="80"
            prop="salesAmount"
            label="送样金额"
        >
        </el-table-column>
        <el-table-column
            min-width="120"
            prop="orderReason"
            label="订单原因"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="tanStatus?'添加样品订单导入':'修改样品订单导入'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="calendarMonth" label="历年/月" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="ruleForm.calendarMonth"
              value-format="yyyy-MM"
              type="month">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="customerAbbreviation" label="客户" label-width="100px">
          <el-select filterable style="width: 50%"
                     v-model="ruleForm.customerAbbreviation">
            <el-option
                v-for="(value,key) in customerRatingDropDownBoxList"
                :key="key"
                :label="value.customerAbbreviation"
                :value="value.customerAbbreviation">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="industry" label="行业" label-width="100px">
          <el-select filterable style="width: 50%" v-model="ruleForm.industry">
            <el-option
                v-for="(value,key) in IndustryList"
                :key="key"
                :label="value.dictLabel"
                :value="value.dictLabel">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="sampleNo" label="样品单号" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.sampleNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="productCategory" label="产品大类" label-width="100px">
          <el-select filterable style="width: 50%" v-model="ruleForm.productCategory">
            <el-option
                v-for="(value,key) in product_categoryList"
                :key="key"
                :label="value.dictLabel"
                :value="value.dictLabel">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="material" label="物料" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.material" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="oldMaterial" label="旧物料号码" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.oldMaterial" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="sales" label="销售量" label-width="100px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.sales" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="salesAmount" label="送样金额" label-width="100px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.salesAmount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="orderReason" label="订单原因" label-width="100px">
          <el-select filterable style="width: 50%" v-model="ruleForm.orderReason">
            <el-option
                v-for="(value,key) in orderReasonList"
                :key="key"
                :label="value.dictLabel"
                :value="value.dictLabel">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="mySalesEmployee" label="销售雇员" label-width="100px">
          <el-select filterable style="width: 50%" v-model="ruleForm.mySalesEmployee">
            <el-option
                v-for="(value,key) in salesEmployeeDropDownBoxList"
                :key="key"
                :label="value.saleEmployee"
                :value="value.saleEmployee">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="搜索" :visible.sync="searchVisible">
      <el-form ref="searchForm" :model="searchForm">
        <el-form-item prop="deliveryYearsInt" label="交货年月" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="searchForm.deliveryYearsInt"
              value-format="yyyy-MM"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="actualDeliveryDate" label="实际发货日" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="searchForm.actualDeliveryDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="orderFirstDeliveryDate" label="订单首个交货日" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="searchForm.orderFirstDeliveryDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="industry" label="行业" label-width="100px">
          <el-select filterable style="width: 50%" v-model="searchForm.industry">
            <el-option
                v-for="(value,key) in IndustryList"
                :key="key"
                :label="value.dictLabel"
                :value="value.dictLabel">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="productCategory" label="产品大类" label-width="100px">
          <el-select filterable style="width: 50%" v-model="searchForm.productCategory">
            <el-option
                v-for="(value,key) in product_categoryList"
                :key="key"
                :label="value.dictLabel"
                :value="value.dictLabel">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="customers_group()">确认</el-button>
          <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderImport",
  mixins: [mixins],
  components: {},
  data() {
    return {
      lossVisible: false,
      searchVisible: false,
      dialogFormVisible: false,
      tanStatus: true,
      ruleForm: {
        id: "",
        calendarMonth: "",
        customerAbbreviation: "",
        industry: "",
        sampleNo: "",
        productCategory: "",
        material: "",
        oldMaterial: "",
        sales: "",
        salesAmount: "",
        orderReason: "",
        mySalesEmployee: "",
        updateBy: "",
      },
      rules: {
        calendarMonth: [
          {required: true, message: '请选择历年/月', trigger: 'blur'}
        ],
        customerAbbreviation: [
          {required: true, message: '请选择客户', trigger: 'blur'}
        ],
        industry: [
          {required: true, message: '请选择行业', trigger: 'blur'}
        ],
        sampleNo: [
          {required: true, message: '请输入样品单号', trigger: 'blur'}
        ],
        productCategory: [
          {required: true, message: '请选择产品大类', trigger: 'blur'}
        ],
        material: [
          {required: true, message: '请输入物料', trigger: 'blur'}
        ],
        oldMaterial: [
          {required: true, message: '请输入旧物料号码', trigger: 'blur'}
        ],
        sales: [
          {required: true, message: '请输入销售量', trigger: 'blur'}
        ],
        salesAmount: [
          {required: true, message: '请输入送样金额', trigger: 'blur'}
        ],
        orderReason: [
          {required: true, message: '请选择订单原因', trigger: 'blur'}
        ],
        mySalesEmployee: [
          {required: true, message: '请选择销售雇员', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        deliveryYearsInt: [],
        industry: [],
        mySalesEmployee: [],
        deptId: [],
        groupIdList: [],

        flag: "",
        sampleNo: "",
        material: "",
        customerAbbreviationList: [],
        productCategoryList: [],
        orderReasonList: [],
        partitionIdList: [],
      },
      searchs: {
        deliveryYearsInt: [
          {required: true, message: '请选择统计时间', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      customerRatingDropDownBoxList: [],
      groupDropDownList: [],
      deptDropDownBoxList: [],
      selectList: [],
      orderReasonList: [],
      statisticsOptionsList: [],
      salesEmployeeDropDownBoxList: [],
      partitionDropDownBoxList: [],
      sampleListBySampleNumList: [],
      sampleOrderCustomerDropboxList: [],
      sampleOrderIndustryDropboxList: [],
      sampleOrderProductCategoryDropboxList: [],
      sampleOrderReasonDropboxList: [],
      tableTime: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
    }
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;


    // this.IndustryFunction();
    this.salesEmployeeDropDownBox();
    // this.product_categoryFunction();
    this.deptDropDownBox();
    // this.statisticsOptions();
    this.customerRatingDropDownBox();
    this.orderReason();
    this.sampleOrderCustomerDropbox();
    this.sampleOrderIndustryDropbox();
    this.sampleOrderProductCategoryDropbox();
    this.sampleOrderReasonDropbox();
    // this.partitionDropDownBox();
  },
  methods: {
    sampleOrderReasonDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sampleOrderReasonDropbox`)
          .then((response) => {
            console.log('dropDownBox/sampleOrderReasonDropbox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleOrderReasonDropboxList = response.data.orderReasonList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleOrderProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleOrderProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleOrderIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleOrderIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleOrderCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.sampleOrderCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];

        this.salesEmployeeDropDownBox();
    },
    groupDropDown() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionIdList.toString()}&deptId=${this.searchForm.deptId}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    sampleListBySampleNum(customerCode,material) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerCode', customerCode);
      data.append('material', material);
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleListBySampleNum`, data)
          .then((response) => {
            console.log('sampleDataAnalysis/sampleListBySampleNum', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.lossVisible = true;
              this.sampleListBySampleNumList = response.data.data;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptId.toString()}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "有效送样统计数据导出.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    statisticsOptions() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=statisticsOptions`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=statisticsOptions', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.statisticsOptionsList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderReason() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=orderReason`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=orderReason', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderReasonList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`,{
            deptId: this.searchForm.deptId.toString(),
            partitionId: this.searchForm.partitionIdList.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: "",
          calendarMonth: "",
          customerAbbreviation: "",
          industry: "",
          sampleNo: "",
          productCategory: "",
          material: "",
          oldMaterial: "",
          sales: "",
          salesAmount: "",
          orderReason: "",
          mySalesEmployee: "",
          updateBy: "",
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.calendarMonth = data.calendarMonth;
        this.ruleForm.customerAbbreviation = data.customerAbbreviation;
        this.ruleForm.industry = data.industry;
        this.ruleForm.sampleNo = data.sampleNo;
        this.ruleForm.productCategory = data.productCategory;
        this.ruleForm.material = data.material;
        this.ruleForm.oldMaterial = data.oldMaterial;
        this.ruleForm.sales = data.sales;
        this.ruleForm.salesAmount = data.salesAmount;
        this.ruleForm.orderReason = data.orderReason;
        this.ruleForm.mySalesEmployee = data.mySalesEmployee;
        this.ruleForm.updateBy = data.updateBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let deliveryYearsInt = this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleDataAnalysis`, {
            calendarMonthStart: deliveryYearsInt[0],
            calendarMonthEnd: deliveryYearsInt[1],
            sampleNo: this.searchForm.sampleNo, // 样品单号
            material: this.searchForm.material, // 新/旧物料号
            industryList: this.searchForm.industry.toString(),
            deptIdList: this.searchForm.deptId.toString(),
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(), // 客户
            productCategoryList: this.searchForm.productCategoryList.toString(),// 产品大类
            mySalesEmployeeList: this.searchForm.mySalesEmployee.toString(),
            orderReasonList: this.searchForm.orderReasonList.toString(), // 订单原因
            partitionIdList: this.searchForm.partitionIdList.toString(), // 分区
            groupIdList: this.searchForm.groupIdList.toString(), // 小组
            flag: this.searchForm.flag, // 送样成功
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/sampleDataAnalysis', response.data);
            loading.close();
            if (response.data.code === 200) {
              // sumQuota
              let a = response.data.list.sort((a,b)=>{
                return b.sumQuota - a.sumQuota
              });
              this.tableData = a;
              if (response.data.list.length > 0) {
                this.tableTime = Object.keys(response.data.list[0].sumSalesQuota);
              }
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('customerRating', this.ruleForm.customerRating);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('calendarMonth', this.ruleForm.calendarMonth);
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('industry', this.ruleForm.industry);
      data.append('sampleNo', this.ruleForm.sampleNo);
      data.append('productCategory', this.ruleForm.productCategory);
      data.append('material', this.ruleForm.material);
      data.append('oldMaterial', this.ruleForm.oldMaterial);
      data.append('sales', this.ruleForm.sales);
      data.append('salesAmount', this.ruleForm.salesAmount);
      data.append('orderReason', this.ruleForm.orderReason);
      data.append('mySalesEmployee', this.ruleForm.mySalesEmployee);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/editSampleList`, data)
          .then((response) => {
            loading.close();
            console.log('sampleDataAnalysis/editSampleList', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/sampleDataAnalysis/delSampleList?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('sampleDataAnalysis/delSampleList', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择订单');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/sampleDataAnalysis/delBatchSampleList?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('sampleDataAnalysis/delBatchSampleList', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatchSearch() {
      if (this.tableData.length <= 0) {
        this.$message('请搜索数据');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.tableData.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/sampleDataAnalysis/delBatchSampleList?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('sampleDataAnalysis/delBatchSampleList', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    searchSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.titleText {
  text-decoration: none;
  color: #409EFF;
  cursor: pointer;
}

.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}



.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
