<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">成功送样-TOP100</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchRule" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="month" label="日期" label-width="120px">
              <el-date-picker
                  clearable
                  style="width: 99%"
                  v-model="searchForm.month"
                  value-format="yyyy/MM"
                  type="month"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="industry" label="行业" label-width="120px">
              <el-select clearable multiple :disabled="searchForm.dictType !== '1' &&  searchForm.dictType !== '9' && searchForm.dictType !== '10'" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.industry">
                <el-option
                    v-for="(value,key) in deliveryDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="deptId" label="部门" label-width="120px">
              <el-select clearable :disabled="searchForm.classZu !== '1' && searchForm.dictType !== '11'" filterable collapse-tags style="width: 99%"
                         @change="deptChange"
                         v-model="searchForm.deptId">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mySalesEmployee" label="销售雇员" label-width="120px">
              <el-select clearable :disabled="searchForm.classZu !== '1'" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.mySalesEmployee">
                <el-option
                    v-for="(value,key) in salesEmployeeDropDownBoxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="type" label="统计类型" label-width="120px">
              <el-select clearable style="width: 99%" v-model="searchForm.type" placeholder="请选择">
                <el-option
                    v-for="(value,key) in top100CompaniesInSalesList"
                    :key="key"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="productCategory" label="产品大类" label-width="120px">
              <el-select clearable multiple :disabled="searchForm.dictType !== '4' &&  searchForm.dictType !== '9' && searchForm.dictType !== '10'" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.productCategory">
                <el-option
                    v-for="(value,key) in deliveryDataProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="partition" label="分区" label-width="120px">
              <el-select clearable :disabled="searchForm.classZu !== '1' && searchForm.dictType !== '12'" filterable collapse-tags style="width: 99%"
                         @change="partitionChange"
                         v-model="searchForm.partition">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerRating" label="评级" label-width="120px">
              <el-select clearable :disabled="searchForm.dictType !== '2'" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.customerRating">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="dictType" label="统计方式" label-width="120px">
              <el-select clearable @change="dictTypeChange" style="width: 99%" v-model="searchForm.dictType" placeholder="请选择">
                <el-option
                    label="按全部"
                    value="">
                </el-option>
                <el-option
                    label="按行业"
                    value="1">
                </el-option>
                <el-option
                    label="按评级"
                    value="2">
                </el-option>
                <el-option
                    label="按产品大类"
                    value="4">
                </el-option>
                <el-option
                    label="按客户"
                    value="7">
                </el-option>
                <el-option
                    label="按行业/产品大类"
                    value="9">
                </el-option>
                <el-option
                    label="按产品大类/行业"
                    value="10">
                </el-option>
                <el-option
                    label="按部门"
                    value="11">
                </el-option>
                <el-option
                    label="按分区"
                    value="12">
                </el-option>
                <el-option
                    label="按小组"
                    value="13">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerAbbreviation" label="客户" label-width="120px">
              <el-select clearable :disabled="searchForm.dictType !== '7'" filterable collapse-tags style="width: 99%"
                         v-model="searchForm.customerAbbreviation">
                <el-option
                    v-for="(value,key) in ssCrDropDownBoxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList" label="小组" label-width="120px">
              <el-select clearable filterable collapse-tags style="width: 99%"
                         @change="groupChange"
                         :disabled="searchForm.classZu !== '1' && searchForm.dictType !== '13'"
                         v-model="searchForm.groupIdList">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="classZu" label="是否组合查询" label-width="120px">
              <el-select clearable filterable collapse-tags style="width: 99%"
                         @change="classZuChange"
                         :disabled="(searchForm.dictType !== '1' && searchForm.dictType !== '4' && searchForm.dictType !== '9' && searchForm.dictType !== '10') || user_identity"
                         v-model="searchForm.classZu">
                <el-option
                    label="是"
                    value="1">
                </el-option>
                <el-option
                    label="否"
                    value="0">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="searchSubmit('searchForm')" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
<!--              <div @click="quXianClick()" style="margin-top: 10px;width: 140px;" class="select_sou">送样成交率曲线图</div>-->
            </el-form-item>


          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                prop="customerAbbreviation"
                label="客户名称"
            >
            </el-table-column>
            <el-table-column
                prop="industry"
                label="行业"
            >
            </el-table-column>
            <el-table-column
                prop="productCategory"
                label="产品大类"
            >
            </el-table-column>
            <el-table-column
                prop="material"
                label="物料"
            >
            </el-table-column>
            <el-table-column
                prop="salesEmployee"
                label="销售雇员"
            >
            </el-table-column>
            <el-table-column
                prop="sampleTotal"
                label="样品总金额"
            >
            </el-table-column>
            <el-table-column
                min-width="100"
                v-for="(item,key) in tableTime"
                :key="key"
                :label="'销售额' + item"
            >
              <template slot-scope="scope">{{ scope.row.sumSalesQuota[item] }}</template>
            </el-table-column>
            <el-table-column
                prop="sumQuota"
                label="总计销售额"
            >
            </el-table-column>
          </el-table>
        </div>
        <!--        <el-pagination-->
        <!--            background-->
        <!--            style="margin-top: 30px"-->
        <!--            @size-change="handleSizeChange"-->
        <!--            @current-change="handleCurrentChange"-->
        <!--            :current-page="currentPage4"-->
        <!--            :page-sizes="[10, 20, 30, 100,1000]"-->
        <!--            :page-size="pageSize"-->
        <!--            layout="total, sizes, prev, pager, next, jumper"-->
        <!--            :total="total">-->
        <!--        </el-pagination>-->
      </div>
    </div>
    <el-dialog title="曲线图" :visible.sync="dialogFormVisibleAA">
      <div id="myChart" style="width: 100%;height: 500px"></div>
    </el-dialog>
    <el-dialog :title="tanStatus?'添加订单出货':'修改订单出货'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="deliveryYears" label="交货年月" label-width="120px">
          <el-date-picker
              style="width: 50%"
              v-model="ruleForm.deliveryYears"
              value-format="yyyy-MM"
              type="month">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="industry" label="行业" label-width="120px">
          <el-select filterable style="width: 50%" v-model="ruleForm.industry">
            <el-option
                v-for="(value,key) in IndustryList"
                :key="key"
                :label="value"
                :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="productCategory" label="产品大类" label-width="120px">
          <el-select filterable style="width: 50%" v-model="ruleForm.productCategory">
            <el-option
                v-for="(value,key) in product_categoryList"
                :key="key"
                :label="value"
                :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="deliveryDate" label="交货日期" label-width="120px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.deliveryDate"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="actualDeliveryDate" label="实际发货日" label-width="120px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.actualDeliveryDate"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="orderFirstDeliveryDate" label="订单首个交货日" label-width="120px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.orderFirstDeliveryDate"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderImport",
  mixins: [mixins],
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisibleAA: false,
      tanStatus: true,
      ruleForm: {
        id: '',
        deliveryYears: '',
        industry: '',
        productCategory: '',
        deliveryDate: '',
        actualDeliveryDate: '',
        orderFirstDeliveryDate: '',
        updateBy: '',
      },
      rules: {
        deliveryYears: [
          {required: true, message: '请选择交货年月', trigger: 'blur'}
        ],
        industry: [
          {required: true, message: '请选择行业', trigger: 'blur'}
        ],
        productCategory: [
          {required: true, message: '请选择产品大类', trigger: 'blur'}
        ],
        deliveryDate: [
          {required: true, message: '请选择交货日期', trigger: 'blur'}
        ],
        actualDeliveryDate: [
          {required: true, message: '请选择实际发货日', trigger: 'blur'}
        ],
        orderFirstDeliveryDate: [
          {required: true, message: '请选择订单首个交货日', trigger: 'blur'}
        ],
        updateBy: [
          {required: true, message: '请输入更新者', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        month: "",
        type: "",
        industry: "",
        classZu: "0",
        productCategory: "",
        deptId: "",
        partition: "",
        mySalesEmployee: "",
        customerRating: "",
        customerAbbreviation: "",
        groupIdList: "",
        dictType: "",
      },
      searchRule: {
        month: [
          {required: true, message: '请选择日期', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择统计类型', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      groupDropDownList: [],
      selectList: [],
      customerRatingDropDownBoxList: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      partitionDropDownBoxList: [],
      deliveryDataIndustryDropboxList: [],
      deliveryDataProductCategoryDropboxList: [],
      deliveryDataCustomerDropboxList: [],
      ssCrDropDownBoxList: [],
      top100CompaniesInSalesList: [],
      ratingFunctionList: [],
      tableTime: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
    }
  },
  mounted() {
// “customerAbbreviation”: 客户名称,
// “industry”: 行业,
// “productCategory”: 产品大类,
// “material”: 物料,
// “mySalesEmployee”: 销售雇员,
// “sampleTotal”: 样品总金额,
// “sumQuota”: 总计销售额

    // “sumSalesQuota”: {每年总销货额集合
// “2020”: 52000.0,
// “2021”: 0.0
//     },
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    this.IndustryFunction();
    this.product_categoryFunction();
    this.customerRatingDropDownBox();
    this.salesEmployeeDropDownBox();
    this.deptDropDownBox();
    this.deliveryDataIndustryDropbox();
    this.deliveryDataProductCategoryDropbox();
    // this.deliveryDataCustomerDropbox();
    // this.partitionDropDownBox();
    this.ssCrDropDownBox();

    this.top100CompaniesInSales();
    this.ratingFunction();
  },
  methods: {
    classZuChange(e) {
      console.log(111, e);
      if (e === '0') {
        this.$set(this.searchForm, 'deptId', []);
        this.$set(this.searchForm, 'partition', []);
        this.$set(this.searchForm, 'groupIdList', []);
        this.$set(this.searchForm, 'mySalesEmployee', []);
      }
    },
    groupDropDown() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partition.toString()}&deptId=${this.searchForm.deptId}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "成功送样-TOP100导出.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    drawLine(list, name, time) {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'));
      let option = {}
      if (this.searchForm.flag === 1) {
        option = {
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: time
          },
          legend: {
            data: name
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: list,
        };
      } else {
        option = {
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: time
          },
          legend: {
            data: name
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: list,
        };
      }

      console.log(999, option)

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    proportionOfInputCategories() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = this.searchForm;

      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/sampleCloseRate`, {
            month: date.month,
            type: date.type.toString(),
            industry: date.industry,
            productCategory: date.productCategory,
            deptId: date.deptId,
            mySalesEmployee: date.mySalesEmployee,
            customerRating: date.customerRating,
            customerAbbreviation: date.customerAbbreviation,
          })
          .then((response) => {
            console.log('sampleDataAnalysis/sampleCloseRate', response.data.mapResult);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let name = [];
              let arr = [];
              let time = [];
              let nameArr = {
                1: '按行业',
                2: '按评级',
                3: '按部门',
                4: '按产品大类',
                5: '按销售雇员',
                6: '按分区',
                7: '按客户',
                8: '按全部',
              }
              if (Object.keys(date).length > 0) {
                arr.push({
                  name: nameArr[this.searchForm.dictType],
                  type: 'line',// bar柱状图 pie饼图 line折线图
                  data: Object.values(date),
                });
                time = Object.keys(date).sort();
                name.push(nameArr[this.searchForm.dictType]);

                // Object.keys(date).map((value, key) => {
                //   arr.push({
                //     name: value,
                //     type: 'line',// bar柱状图 pie饼图 line折线图
                //     data: Object.keys(date[value]).sort().map((val)=>{
                //       return date[value][val]
                //     }),
                //   });
                //   time = Object.keys(date[value]).sort();
                //   name.push(value);
                //   // Object.keys(date[value]).map((val)=>{
                //   //
                //   // })
                //   console.log(111, value);
                //
                // })
                // time = time.sort();
              }
              if (this.echats_date) {
                this.echats_date.clear();
              }
              this.dialogFormVisibleAA = true;
              setTimeout(() => {
                this.drawLine(arr, name, time);
              }, 300)
              console.log(arr, name, time);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    quXianClick() {
      this.$refs['searchForm'].validate((valid) => {
        if (valid) {
          console.log(this.searchForm.dictType,111)
          if (this.searchForm.dictType && this.searchForm.dictType !== '6') {
            if (this.searchForm.dictType !== '8'){
              let date = {
                1: 'industry',
                2: 'customerRating',
                3: 'deptId',
                4: 'productCategory',
                5: 'mySalesEmployee',
                6: 'partition',
                7: 'customerAbbreviation',
              }

              if (!this.searchForm[date[this.searchForm.dictType]]){
                this.$message('所选统计方式数量不为1');
                return;
              }
            }

            this.proportionOfInputCategories();
          } else if (this.searchForm.dictType === '6') {
            this.$message('曲线图不支持分区');
          } else {
            this.$message('请选择统计方式');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    ssCrDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ssCrDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    dictTypeChange() {
      this.searchForm.classZu = "0";
      this.searchForm.groupIdList = "";
      this.searchForm.industry = "";
      this.searchForm.productCategory = "";
      this.searchForm.deptId = "";
      this.searchForm.partition = "";
      this.searchForm.mySalesEmployee = "";
      this.searchForm.customerRating = "";
    },
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/rateDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/rateDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = response.data.rateList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    top100CompaniesInSales() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=top100CompaniesInSales`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=top100CompaniesInSales', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.top100CompaniesInSalesList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptChange() {
      this.searchForm.partition = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployee = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
      }
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
      }
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.salesEmployeeDropDownBox();
      }
    },
    partitionDropDownBox() {
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptId.toString()}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, {
            deptId: this.searchForm.deptId.toString(),
            partitionId: this.searchForm.partition.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deliveryDataCustomerDropbox`)
          .then((response) => {
            console.log('dropDownBox/deliveryDataCustomerDropbox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataCustomerDropboxList = response.data.customerList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,
          deliveryYears: "",
          industry: "",
          productCategory: "",
          deliveryDate: "",
          actualDeliveryDate: "",
          orderFirstDeliveryDate: "",
          updateBy: "",
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.deliveryYears = data.deliveryYears;
        this.ruleForm.industry = data.industry;
        this.ruleForm.productCategory = data.productCategory;
        this.ruleForm.deliveryDate = data.deliveryTime;
        this.ruleForm.actualDeliveryDate = data.actualDeliveryTime;
        this.ruleForm.orderFirstDeliveryDate = data.orderFirstDeliveryTime;
        this.ruleForm.updateBy = data.updateBy;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let date = this.searchForm;

      let user = {};

      if (this.searchForm.classZu === '1') {
        user = {
          deptId: date.deptId,
          partitionId: date.partition,
          groupId: date.groupIdList,
          mySalesEmployee: date.mySalesEmployee,
        }
      } else {
        user = {
          deptId:this.user_date.deptId,
          partitionId:this.user_date.partitionId,
          groupId:this.user_date.groupId,
          mySalesEmployee:this.user_date.saleEmployee,
        }
      }

      this.axios
          .post(this.$urlHttp + `/sampleDataAnalysis/top100CompaniesInSales`, {
            month: date.month,
            type: date.type.toString(),
            industryS: date.industry.length > 0 ? date.industry.toString() : '',
            productCategoryS: date.productCategory.length > 0 ? date.productCategory.toString() : '',
            customerRating: date.customerRating,
            customerAbbreviation: date.customerAbbreviation,
            combination: this.searchForm.classZu,
            roleName:this.user_date.roleName,
            ...user
          })
          .then((response) => {
            console.log('sampleDataAnalysis/top100CompaniesInSales', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.list;
              let arr = date.sort((value,val)=>{
                return val.sumQuota - value.sumQuota
              });
              this.tableData = arr;
              console.log(11,arr);
              if (response.data.list.length > 0) {
                this.tableTime = Object.keys(response.data.list[0].sumSalesQuota);
              }
              // this.total = response.data.total;
              // this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sampleOrderIndustryDropbox`)
          .then((response) => {
            console.log('dropDownBox/sampleOrderIndustryDropbox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('customerRating', this.ruleForm.customerRating);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('deliveryYears', this.ruleForm.deliveryYears);
      data.append('industry', this.ruleForm.industry);
      data.append('productCategory', this.ruleForm.productCategory);
      data.append('deliveryTime', this.ruleForm.deliveryDate);
      data.append('actualDeliveryTime', this.ruleForm.actualDeliveryDate);
      data.append('orderFirstDeliveryTime', this.ruleForm.orderFirstDeliveryDate);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/orderShipment/edit`, data)
          .then((response) => {
            loading.close();
            console.log('orderShipment/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/orderShipment/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('orderShipment/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择订单');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/orderShipment/delBatch?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('orderShipment/delBatch', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatchSearch() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      let deliveryYearsInt = this.searchForm.deliveryYearsInt && this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      let actualDeliveryDate = this.searchForm.actualDeliveryDate && this.searchForm.actualDeliveryDate.length > 0 ? this.searchForm.actualDeliveryDate : ['', '']
      let orderFirstDeliveryDate = this.searchForm.orderFirstDeliveryDate && this.searchForm.orderFirstDeliveryDate.length > 0 ? this.searchForm.orderFirstDeliveryDate : ['', '']
      data.append('deliveryYearsStart', deliveryYearsInt[0]);
      data.append('deliveryYearsEnd', deliveryYearsInt[1]);
      data.append('actualDeliveryDateStart', actualDeliveryDate[0]);
      data.append('actualDeliveryDateEnd', actualDeliveryDate[1]);
      data.append('orderFirstDeliveryDateStart', orderFirstDeliveryDate[0]);
      data.append('orderFirstDeliveryDateEnd', orderFirstDeliveryDate[1]);
      data.append('industryList', this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "");
      data.append('productCategoryList', this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "");
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.toString());
      data.append('deptIdList', this.searchForm.deptIdList.toString());
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.toString());
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      this.axios
          .post(this.$urlHttp + `/orderShipment/deleteSearchResults`, {
            deliveryYearsStart: deliveryYearsInt[0],
            deliveryYearsEnd: deliveryYearsInt[1],
            actualDeliveryDateStart: actualDeliveryDate[0],
            actualDeliveryDateEnd: actualDeliveryDate[1],
            orderFirstDeliveryDateStart: orderFirstDeliveryDate[0],
            orderFirstDeliveryDateEnd: orderFirstDeliveryDate[1],
            industryList: this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "",
            productCategoryList: this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "",
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
          })
          .then((response) => {
            loading.close();
            console.log('orderShipment/deleteSearchResults', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    searchSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.customers_group();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
