<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D;margin-right: 100px">销售额来源</span>
<!--          <span style="color: #3D3D3D">当前月度:{{queryTheLatestMonthList}}月</span>-->
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :model="searchForm" :rules="searchs">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="calendarMonthInt" label="历年" label-width="120px">
              <el-date-picker
                  style="width: 99%"
                  v-model="searchForm.calendarMonthInt"
                  value-format="yyyy/MM"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客户" label-width="120px" prop="customerAbbreviation">
              <el-select v-model="searchForm.customerAbbreviation" :disabled="searchForm.flag !== 4" collapse-tags filterable
                         multiple style="width: 99%">
                <el-option
                    v-for="(value,key) in orderDataCustomerDropboxList"
                    :key="key"
                    :label="value.customerAbbreviation"
                    :value="value.customerAbbreviation">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="评级" label-width="120px" prop="customerRatingS">
              <el-select v-model="searchForm.customerRatingS" :disabled="searchForm.flag !== 5" collapse-tags filterable multiple
                         style="width: 99%">
                <el-option
                    v-for="(value,key) in ratingFunctionList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小组" label-width="120px" prop="groupIdList">
              <el-select v-model="searchForm.groupIdList" :disabled="searchForm.classZu !== '1' && searchForm.flag !== 10" collapse-tags filterable multiple
                         style="width: 99%"
                         @change="groupChange">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item label="统计类型" label-width="120px" prop="flagFen">
              <el-select v-model="searchForm.flagFen" collapse-tags filterable
                         @change="flagFenChange"
                         style="width: 99%">
                <el-option
                    label="原有业务销售额"
                    value="1">
                </el-option>
                <el-option
                    label="新业务销售额"
                    value="2">
                </el-option>
                <el-option
                    label="原有业务同比"
                    value="3">
                </el-option>
                <el-option
                    label="新业务占比"
                    value="4">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="行业" label-width="120px" prop="industryS">
              <el-select v-model="searchForm.industryS" :disabled="searchForm.flag !== 2 && searchForm.flag !== 6 && searchForm.flag !== 7" collapse-tags filterable multiple
                         style="width: 99%">
                <el-option
                    v-for="(value,key) in orderDataIndustryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门" label-width="120px" prop="deptIdS">
              <el-select v-model="searchForm.deptIdS" :disabled="searchForm.classZu !== '1' && searchForm.flag !== 8" collapse-tags filterable multiple
                         style="width: 99%" @change="deptChange">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销售雇员" label-width="120px" prop="mySalesEmployeeS">
              <el-select v-model="searchForm.mySalesEmployeeS" :disabled="searchForm.classZu !== '1' && searchForm.flag !== 11" collapse-tags filterable multiple
                         style="width: 99%">
                <el-option
                    v-for="(value,key) in orderDataSalesEmployeeDropboxList"
                    :key="key"
                    :label="value.saleEmployee"
                    :value="value.saleEmployee">
                </el-option>
              </el-select>
            </el-form-item>


          </el-col>
          <el-col :span="6">
            <el-form-item label="统计方式" label-width="120px" prop="flag">
              <el-select v-model="searchForm.flag" filterable style="width: 99%"
                         @change="selectChange">
                <el-option
                    v-for="(value,key) in salesIndexStatisticsModeList"
                    :key="key"
                    :disabled="typeStatus(value.dictValue)"
                    :label="value.dictLabel"
                    :value="value.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品大类" label-width="120px" prop="productCategoryS">
              <el-select v-model="searchForm.productCategoryS" :disabled="searchForm.flag !== 3  && searchForm.flag !== 6 && searchForm.flag !== 7 " collapse-tags filterable multiple
                         style="width: 99%">
                <el-option
                    v-for="(value,key) in orderDataProductCategoryDropboxList"
                    :key="key"
                    :label="value"
                    :value="value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分区" label-width="120px" prop="partitionS">
              <el-select v-model="searchForm.partitionS" :disabled="searchForm.classZu !== '1' && searchForm.flag !== 9" filterable multiple
                         style="width: 99%" @change="partitionChange">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否组合查询" label-width="120px" prop="classZu">
              <el-select v-model="searchForm.classZu" :disabled="(searchForm.flag !== 2 && searchForm.flag !== 3 && searchForm.flag !== 6 && searchForm.flag !== 7) || user_identity" collapse-tags
                         filterable
                         @change="classZuChange"
                         style="width: 99%">
                <el-option
                    label="是"
                    value="1">
                </el-option>
                <el-option
                    label="否"
                    value="0">
                </el-option>
              </el-select>
            </el-form-item>


          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="addOfEdit('searchForm',false)" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm',false)" class="select_chong">重 置</div>
              </div>
              <div @click="addOfEdit('searchForm',true)" style="margin-top: 10px" class="select_sou">曲线图</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableList"
              style="width: 100%">
            <el-table-column
                prop="name"
                label="分类"
            >
            </el-table-column>
            <el-table-column
                v-for="(item,key) in tableTime"
                :key="key"
                :label="item"
            >
              <template slot-scope="scope">
                <span>{{tableList[scope.$index].data[key]}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogFormVisible" title="曲线图">
      <div id="myChart" style="width: 100%;height: 500px"></div>
    </el-dialog>
    <el-dialog :visible.sync="searchVisible" title="搜索">
      <el-form ref="searchForm" :model="searchForm" :rules="searchs">


        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('searchForm')">确认</el-button>
          <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderListXiao",
  mixins: [mixins],
  components: {},
  data() {
    return {
      searchVisible: false,
      dialogFormVisible: false,
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      tableDataTime: [],
      searchForm: {
        calendarMonthInt: [],
        calendarMonthIntStart: "",
        customerAbbreviation: "",
        flagFen: "",
        calendarMonthIntEnd: "",
        classZu: "0",
        flag: "",
        groupIdList: [],
        customerAbbreviationS: [],
        customerAbbreviationS_s: [],
        industryS: [],
        productCategoryS: [],
        mySalesEmployeeS: [],
        deptIdS: [],
        customerRatingS: [],
        deptS: [],
        partitionS: [],
      },
      searchs: {
        flagFen: [
          {required: true, message: '请选择统计类型', trigger: 'blur'}
        ],
        flag: [
          {required: true, message: '请选择统计方式', trigger: 'blur'}
        ],
        calendarMonthInt: [
          {required: true, message: '请选择历年', trigger: 'blur'}
        ],
      },
      IndustryList: [],
      product_categoryList: [],
      salesEmployeeDropDownBoxList: [],
      groupDropDownList: [],
      customerRatingDropDownBoxList: [],
      ratingFunctionList: [],
      top20DropDownBoxList: [],
      selectList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
      echartsList: [],
      echartsName: [],
      salesIndexStatisticsModeList: [],
      partitionListDropDownBoxList: [],
      deptDropDownBoxList: [],
      orderDataSalesEmployeeDropboxList: [],
      orderDataIndustryDropboxList: [],
      queryTheLatestMonthList: 0,
      orderDataProductCategoryDropboxList: [],
      orderDataCustomerDropboxList: [],
      partitionDropDownBoxList: [],
      echats_date: '',
      tableCao: true,
      tableList:[],
      tableTime:[],
    }
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;
    // this.customers_group();
    this.top20DropDownBox();
    this.ratingFunction();
    this.customerRatingDropDownBox();
    this.salesEmployeeDropDownBox();
    // this.IndustryFunction();
    // this.product_categoryFunction();
    this.salesIndexStatisticsMode();
    this.deptDropDownBox();
    this.orderDataSalesEmployeeDropbox();
    this.orderDataIndustryDropbox();
    this.orderDataProductCategoryDropbox();
    this.orderDataCustomerDropbox();
    // this.queryTheLatestMonth();
    // this.partitionDropDownBox();
    // this.groupDropDown();
    // this.partitionListDropDownBox();
  },
  methods: {
    flagFenChange(e){
      console.log('e',e);
      this.searchForm.flag = '';
    },
    typeStatus(item){
      if (this.searchForm.flagFen == 3 || this.searchForm.flagFen == 4) {
        console.log('进来了',this.searchForm.flagFen,item);
        if (item == 6 || item == 7) {
          return true;
        }
        return false;
      } else {
        return false
      }
    },
    orderDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptChange() {
      this.searchForm.partitionS = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeS = "";
      this.salesEmployeeDropDownBoxList = [];

      if (this.searchForm.classZu === '1') {
        this.partitionDropDownBox();
        this.orderDataSalesEmployeeDropbox();
      }
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeS = "";
      this.salesEmployeeDropDownBoxList = [];

      if (this.searchForm.classZu === '1') {
        this.groupDropDown();
        this.orderDataSalesEmployeeDropbox();
      }
    },
    groupChange() {
      this.searchForm.mySalesEmployeeS = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.orderDataSalesEmployeeDropbox();
      }
    },
    groupDropDown() {
      let date = `roleName=${this.roleMap.roleName}&groupId=${this.salesEmployee.groupId}&partitionId=${this.searchForm.partitionS.toString()}&deptId=${this.searchForm.deptIdS}`
      if (this.searchForm.flag === 10) {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&groupId=${this.user_date.groupId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      let date = `roleName=${this.roleMap.roleName}&partitionId=${this.salesEmployee.partitionId}&deptId=${this.searchForm.deptIdS.toString()}`
      if (this.searchForm.flag === 9) {
        date = `roleName=${this.user_date.roleName}&partitionId=${this.user_date.partitionId}&deptId=${this.user_date.deptId}`
      }
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?${date}`, {})
          .then((response) => {
            console.log('dropDownBox/partitionDropDown', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.orderDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    orderDataSalesEmployeeDropbox() {
      let date = {
        deptId: this.searchForm.deptIdS.toString(),
        partitionId: this.searchForm.partitionS.toString(),
        groupId: this.searchForm.groupIdList.toString(),
        roleName: this.roleMap.roleName,
        id: JSON.parse(sessionStorage.getItem('user')).id,
      }

      if (this.searchForm.flag === 11) {
        date = {
          deptId: this.user_date.deptId,
          partitionId: this.user_date.partitionId,
          groupId: this.user_date.groupId,
          roleName: this.user_date.roleName,
          id: JSON.parse(sessionStorage.getItem('user')).id,
        }
      }

      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, date)
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            if (response.data.code === 200) {
              this.orderDataSalesEmployeeDropboxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    echartsTitle() {
      let name = '曲线图';
      if (this.searchForm.flag) {
        let date = this.salesIndexStatisticsModeList.find((value) => {
          return value.dictValue === this.searchForm.flag
        })
        if (date) {
          name = date.dictLabel + name
        }
      }
      return name
    },
    dateTimeChange(e) {
      if (this.searchForm.flag === 8) {
        this.top20DropDownBox();
      }
    },
    selectChange(e) {
      console.log(e);
      this.searchForm.classZu = "0";
      this.searchForm.groupIdList = "";
      this.searchForm.customerAbbreviationS_s = "";
      this.searchForm.industryS = "";
      this.searchForm.partitionS = "";
      this.searchForm.mySalesEmployeeS = "";
      this.searchForm.productCategoryS = "";
      this.searchForm.customerRatingS = "";
      this.searchForm.deptIdS = "";
      this.searchForm.customerAbbreviation = "";

      if (e === 9) {
        this.partitionDropDownBox()
      }else if (e === 10) {
        this.groupDropDown()
      }else if (e === 11) {
        this.orderDataSalesEmployeeDropbox()
      }
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?roleName=${this.roleMap.roleName}&deptId=${this.salesEmployee.deptId}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionListDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionListDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/partitionListDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.partitionListDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesIndexStatisticsMode() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=unshippedDataType`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=unshippedDataType', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesIndexStatisticsModeList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    queryTheLatestMonth() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/unshippedDetails/queryTheLatestMonth`)
          .then((response) => {
            console.log('/unshippedDetails/queryTheLatestMonth', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.queryTheLatestMonthList = response.data.month;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    toPoint(num) {
      let str = num.replace("%", "");
      return str
    },
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "销售额来源.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    radioChange(e) {
      console.log(111, e);
      this.searchForm.customerAbbreviationS = "";
      this.searchForm.industryS = "";
      this.searchForm.productCategoryS = "";
      this.searchForm.mySalesEmployeeS = "";
      this.searchForm.customerRatingS = "";
      this.searchForm.customerAbbreviationS_s = "";
    },
    drawLine(list, name, time) {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'))
      let option = {
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: time
        },
        legend: {
          data: name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'value',
          minInterval: 0.1,
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: list,
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    drawLineA(list, name, time) {
      // 基于准备好的dom，初始化echarts实例
      this.echats_date = this.$echarts.init(document.getElementById('myChart'))
      let option = {
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: time
        },
        legend: {
          data: name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'value',
          minInterval: 0.1,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        series: list,
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echats_date.setOption(option);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    classZuChange(e) {
      console.log(111, e);
      if (e === '0') {
        this.$set(this.searchForm,'deptIdS',[]);
        this.$set(this.searchForm,'partitionS',[]);
        this.$set(this.searchForm,'groupIdList',[]);
        this.$set(this.searchForm,'mySalesEmployeeS',[]);
      }
    },
    addOfEdit(formName,type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let {classZu, deptIdS, partitionS, groupIdList, mySalesEmployeeS} = this.searchForm;
          if (classZu === '1' && !deptIdS.toString() && !partitionS.toString() && !groupIdList.toString() && !mySalesEmployeeS.toString()) {
            this.$message('部门、分区、小组、销售雇员至少选一个');
            return;
          }
          if (this.echats_date) {
            this.echats_date.clear();
          }
          if (type) {
            this.dialogFormVisible = true;
          }
          let flag = this.searchForm.flagFen;
          if (flag === '1') {
            this.oneEchartsFun(type);
          } else if (flag === '2') {
            this.twoEchartsFun(type);
          } else if (flag === '3') {
            this.threeEchartsFun(type);
          } else if (flag === '4') {
            this.fourEchartsFun(type);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    IfFunction() {
      let date = {
        2: 'industryS',
        5: 'partitionS',
      }
      if (date[this.searchForm.flag] && this.searchForm[date[this.searchForm.flag]].length <= 0) {
        this.$message('所选统计方式不能为空');
        return true;
      } else {
        return false;
      }
    },
    customers_group() {
      if (this.IfFunction()) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/order/orderSalesGrowthIndex`, {
            calendarMonthIntStart: calendarMonthInt[0],
            calendarMonthIntEnd: calendarMonthInt[1],
            flag: this.searchForm.flag.toString(),
            industryS: this.searchForm.industryS.toString(),
            productCategoryS: this.searchForm.productCategoryS.toString(),
            deptS: this.searchForm.deptIdS.toString(),
            partitionIdS: this.searchForm.partitionS.toString(),
            mySalesEmployeeS: this.searchForm.mySalesEmployeeS.toString(),
            customerRatingS: this.searchForm.customerRatingS.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviationS_s.toString(),
            groupIdS: this.searchForm.groupIdList.toString(),
            combination: this.searchForm.classZu,
          })
          .then((response) => {
            console.log('order/orderSalesGrowthIndex', response.data);
            loading.close();
            if (response.data.code === 200) {
              let arr = [];
              let arr_echarts = [];
              let arrTime = [];
              let arrName = [];
              let mapResultObject = response.data.mapResult;
              let mapResult = Object.keys(response.data.mapResult);
              if (mapResult.length > 0) {
                mapResult.map((value) => {
                  let listObject = mapResultObject[value];
                  let list = Object.keys(mapResultObject[value]);
                  console.log(111, listObject, list);
                  if (list.length > 0) {
                    list.map((val) => {
                      let dateObject = listObject[val];
                      let date = Object.keys(listObject[val]);
                      console.log(222, dateObject, date, val);
                      arrTime = date;
                      let obj = [];
                      let echarts_obj = [];
                      if (date.length > 0) {
                        date.map((values) => {
                          let dateListObject = dateObject[values];
                          console.log(333, dateListObject, values);
                          if (obj['name']) {
                            obj[values] = dateListObject;
                            echarts_obj.data.push(this.toPoint(dateListObject));
                          } else {
                            obj = {
                              name: value,
                              level: val,
                              [values]: dateListObject,
                            };
                            echarts_obj = {
                              name: value + '-' + val,
                              type: 'line',
                              data: [this.toPoint(dateListObject)],
                            };
                          }
                        })
                      }
                      arr.push(obj);
                      arr_echarts.push(echarts_obj);
                      console.log(555, obj, echarts_obj);
                    })
                  }
                })
              }
              console.log('arr', arr, arrTime, arr_echarts);
              let a = arr.sort((a, b) => {
                return b[arrTime[arrTime.length - 1]] - a[arrTime[arrTime.length - 1]]
              });
              this.tableData = a;
              this.tableDataTime = arrTime;
              this.echartsList = arr_echarts;
              this.echartsName = arrName;
              this.total = response.data.total;
              this.current_page = response.data.current_page
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    oneEchartsFun(type) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/order/originalBusinessSales`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.flag.toString(),
            combination: this.searchForm.classZu,
            industryS: this.searchForm.industryS.toString(),
            productCategoryS: this.searchForm.productCategoryS.toString(),
            deptIdS: this.searchForm.deptIdS.toString(),
            partitionIdS: this.searchForm.partitionS.toString(),
            groupIdS: this.searchForm.groupIdList.toString(),
            mySalesEmployeeS: this.searchForm.mySalesEmployeeS.toString(),
            customerRatingS: this.searchForm.customerRatingS.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviation.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/originalBusinessSales', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let list = [];
              let time = [];
              let name = [];
              if (this.searchForm.classZu === '0') {
                list = [];
                time = [];
                Object.keys(date).map((value,key)=>{
                  list.push({
                    type: 'line',
                    name: value,
                    data: Object.values(date[value])
                  })
                  time = Object.keys(date[value])
                })
                name = Object.keys(date);
              } else {
                list = [];
                time = ['原有业务销售额'];
                name = [];
                Object.keys(date).map((value,key)=>{
                  Object.keys(date[value]).map((val,ind)=>{
                    console.log(111,val);
                    list.push({
                      type: 'line',
                      name: value+'-'+val,
                      data: Object.values(date[value][val])
                    })
                    time = Object.keys(date[value][val])
                    name.push(value+'-'+val)
                  })
                })
                console.log(11,list, name, time)
              }

              this.tableList = list;
              this.tableTime = time;
              if (type) {
                this.drawLine(list, name, time)
              }

            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    twoEchartsFun(type) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/order/newBusinessSales`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.flag.toString(),
            combination: this.searchForm.classZu,
            industryS: this.searchForm.industryS.toString(),
            productCategoryS: this.searchForm.productCategoryS.toString(),
            deptIdS: this.searchForm.deptIdS.toString(),
            partitionIdS: this.searchForm.partitionS.toString(),
            groupIdS: this.searchForm.groupIdList.toString(),
            mySalesEmployeeS: this.searchForm.mySalesEmployeeS.toString(),
            customerRatingS: this.searchForm.customerRatingS.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviation.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/newBusinessSales', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let list = [];
              let time = [];
              let name = [];
              if (this.searchForm.classZu === '0') {
                list = [];
                time = [];
                Object.keys(date).map((value,key)=>{
                  list.push({
                    type: 'line',
                    name: value,
                    data: Object.values(date[value])
                  })
                  time = Object.keys(date[value])
                })
                name = Object.keys(date);
              } else {
                list = [];
                time = ['新业务销售额'];
                name = [];
                Object.keys(date).map((value,key)=>{
                  Object.keys(date[value]).map((val,ind)=>{
                    console.log(111,val);
                    list.push({
                      type: 'line',
                      name: value+'-'+val,
                      data: Object.values(date[value][val])
                    })
                    time = Object.keys(date[value][val])
                    name.push(value+'-'+val)
                  })
                })
                console.log(11,list, name, time)
              }

              this.tableList = list;
              this.tableTime = time;
              if (type) {
                this.drawLine(list, name, time)
              }

            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    threeEchartsFun(type) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/order/originalBusinessYearOnYear`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.flag.toString(),
            combination: this.searchForm.classZu,
            industryS: this.searchForm.industryS.toString(),
            productCategoryS: this.searchForm.productCategoryS.toString(),
            deptIdS: this.searchForm.deptIdS.toString(),
            partitionIdS: this.searchForm.partitionS.toString(),
            groupIdS: this.searchForm.groupIdList.toString(),
            mySalesEmployeeS: this.searchForm.mySalesEmployeeS.toString(),
            customerRatingS: this.searchForm.customerRatingS.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviation.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/originalBusinessYearOnYear', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let list = [];
              let time = [];
              let name = [];
              if (this.searchForm.classZu === '0') {
                list = [];
                time = [];
                Object.keys(date).map((value, key) => {
                  list.push({
                    type: 'line',
                    name: value,
                    data: Object.values(date[value]).map(res=>{
                      return (res * 100).toFixed(2)
                    })
                  })
                  time = Object.keys(date[value])
                })
                name = Object.keys(date);
              } else {
                list = [];
                time = ['原有业务同比'];
                name = [];
                Object.keys(date).map((value,key)=>{
                  Object.keys(date[value]).map((val,ind)=>{
                    console.log(111,val);
                    list.push({
                      type: 'line',
                      name: value + '-' + val,
                      data: Object.values(date[value][val]).map(res=>{
                        return (res * 100).toFixed(2)
                      })
                    })
                    time = Object.keys(date[value][val])
                    name.push(value+'-'+val)
                  })
                })
                console.log(11,list, name, time)
              }

              this.tableList = list;
              this.tableTime = time;
              if (type) {
                this.drawLineA(list, name, time)
              }

            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    fourEchartsFun(type) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/order/proportionOfNewBusiness`, {
            calendarMonthStart: calendarMonthInt[0],
            calendarMonthEnd: calendarMonthInt[1],
            flag: this.searchForm.flag.toString(),
            combination: this.searchForm.classZu,
            industryS: this.searchForm.industryS.toString(),
            productCategoryS: this.searchForm.productCategoryS.toString(),
            deptIdS: this.searchForm.deptIdS.toString(),
            partitionIdS: this.searchForm.partitionS.toString(),
            groupIdS: this.searchForm.groupIdList.toString(),
            mySalesEmployeeS: this.searchForm.mySalesEmployeeS.toString(),
            customerRatingS: this.searchForm.customerRatingS.toString(),
            customerAbbreviationS: this.searchForm.customerAbbreviation.toString(),
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            mySalesEmployee:this.user_date.saleEmployee,
          })
          .then((response) => {
            console.log('order/proportionOfNewBusiness', response.data);
            loading.close();
            if (response.data.code === 200) {
              let date = response.data.mapResult;
              let list = [];
              let time = [];
              let name = [];
              if (this.searchForm.classZu === '0') {
                list = [];
                time = [];
                Object.keys(date).map((value, key) => {
                  list.push({
                    type: 'line',
                    name: value,
                    data: Object.values(date[value]).map(res=>{
                      return (res * 100).toFixed(2)
                    })
                  })
                  time = Object.keys(date[value])
                })
                name = Object.keys(date);
              } else {
                list = [];
                time = ['新业务占比'];
                name = [];
                Object.keys(date).map((value,key)=>{
                  Object.keys(date[value]).map((val,ind)=>{
                    console.log(111,val);
                    list.push({
                      type: 'line',
                      name: value + '-' + val,
                      data: Object.values(date[value][val]).map(res=>{
                        return (res * 100).toFixed(2)
                      })
                    })
                    time = Object.keys(date[value][val])
                    name.push(value+'-'+val)
                  })
                })
                console.log(11,list, name, time)
              }

              this.tableList = list;
              this.tableTime = time;
              if (type) {
                this.drawLineA(list, name, time)
              }

            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDownBox`, {
            deptIdList: "",
            partitionIdList: ""
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    top20DropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let calendarMonthInt = this.searchForm.calendarMonthInt.length > 0 ? this.searchForm.calendarMonthInt : ['', '']
      this.axios
          .post(this.$urlHttp + `/dropDownBox/top20DropDownBox`,{
            month:calendarMonthInt[1],
            roleName:this.user_date.roleName,
            deptId:this.user_date.deptId,
            partitionId:this.user_date.partitionId,
            groupId:this.user_date.groupId,
            id:this.user_date.id,
          })
          .then((response) => {
            console.log('dropDownBox/top20DropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.top20DropDownBoxList = response.data.top20List;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    ratingFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/rateDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/rateDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.ratingFunctionList = response.data.rateList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.customers_group();
          this.searchVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
