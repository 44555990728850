<template>
  <div class="content">
    <div class="list">
      <el-row style="border-bottom: 1px solid #DCDCDC;margin-bottom: 18px;padding: 22px 0;">
        <el-col :span="12">
          <span style="color: #3D3D3D">销售统计</span>
        </el-col>
        <el-col style="display: flex;align-items:center;justify-content: flex-end" :span="12">
          <div @click="exportExcel" style="margin-top: 10px" class="select_sou">导出搜索结果</div>
        </el-col>
      </el-row>
      <el-form ref="searchForm" :rules="searchForm" :model="searchForm">
        <el-row style="border-bottom: 1px solid #DCDCDC;">
          <el-col :span="6">
            <el-form-item prop="calendarMonthInt" label="年月" label-width="100px">
<!--              <el-date-picker-->
<!--                  style="width: 99%"-->
<!--                  v-model="searchForm.calendarMonthStart"-->
<!--                  value-format="yyyy/MM"-->
<!--                  type="month"-->
<!--                  placeholder="选择月">-->
<!--              </el-date-picker>-->
              <el-date-picker
                  v-model="searchForm.calendarMonthInt"
                  end-placeholder="结束"
                  range-separator="至"
                  start-placeholder="开始"
                  style="width: 99%"
                  type="month"
                  value-format="yyyy/MM">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="分区" label-width="100px" prop="partitionId">
              <el-select v-model="searchForm.partitionId" :disabled="searchForm.flag !== '3'" style="width: 99%">
                <el-option
                    v-for="(value,key) in partitionDropDownBoxList"
                    :key="key"
                    :label="value.partitionName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="统计类型" label-width="100px" prop="flag">
              <el-select @change="flagChange" v-model="searchForm.flag" style="width: 99%">
                <el-option
                    label="按全部"
                    value="1">
                </el-option>
                <el-option
                    :disabled="user_identity"
                    label="按部门"
                    value="2">
                </el-option>
                <el-option
                    :disabled="user_identity"
                    label="按分区"
                    value="3">
                </el-option>
                <el-option
                    :disabled="user_identity"
                    label="按小组"
                    value="4">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小组" label-width="100px" prop="groupId">
              <el-select v-model="searchForm.groupId" :disabled="searchForm.flag !== '4'" style="width: 99%">
                <el-option
                    v-for="(value,key) in groupDropDownList"
                    :key="key"
                    :label="value.groupName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="部门" label-width="100px" prop="deptId">
              <el-select v-model="searchForm.deptId" :disabled="searchForm.flag !== '2'" style="width: 99%">
                <el-option
                    v-for="(value,key) in deptDropDownBoxList"
                    :key="key"
                    :label="value.deptName"
                    :value="value.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="display: flex">
                <div @click="customers_group()" class="select_sou">搜 索</div>
                <div @click="resetForm('searchForm')" class="select_chong">重 置</div>
              </div>
              <!--              <el-button @click="customers_group()">确认</el-button>-->
              <!--              <el-button type="primary" @click="resetForm('searchForm')">重置</el-button>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableDiv">
        <div class="tableDiv_div">
          <el-table
              id="tableDao"
              :data="tableData"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column
                prop="currentMonth"
                label="年月"
            >
            </el-table-column>
            <el-table-column
                prop="saleEmployee"
                label="销售雇员"
            >
            </el-table-column>
            <el-table-column
                prop="accumulatedSalesCompleted"
                label="累计完成销售额"
            >
            </el-table-column>
            <el-table-column
                prop="annualTargetSalesRevenue"
                label="年度目标销售额"
            >
            </el-table-column>
            <el-table-column
                prop="goalAchievementRate"
                label="目标达成率"
            >
              <template slot-scope="scope">
                {{sumChange(scope.row.goalAchievementRate)}}
              </template>
            </el-table-column>
            <el-table-column
                prop="growthContributionRate"
                label="增长贡献率"
            >
              <template slot-scope="scope">
                {{sumChange(scope.row.growthContributionRate)}}
              </template>
            </el-table-column>
            <el-table-column
                prop="originalBusinessSales"
                label="原有业务销售额"
            >
            </el-table-column>
            <el-table-column
                prop="originalBusinessYearOnYear"
                label="原有业务同比"
            >
              <template slot-scope="scope">
                {{sumChange(scope.row.originalBusinessYearOnYear)}}
              </template>
            </el-table-column>
            <el-table-column
                prop="newBusinessSales"
                label="新业务销售额"
            >
            </el-table-column>
            <el-table-column
                prop="proportionOfNewBusiness"
                label="新业务占比"
            >
              <template slot-scope="scope">
                {{sumChange(scope.row.proportionOfNewBusiness)}}
              </template>
            </el-table-column>
            <el-table-column
                prop="unshippedAmount"
                label="未出货金额"
            >
            </el-table-column>
            <el-table-column
                prop="unshippedCoefficientDDO"
                label="未出货系数DDO"
            >
            </el-table-column>
          </el-table>
        </div>
<!--        <el-pagination-->
<!--            background-->
<!--            style="margin-top: 30px"-->
<!--            @size-change="handleSizeChange"-->
<!--            @current-change="handleCurrentChange"-->
<!--            :current-page="currentPage4"-->
<!--            :page-sizes="[10, 20, 30, 100,1000]"-->
<!--            :page-size="pageSize"-->
<!--            layout="total, sizes, prev, pager, next, jumper"-->
<!--            :total="total">-->
<!--        </el-pagination>-->
      </div>
    </div>
    <el-dialog :title="tanStatus?'添加订单出货':'修改订单出货'" :visible.sync="dialogFormVisible">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm">
        <el-form-item prop="deliveryYears" label="交货年月" label-width="100px">
          <el-date-picker
              style="width: 50%"
              v-model="ruleForm.deliveryYears"
              value-format="yyyy-MM"
              type="month">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="customerCode" label="客户代码" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.customerCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="material" label="物料" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.material" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="customerMaterial" label="客户物料" label-width="100px">
          <el-input style="width: 50%" v-model="ruleForm.customerMaterial" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="amount" label="数量" label-width="100px">
          <el-input style="width: 50%" type="number" v-model="ruleForm.amount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="deliveryTime" label="交货日期" label-width="100px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.deliveryTime"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="actualDeliveryTime" label="实际发货日" label-width="100px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.actualDeliveryTime"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="orderFirstDeliveryTime" label="首个交货日" label-width="100px">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 50%"
              v-model="ruleForm.orderFirstDeliveryTime"
              type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left:100px;">
          <el-button @click="addSubmit('ruleForm')">确认</el-button>
          <!--          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {httpList} from "@/components/request";
import {mixins} from "@/mixin/identity";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "OrderImport",
  mixins: [mixins],
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      tanStatus: true,
      ruleForm: {
        id: "",//id
        deliveryYears: "",//交货年月
        deliveryTime: "",//交货日期
        actualDeliveryTime: "",//实际发货日
        orderFirstDeliveryTime: "",//首个交货日
        customerCode: "",//客户代码
        material: "",//物料
        customerMaterial: "",//客户物料
        amount: "",//数量
        updateBy: "",//更新者
      },
      rules: {
        deliveryYears: [
          {required: true, message: '请选择交货年月', trigger: 'blur'}
        ],
        deliveryTime: [
          {required: true, message: '请选择交货日期', trigger: 'blur'}
        ],
        actualDeliveryTime: [
          {required: true, message: '请选择实际发货日', trigger: 'blur'}
        ],
        orderFirstDeliveryTime: [
          {required: true, message: '请选择首个交货日', trigger: 'blur'}
        ],
        customerCode: [
          {required: true, message: '请输入客户代码', trigger: 'blur'}
        ],
        material: [
          {required: true, message: '请输入物料', trigger: 'blur'}
        ],
        customerMaterial: [
          {required: true, message: '请输入客户物料', trigger: 'blur'}
        ],
        amount: [
          {required: true, message: '请输入数量', trigger: 'blur'}
        ],
      },
      total: 0,
      currentPage4: 1,
      pageSize: 20,
      tableData: [],
      searchForm: {
        calendarMonthInt:"",
        flag:'',
        calendarMonthStart:'',
        deptId:'',
        partitionId:'',
        groupId:'',
      },
      IndustryList: [],
      product_categoryList: [],
      selectList: [],
      customerRatingDropDownBoxList: [],
      deptDropDownBoxList: [],
      salesEmployeeDropDownBoxList: [],
      partitionDropDownBoxList: [],
      deliveryDataIndustryDropboxList: [],
      deliveryDataProductCategoryDropboxList: [],
      deliveryDataCustomerDropboxList: [],
      groupDropDownList: [],
      user: {},
      roleMap: {},
      salesEmployee: {},
    }
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let roleMap = JSON.parse(sessionStorage.getItem('roleMap'));
    let salesEmployee = JSON.parse(sessionStorage.getItem('salesEmployee'));
    this.user = user;
    this.roleMap = roleMap;
    this.salesEmployee = salesEmployee;

    this.searchForm.calendarMonthInt = this.$moment().subtract(1,'month').format('YYYY/MM');

    this.deptDropDownBox();
    this.partitionDropDownBox();
    this.groupDropDownBox();
  },
  methods: {
    exportExcel() {
      this.tableCao = false;
      setTimeout(() => {
        /* 从表生成工作簿对象 */
        /* 传入 table id */
        var wb = this.$XLSX.utils.table_to_book(document.querySelector("#tableDao"));
        /* 获取二进制字符串作为输出 */
        var wbout = this.$XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          this.$FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wbout], {type: "application/octet-stream"}),
              //设置导出文件名称
              "销售统计导出.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.tableCao = true;
        return wbout;
      }, 300)
    },
    sumChange(num){
      return (num * 100).toFixed(2) + '%'
    },
    flagChange(){
      this.searchForm.groupId = '';
      this.searchForm.partitionId = '';
      this.searchForm.deptId = '';
    },
    exportExcelSampleList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let deliveryYearsInt = this.searchForm.deliveryYearsInt && this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      let actualDeliveryDate = this.searchForm.actualDeliveryDate && this.searchForm.actualDeliveryDate.length > 0 ? this.searchForm.actualDeliveryDate : ['', '']
      let orderFirstDeliveryDate = this.searchForm.orderFirstDeliveryDate && this.searchForm.orderFirstDeliveryDate.length > 0 ? this.searchForm.orderFirstDeliveryDate : ['', '']
      window.location.href = this.$urlHttp + `/orderShipment/exportExcel?deliveryYearsStart=${deliveryYearsInt[0]}&deliveryYearsEnd=${deliveryYearsInt[1]}&actualDeliveryDateStart=${actualDeliveryDate[0]}&actualDeliveryDateEnd=${actualDeliveryDate[1]}&orderFirstDeliveryDateStart=${orderFirstDeliveryDate[0]}&orderFirstDeliveryDateEnd=${orderFirstDeliveryDate[1]}&industryList=${this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : ""}&productCategoryList=${this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : ""}&customerAbbreviationList=${this.searchForm.customerAbbreviationList.toString()}&deptIdList=${this.searchForm.deptIdList.toString()}&mySalesEmployeeList=${this.searchForm.mySalesEmployeeList.toString()}&partitionIdList=${this.searchForm.partitionIdList.toString()}`
      loading.close();
    },
    deptChange() {
      this.searchForm.partitionIdList = "";
      this.partitionDropDownBoxList = [];

      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.partitionDropDownBox();
        this.salesEmployeeDropDownBox();
      }
    },
    partitionChange() {
      this.searchForm.groupIdList = "";
      this.groupDropDownList = [];

      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.groupDropDown();
        this.salesEmployeeDropDownBox();
      }
    },
    groupChange() {
      this.searchForm.mySalesEmployeeList = "";
      this.salesEmployeeDropDownBoxList = [];
      if (this.searchForm.classZu === '1') {

        this.salesEmployeeDropDownBox();
      }
    },
    groupDropDownBox() {
      const searchParams = new URLSearchParams({
        roleName:this.user_date.roleName,
        partitionId:this.user_date.partitionId,
        groupId:this.user_date.groupId,
        deptId:this.user_date.deptId,
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/groupDropDown?${searchParams}`, {})
          .then((response) => {
            console.log('dropDownBox/groupDropDown', response.data);
            if (response.data.code === 200) {
              this.groupDropDownList = response.data.groupList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    partitionDropDownBox() {
      const searchParams = new URLSearchParams({
        roleName:this.user_date.roleName,
        deptId:this.user_date.deptId,
        partitionId:this.user_date.partitionId,
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/partitionDropDown?${searchParams}`, {})
          .then((response) => {
            console.log('dropDownBox/subregionDropDownBox', response.data);
            if (response.data.code === 200) {
              this.partitionDropDownBoxList = response.data.partitionList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    salesEmployeeDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/salesEmployeeDropDown`, {
            deptId: this.searchForm.deptIdList.toString(),
            partitionId: this.searchForm.partitionIdList.toString(),
            groupId: this.searchForm.groupIdList.toString(),
            roleName: this.roleMap.roleName,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/salesEmployeeDropDown', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.salesEmployeeDropDownBoxList = response.data.salesEmployeeList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deptDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const searchParams = new URLSearchParams({
        roleName:this.user_date.roleName,
        deptId:this.user_date.deptId,
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/deptDropDown?${searchParams}`)
          .then((response) => {
            console.log('dropDownBox/deptDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deptDropDownBoxList = response.data.deptList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customerRatingDropDownBox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.customerRatingDropDownBoxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataCustomerDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .post(this.$urlHttp + `/dropDownBox/customerRatingDropDownBox`, {
            roleName: JSON.parse(sessionStorage.getItem('roleMap')).roleName,
            deptId: JSON.parse(sessionStorage.getItem('user')).deptId,
            partitionId: JSON.parse(sessionStorage.getItem('user')).partitionId,
            groupId: JSON.parse(sessionStorage.getItem('user')).groupId,
            id: JSON.parse(sessionStorage.getItem('user')).id,
          })
          .then((response) => {
            console.log('dropDownBox/customerRatingDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataCustomerDropboxList = response.data.orderList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    handleUpdate(file, fileList) {
      console.log(file, fileList)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (file.response) {
        loading.close();
        this.$message(file.response.msg);
        this.customers_group();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    addOfEdit(type, data) {
      this.tanStatus = type;
      if (type) {
        this.ruleForm = {
          id: 0,//id
          deliveryYears: "",//交货年月
          deliveryTime: "",//交货日期
          actualDeliveryTime: "",//实际发货日
          orderFirstDeliveryTime: "",//首个交货日
          customerCode: "",//客户代码
          material: "",//物料
          customerMaterial: "",//客户物料
          amount: "",//数量
          updateBy: "",//更新者
        }
      } else {
        console.log(111, data);
        this.ruleForm.id = data.id;
        this.ruleForm.deliveryYears = data.deliveryYears;
        this.ruleForm.deliveryTime = data.deliveryTime;
        this.ruleForm.actualDeliveryTime = data.actualDeliveryTime;
        this.ruleForm.orderFirstDeliveryTime = data.orderFirstDeliveryTime;
        this.ruleForm.customerCode = data.customerCode;
        this.ruleForm.material = data.material;
        this.ruleForm.customerMaterial = data.customerMaterial;
        this.ruleForm.amount = data.amount;
      }
      this.dialogFormVisible = true;
    },
    customers_group() {
      if (!this.searchForm.calendarMonthInt) {
        this.$message('请选择年月');
        return;
      } else if (!this.searchForm.flag){
        this.$message('请选择统计类型');
        return;
      } else if (this.searchForm.flag === '2' && !this.searchForm.deptId){
        this.$message('请选择部门');
        return;
      } else if (this.searchForm.flag === '3' && !this.searchForm.partitionId){
        this.$message('请选择分区');
        return;
      } else if (this.searchForm.flag === '4' && !this.searchForm.groupId){
        this.$message('请选择小组');
        return;
      }

      this.totalSales()
          .then(res=>{
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.axios
                .post(this.$urlHttp + `/order/salesStatistics`, {
                  calendarMonth: this.searchForm.calendarMonthInt,
                  flag:this.searchForm.flag,
                  deptId:this.searchForm.deptId.toString() || this.user_date.deptId,
                  partitionId:this.searchForm.partitionId.toString() || this.user_date.partitionId,
                  groupId:this.searchForm.groupId.toString() || this.user_date.groupId,
                  roleName:this.user_date.roleName,
                  mySalesEmployee:this.user_date.saleEmployee,
                })
                .then((response) => {
                  console.log('order/salesStatistics', response.data);
                  loading.close();
                  if (response.data.code === 200) {
                    console.log(666,res);
                    this.tableData = response.data.mapResult.seList;
                    this.tableData.push({
                      saleEmployee:'合计',
                      currentMonth:res.currentMonth,
                      accumulatedSalesCompleted:res.accumulatedSalesCompleted,
                      annualTargetSalesRevenue:res.annualTargetSalesRevenue,
                      goalAchievementRate:res.goalAchievementRate,
                      growthContributionRate:res.growthContributionRate,
                      originalBusinessSales:res.originalBusinessSales,
                      originalBusinessYearOnYear:res.originalBusinessYearOnYear,
                      newBusinessSales:res.newBusinessSales,
                      proportionOfNewBusiness:res.proportionOfNewBusiness,
                      unshippedAmount:res.unshippedAmount,
                      unshippedCoefficientDDO:res.unshippedCoefficientDDO,
                    })
                    this.total = response.data.mapResult.seList.length;
                    this.current_page = response.data.current_page
                  } else {
                    this.$message(response.data.message);
                  }
                })
                .catch((error) => console.log(error, "error"));
          })



    },
    totalSales() {
      return new Promise((resolve, reject) => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.axios
            .post(this.$urlHttp + `/order/totalSales`, {
              calendarMonth: this.searchForm.calendarMonthInt,
              flag:this.searchForm.flag,
              deptId:this.searchForm.deptId.toString() || this.user_date.deptId,
              partitionId:this.searchForm.partitionId.toString() || this.user_date.partitionId,
              groupId:this.searchForm.groupId.toString() || this.user_date.groupId,
              roleName:this.user_date.roleName,
              mySalesEmployee:this.user_date.saleEmployee,
            })
            .then((response) => {
              console.log('order/totalSales', response.data);
              loading.close();
              if (response.data.code === 200) {
                resolve(response.data.mapResult['合计']);
              } else {
                reject();
                this.$message(response.data.message);
              }
            })
            .catch((error) => console.log(error, "error"));
      })

    },
    IndustryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=Industry`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=Industry', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.IndustryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataIndustryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/industryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/industryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataIndustryDropboxList = response.data.industryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    deliveryDataProductCategoryDropbox() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/productCategoryDropDownBox`)
          .then((response) => {
            console.log('dropDownBox/productCategoryDropDownBox', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.deliveryDataProductCategoryDropboxList = response.data.productCategoryList;
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    product_categoryFunction() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .get(this.$urlHttp + `/dropDownBox/sysDictDataDropDownBox?dictType=productCategory`)
          .then((response) => {
            console.log('dropDownBox/sysDictDataDropDownBox?dictType=productCategory', response.data);
            loading.close();
            if (response.data.code === 200) {
              this.product_categoryList = this.listFormData__(response.data.sysDictDataList);
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    resetPwd(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('id', id);
      this.axios
          .post(this.$urlHttp + `/sysUser/resetPwd`, data)
          .then((response) => {
            loading.close();
            console.log('sysUser/resetPwd', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      data.append('customerAbbreviation', this.ruleForm.customerAbbreviation);
      data.append('customerRating', this.ruleForm.customerRating);
      this.axios
          .post(this.$urlHttp + `/customerRating/add`, data)
          .then((response) => {
            loading.close();
            console.log('customerRating/add', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupEdit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let user = JSON.parse(sessionStorage.getItem('user'));
      let data = new FormData();
      data.append('id', this.ruleForm.id);
      data.append('deliveryYears', this.ruleForm.deliveryYears);
      data.append('deliveryTime', this.ruleForm.deliveryTime);
      data.append('actualDeliveryTime', this.ruleForm.actualDeliveryTime);
      data.append('orderFirstDeliveryTime', this.ruleForm.orderFirstDeliveryTime);
      data.append('customerCode', this.ruleForm.customerCode);
      data.append('material', this.ruleForm.material);
      data.append('customerMaterial', this.ruleForm.customerMaterial);
      data.append('amount', this.ruleForm.amount);
      data.append('updateBy', user.userName);
      this.axios
          .post(this.$urlHttp + `/orderShipment/edit`, data)
          .then((response) => {
            loading.close();
            console.log('orderShipment/edit', response.data);
            if (response.data.code === 200) {
              this.dialogFormVisible = false;
              this.customers_group();
            } else {
              this.$message(response.data.message);
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    customers_groupDel(id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios
          .delete(this.$urlHttp + `/orderShipment/del?id=${id}`)
          .then((response) => {
            loading.close();
            console.log('orderShipment/del', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatch() {
      if (this.selectList.length <= 0) {
        this.$message('请选择订单');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let id = [];
      this.selectList.map((value) => {
        id.push(value.id)
      })
      this.axios
          .delete(this.$urlHttp + `/orderShipment/delBatch?ids=${id.toString()}`)
          .then((response) => {
            loading.close();
            console.log('orderShipment/delBatch', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    delBatchSearch() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      console.log(this.searchForm);
      let deliveryYearsInt = this.searchForm.deliveryYearsInt && this.searchForm.deliveryYearsInt.length > 0 ? this.searchForm.deliveryYearsInt : ['', '']
      let actualDeliveryDate = this.searchForm.actualDeliveryDate && this.searchForm.actualDeliveryDate.length > 0 ? this.searchForm.actualDeliveryDate : ['', '']
      let orderFirstDeliveryDate = this.searchForm.orderFirstDeliveryDate && this.searchForm.orderFirstDeliveryDate.length > 0 ? this.searchForm.orderFirstDeliveryDate : ['', '']
      data.append('pageNo', this.currentPage4);
      data.append('pageSize', this.pageSize);
      data.append('deliveryYearsStart', deliveryYearsInt[0]);
      data.append('deliveryYearsEnd', deliveryYearsInt[1]);
      data.append('actualDeliveryDateStart', actualDeliveryDate[0]);
      data.append('actualDeliveryDateEnd', actualDeliveryDate[1]);
      data.append('orderFirstDeliveryDateStart', orderFirstDeliveryDate[0]);
      data.append('orderFirstDeliveryDateEnd', orderFirstDeliveryDate[1]);
      data.append('industryList', this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "");
      data.append('productCategoryList', this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "");
      data.append('customerAbbreviationList', this.searchForm.customerAbbreviationList.toString());
      data.append('deptIdList', this.searchForm.deptIdList.toString());
      data.append('mySalesEmployeeList', this.searchForm.mySalesEmployeeList.toString());
      data.append('partitionIdList', this.searchForm.partitionIdList.toString());
      data.append('groupIdList', this.searchForm.groupIdList.toString());
      this.axios
          .post(this.$urlHttp + `/orderShipment/deleteSearchResults`, {
            deliveryYearsStart: deliveryYearsInt[0],
            deliveryYearsEnd: deliveryYearsInt[1],
            actualDeliveryDateStart: actualDeliveryDate[0],
            actualDeliveryDateEnd: actualDeliveryDate[1],
            orderFirstDeliveryDateStart: orderFirstDeliveryDate[0],
            orderFirstDeliveryDateEnd: orderFirstDeliveryDate[1],
            industryList: this.searchForm.industry && this.searchForm.industry.length > 0 ? this.searchForm.industry.toString() : "",
            productCategoryList: this.searchForm.productCategory && this.searchForm.productCategory.length > 0 ? this.searchForm.productCategory.toString() : "",
            customerAbbreviationList: this.searchForm.customerAbbreviationList.toString(),
            deptIdList: this.searchForm.deptIdList.toString(),
            mySalesEmployeeList: this.searchForm.mySalesEmployeeList.toString(),
            partitionIdList: this.searchForm.partitionIdList.toString(),
          })
          .then((response) => {
            loading.close();
            console.log('orderShipment/deleteSearchResults', response.data);
            this.$message(response.data.message);
            if (response.data.code === 200) {
              this.customers_group();
            }
          })
          .catch((error) => console.log(error, "error"));
    },
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tanStatus) {
            // this.customers_groupAdd();
          } else {
            this.customers_groupEdit();
          }
          // this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(123, formName)
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.customers_group();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.customers_group();
    },
  },
}
</script>

<style scoped>
.content >>> .el-dialog__title {
  color: #FFFFFF;
}

.content >>> .el-dialog__headerbtn .el-dialog__close {
  color: #FFFFFF;
}

.content >>> .el-dialog__header {
  background-color: #4696C8;
}

.ButtonDao {
  background-color: #5BC0DE;
  border: none;
}


.tableDiv >>> .el-table th.el-table__cell {
  color: #0358DD;
}

.tableDiv >>> .el-table .warning-row {
  background: #D6EFFF;
}

.tableDiv >>> .el-table .success-row {
  background: #F3FAFF;
}

.tableDiv_div {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}

.tableDiv {
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ButtonDao_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.listTitle_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 15px 10px;
  box-sizing: border-box;
}

.content {
  height: 100%;
  padding: 26px 37px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>
